import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function LinkInstitutii() {
  const { t } = useTranslation();
  return (
    <div className="mt-2">
      <Link
        to="/relatii-bilaterale"
        className="bg-gradient-to-r from-yellow-100 via-yellow-200 to-yellow-300 mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/u5.png"
          alt="Steaguri România și China"
          className="drop-shadow-md w-[40px] md:w-[60px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-extrabold">{t("relatii_bilaterale")}</p>
          <p className="text-xs font-semibold">{t("romania_china")}</p>
        </div>
      </Link>
      <Link
        to="/studii-documentare"
        className="bg-gradient-to-r from-yellow-100 via-yellow-200 to-yellow-300 flex mt-2 items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/u4.png"
          alt="Toca"
          className="w-[40px] sm:w-[60px] drop-shadow-lg"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-extrabold">{t("studii_documentare")}</p>
          <p className="text-xs font-semibold">{t("romania_china")}</p>
        </div>
      </Link>
      <a
        href="https://www.mae.ro/travel-alerts/62449"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/mae.png"
          alt="Ministerul Afacerilor Externe"
          className="w-[40px] sm:w-[60px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-bold">
            {t("ministerul_afacerilor_externe")}
          </p>
          <p className="text-xs">{t("sfaturi_calatorie_china")}</p>
        </div>
      </a>
      <a
        href="https://beijing.mae.ro/"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/u9.png"
          alt="Logo Ambasada Romaniei in Republica Populara Chineza"
          className="w-[40px] sm:w-[60px]"
        />
        <div className="text-center">
          <p className="text-xs font-bold">{t("ambasada_romaniei")}</p>
        </div>
      </a>
      <a
        href="http://ro.china-embassy.gov.cn/rom/"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/u8.png"
          alt="Logo Ambasada Republici Populare Chineze In Romania"
          className="w-[40px] sm:w-[60px]"
        />
        <div className="text-center">
          <p className="text-xs font-bold">{t("ambasada_chinei")}</p>
        </div>
      </a>
      <a
        href="https://www.icr.ro/paris/deschiderea-institutului-cultural-roman-de-la-beijing"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/icr.png"
          alt="Institut Culturel Roumain"
          className="w-[40px] sm:w-[60px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-bold">{t("institutul_cultural_roman")}</p>
          <p className="text-xs">{t("icr_beijing")}</p>
        </div>
      </a>
      <a
        href="https://www.cccbucharest.org/"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/ccc.png"
          alt="China Culturel Center"
          className="w-[40px] sm:w-[60px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-bold">{t("centrul_cultural_chinez")}</p>
          <p className="text-xs">{t("ccc_bucuresti")}</p>
        </div>
      </a>
      <a
        href="https://ci.cn/en/qqwl"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/u7.png"
          alt="Institutul Confucius"
          className="w-[40px] sm:w-[60px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-bold">{t("institutul_confucius")}</p>
          <p className="text-xs">{t("universitati_romania")}</p>
        </div>
      </a>
      <a
        href="https://romanian.cri.cn/"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal gap-2 px-2 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/cri.png"
          alt="China Radio International"
          className="w-[40px] sm:w-[60px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-bold">{t("radio_china_international")}</p>
          <p className="text-xs">{t("sectia_romana")}</p>
        </div>
      </a>
      <a
        href="https://bio.visaforchina.cn/BUH3_EN/"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal px-2 py-1 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/u6.png"
          alt="China Radio International"
          className="w-[30px] sm:w-[50px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-bold">Chinese Visa Service Center</p>
          <p className="text-xs">{t("centrul_de_vize")}</p>
        </div>
      </a>
      <a
        href="https://investromania.gov.ro/web/"
        className="bg-white mt-2 flex items-center justify-center sm:justify-normal px-2 py-1 rounded-md shadow-md transition duration-200 hover:shadow-lg"
      >
        <img
          src="/u10.png"
          alt="China Radio International"
          className="w-[40px] sm:w-[50px]"
        />
        <div className="text-center mx-auto">
          <p className="text-xs font-bold">{t("invest_romania")}</p>
          <p className="text-xs">{t("investeste_in_romania")}</p>
        </div>
      </a>
    </div>
  );
}
