import React from "react";
import { useTranslation } from "react-i18next";

const facilitati = [
  {
    categorie: "Implicare strategică",
    beneficii: [
      {
        descriere:
          "Atribuie reprezentantului companiei funcția de vicepreședinte al CCIRC și membru în Consiliul Național de Conducere al camerei bilaterale.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă reprezentantului companiei coordonarea activităților camerei bilaterale în domeniul de activitate al companiei, dezvoltate în cadrul departamentului intern specializat al CCIRC.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură participarea companiei la elaborarea procedurilor CCIRC de selectare și propunere a oportunităților și proiectelor de afaceri  bilaterale în domenii de interes pentru activitatea acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Cooptează reprezentanții companiei în delegațiile camerei bilaterale care participă la întâlniri oficiale în România sau în cadrul misiunilor economice ale autorităților române în R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează serviciile sau produsele companiei, inclusiv ofertele speciale ale acesteia către membrii camerei bilaterale, direct sau prin intermediului sistemului M2M.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează companiei contactele și dialogurile cu reprezentanții misiunilor diplomatice ale României din R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Reprezentare și relații publice",
    beneficii: [
      {
        descriere:
          "Susține și reprezintă interesele generale ale companiei în relația cu instituțiile administrației publice centrale și locale, precum și în fața organismelor și organizațiilor naționale din care face parte camera bilaterală.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Apără interesele strategice ale companiei în cadrul mediului de afaceri și în fața autorităților, inclusiv prin susținerea campaniilor de advocacy ale acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează întâlnirile, dialogurile și vizitele de prezentare ale companiei cu reprezentanții autorităților, instituțiilor sau structurile asociative economice, camerale, patronale și sindicale, la nivel central și local din România.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează proiectele de investiții și inițiativele de tip parteneriat public - privat ale companiei în fața unităților administrativ teritoriale din România.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează participarea reprezentanților companiei la evenimentele camerei bilaterale, proprii sau organizate în parteneriat, precum și la evenimentele sau activitățile la care aceasta este invitată să participe, în România, cu scopul dezvoltării relațiilor publice și comerciale ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează obținerea de invitații și accesul la evenimente publice sau private din România și R.P. Chineză, care prezintă interes pentru dezvoltarea relațiilor economice bilaterale ale companiei (forumuri, conferințe, congrese, seminarii, work-shopuri s.a.).",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează accesul și participarea companiei la târguri, expoziții și alte asemenea evenimente economice și comerciale care au loc în România sau în UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei suport pentru organizarea evenimentelor proprii, în România, prin asigurarea participării în cadrul acestora a reprezentanților autorităților, instituțiilor sau structurile asociative economice, camerale, patronale și sindicale, la nivel central și local din România.",
        destinatari: ["Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Suport de afaceri",
    beneficii: [
      {
        descriere:
          "Identifică și verifică potențialii parteneri de afaceri ai companiei din România (analize juridice,  financiare și de bonitate).",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Prezintă companiei proiecte sau oportunități de afaceri comunicate camerei bilaterale de către companiile românești sau de alte entități publice sau private din România, care pot fi considerate ca fiind de interes pentru  aceasta.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Prezintă proiectele sau oportunitățile de afaceri ale companiei, potențialilor parteneri de afaceri români sau colaboratorilor camerei bilaterale din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Organizează întâlniri de tip B2B pentru reprezentanții companiei cu companii sau entități economice și comerciale din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei, în condiții contractuale, consultanță de afaceri, juridică și financiară, precum și alte servicii specifice dezvoltării proiectelor  bilaterale.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă asistență de specialitate la negocierile reprezentanților companiei cu entități economice și comerciale din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează contactele companiei cu parteneri din România pentru derularea în condiții de siguranță și calitate a operațiunilor specifice activităților comerciale de import - export pe piața europeană.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Organizează sau facilitează înscrierea și participarea reprezentanților sau personalului companiei la seminarii de prezentare a mediului de afaceri și legislației economice și comerciale din România și UE și la cursuri de perfecționare a negocierii și comunicării specifice relațiilor bilaterale cu parteneri români, precum și de învățare a limbii române.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Listează pe platforma de business a camerei bilaterale, în cadrul sistemelor de cooperare inter-membri M2M și networking cu partenerii din România oportunitățiile de afaceri sau propunerile de proiecte ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei asistență pentru întocmirea documentelor specifice obținerii vizelor de tranzit, de scurtă sau lungă ședere, precum și a permiselor de muncă pentru personalul acesteia din România.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei la serviciile de logistică contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de marfă (terestru, naval și aerian), inspecție și control al produselor importate sau exportate, comisionari vamali s.a.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei la serviciile de asistență turistică și călătorii de afaceri contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de persoane, rezervări servicii hoteliere, rent-a-car, ghizi însoțitori s.a.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei în spațiile și sălile de ședință și protocol, precum și la dotările tehnice din sediul central al camerei bilaterale pentru organizarea întâlnirilor și evenimentelor proprii.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează accesul la expertiză pentru analizarea, evaluarea și soluționarea disputelor și conflictelor comerciale ale companiei cu parteneri din România, înainte ca acestea să ajungă în instanță și oferă metode alternative de soluționare a acestora prin mediere, conciliere și arbitraj.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asistă compania în fața Curții de Arbitraj Comercial Internațional de pe lângă Camera de Comerț și Industrie a României pentru soluționarea  litigiilor comerciale.",
        destinatari: ["Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Informare și documentare",
    beneficii: [
      {
        descriere:
          "Asigură accesul companiei la baza de date, studiile documentare, documentele de analiză, evaluări și statistici elaborate de camera bilaterală, referitoare la evoluția economică și socială din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Furnizează informații cu privire la mediul de afaceri, politicile de concurență și practicile de proprietate intelectuală din România și UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Transmite companiei informații actualizate privind cadrul legislativ cu incidență asupra mediului de afaceri și investițional din România și UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania cu privire la facilitățile și avantajele oferite de România pentru investițiile străine realizate pe teritoriul său.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă  companiei informații privind identificarea și atragerea surselor de finanțare pentru proiectele de afaceri ale acestora din România și UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania cu privire la formalitățile, normele și procedurile privind înființarea, organizarea și funcționarea societăților comerciale în România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania despre oportunitățile de participare a reprezentanților companiei la diverse forme de training sau la accesarea de burse de studii în România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Imagine și promovare",
    beneficii: [
      {
        descriere:
          "Asigură accesul companiei la evenimentele organizate de camera bilaterală pentru realizarea unor acțiuni sau campanii publicitare de promovare a proiectelor, imaginii și a brand-urilor acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează produsele și serviciile companiei în cadrul standurilor organizate de camera bilaterală la târgurile și expozițiile la care este prezentă, în România sau în R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează logo-urile companiei pe documentele și materialele de publicitate ale evenimentelor camerei bilaterale, în condițiile convenite de comun acord.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează imaginea companiei și distribuie știrile care privesc activitatea acesteia în publicațiile și cataloagele tipărite, precum și pe web-siteul sau  pe paginile de socializare ale camerei bilaterale.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă dreptul de a utiliza logo-ul camerei bilaterale pe documentele și materialele de imagine și promovare ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă distincții și titluri onorifice companiei sau reprezentanților acesteia, pentru activitatea, proiectele sau parteneriatele economice și comerciale cu caracter bilateral.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
];

const facilitatiInEnglish = [
  {
    categorie: "Strategic involvement",
    beneficii: [
      {
        descriere:
          "Assigns the company's representative the position of vice-president of the CCIRC and member of the National Management Council of the bilateral chamber.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Assigns the company's representative the coordination of the activities of the bilateral chamber in the field of the company's activity, developed within the specialized internal department of the CCIRC.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Ensures the company's participation in the development of the CCIRC procedures for selecting and proposing business opportunities and projects bilaterally in areas of interest to its activity.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Co-opts the company's representatives in the delegations of the bilateral chamber that participate in official meetings in Romania or within the economic missions of the Romanian authorities in the People's Republic of China.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's services or products, including its special offers to the members of the bilateral chamber, directly or through the M2M system.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's contacts and dialogues with the representatives of the Romanian diplomatic missions in the People's Republic of China.",
        destinatari: ["Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Representation and public relations",
    beneficii: [
      {
        descriere:
          "Supports and represents the general interests of the company in relation to the central and local public administration institutions, as well as to the national bodies and organizations to which the bilateral chamber belongs.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Defends the company's strategic interests in the business environment and in front of the authorities, including by supporting its advocacy campaigns.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the meetings, dialogues, and presentation visits of the company with the representatives of the authorities, institutions, or economic, chamber, employer, and trade union associations, at central and local level in Romania.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's investment projects and public-private partnership initiatives in front of the administrative territorial units in Romania.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the participation of the company's representatives in the events of the bilateral chamber, organized or in partnership, as well as in the events or activities to which it is invited to participate, in Romania, in order to develop the company's public and commercial relations.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates obtaining invitations and access to public or private events in Romania and the People's Republic of China, which are of interest to the development of the company's bilateral economic relations (forums, conferences, congresses, seminars, workshops, etc.).",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's access to fairs, exhibitions, and other similar economic and commercial events that take place in Romania or in the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with support for organizing its own events in Romania, by ensuring the participation of the representatives of the authorities, institutions, or economic, chamber, employer, and trade union associations, at central and local level in Romania.",
        destinatari: ["Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Business support",
    beneficii: [
      {
        descriere:
          "Identifies and verifies the company's potential business partners in Romania (legal, financial, and creditworthiness analyzes).",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Presents to the company projects or business opportunities communicated to the bilateral chamber by Romanian companies or other public or private entities in Romania, which may be considered of interest to it.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Presents the company's projects or business opportunities to potential Romanian business partners or to the bilateral chamber's collaborators in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Organizes B2B meetings for the company's representatives with companies or economic and commercial entities in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company, under contractual conditions, with business, legal, and financial consultancy, as well as other services specific to the development of bilateral projects.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides specialized assistance to the company's representatives in negotiations with economic and commercial entities in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's contacts with partners in Romania for the safe and quality conduct of import-export business operations on the European market.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Organizes or facilitates the registration and participation of the company's representatives or staff in seminars presenting the business environment and economic and commercial legislation in Romania and the EU, as well as in negotiation and communication training courses specific to bilateral relations with Romanian partners, as well as in learning the Romanian language.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Lists on the bilateral chamber's business platform, within the M2M inter-member cooperation and networking systems with partners from Romania, the company's business opportunities or project proposals.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with assistance in preparing the specific documents for obtaining transit visas, short or long-stay visas, as well as work permits for its staff in Romania.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Ensures the company's access to the logistics services contracted by the bilateral chamber with its partners, at preferential rates: internal and international freight transport (land, sea, and air), inspection and control of imported or exported products, customs brokers, etc.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Ensures the company's access to the tourist assistance and business travel services contracted by the bilateral chamber with its partners, at preferential rates: internal and international passenger transport, hotel service reservations, rent-a-car, accompanying guides, etc.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Ensures the company's access to the meeting and protocol spaces and rooms, as well as to the technical facilities in the central headquarters of the bilateral chamber for organizing its own meetings and events.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates access to expertise for analyzing, evaluating, and resolving the company's commercial disputes and conflicts with Romanian partners, before they reach the court, and offers alternative methods of resolving them through mediation, conciliation, and arbitration.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Assists the company in front of the International Commercial Arbitration Court attached to the Chamber of Commerce and Industry of Romania for the settlement of commercial disputes.",
        destinatari: ["Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Information and documentation",
    beneficii: [
      {
        descriere:
          "Ensures the company's access to the database, documentary studies, analysis documents, evaluations, and statistics elaborated by the bilateral chamber, regarding the economic and social evolution in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides information on the business environment, competition policies, and intellectual property practices in Romania and the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Transmits to the company information regarding the legislative framework with an impact on the business and investment environment in Romania and the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the facilities and advantages offered by Romania for foreign investments made on its territory.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with information on identifying and attracting sources of funding for its business projects in Romania and the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the formalities, norms, and procedures regarding the establishment, organization, and operation of commercial companies in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the opportunities for the participation of its representatives in various forms of training or accessing study grants in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Image and promotion",
    beneficii: [
      {
        descriere:
          "Ensures the company's access to the events organized by the bilateral chamber to carry out actions or advertising campaigns to promote its projects, image, and brands.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's products and services within the stands organized by the bilateral chamber at the fairs and exhibitions it attends, in Romania or in the People's Republic of China.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's logos on the advertising documents and materials of the bilateral chamber's events, under the agreed conditions.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's image and distributes the news related to its activity in the printed publications and catalogs, as well as on the website or social media pages of the bilateral chamber.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Grants the right to use the logo of the bilateral chamber on the company's image and promotional documents.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Grants distinctions and honorary titles to the company or its representatives, for the activity, projects, or economic and commercial partnerships with a bilateral character.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
];

const facilitatiInChinese = [
  {
    categorie: "战略参与",
    beneficii: [
      {
        descriere:
          "指定公司代表为CCIRC的副主席和双边商会的全国管理委员会成员。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "指定公司代表协调双边商会在公司活动领域内的活动，这些活动在CCIRC的专业内部部门中开展。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "确保公司参与CCIRC制定的选择和提议业务机会和双边项目的程序，这些项目涉及公司活动领域的利益。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "将公司代表纳入参加在罗马尼亚举行的官方会议或罗马尼亚当局在中华人民共和国举行的经济任务中的双边商会代表团。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "直接或通过M2M系统向双边商会成员推广公司的服务或产品，包括其特别优惠。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere: "促进公司与罗马尼亚外交使团的联系和对话。",
        destinatari: ["战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "代表和公共关系",
    beneficii: [
      {
        descriere:
          "支持和代表公司在与中央和地方公共行政机构的关系中的一般利益，以及在双边商会所属的国家机构和组织面前。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "在商业环境中捍卫公司的战略利益，并在当局面前支持其倡导活动。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司与罗马尼亚中央和地方层面的当局、机构或经济、商会、雇主和工会协会的代表之间的会议、对话和访问。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在罗马尼亚的行政领土单位面前推广公司的投资项目和公私合作倡议。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司代表参加双边商会的活动，无论是自己组织的还是合作组织的，以及公司被邀请参加的活动或活动，目的是发展公司的公共和商业关系。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "促进公司在罗马尼亚和中华人民共和国举行的对发展双边经济关系有利的公共或私人活动的邀请和参与。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司参加在罗马尼亚或欧盟举行的展会、展览和其他类似的经济和商业活动。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "通过确保中央和地方层面的当局、机构或经济、商会、雇主和工会协会的代表参与公司自己的活动，为公司组织自己的活动提供支持。",
        destinatari: ["战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "商务支持",
    beneficii: [
      {
        descriere:
          "确定并验证罗马尼亚潜在的商业合作伙伴（法律、财务和信用分析）。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司介绍罗马尼亚公司或其他罗马尼亚公共或私人实体向双边商会通报的项目或商业机会，这些机会可能对公司有利。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向潜在的罗马尼亚商业合作伙伴或双边商会在罗马尼亚的合作伙伴介绍公司的项目或商业机会。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere: "为公司的代表与罗马尼亚公司或经济和商业实体举行B2B会议。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "根据合同条件，为公司提供商业、法律和财务咨询，以及其他特定于双边项目发展的服务。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司的代表在与罗马尼亚经济和商业实体的谈判中提供专业协助。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "促进公司与罗马尼亚合作伙伴的联系，以便在欧洲市场上安全和高质量地进行进出口业务操作。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "组织或促进公司代表或员工参加在罗马尼亚和欧盟举行的关于罗马尼亚和欧盟的商业环境和经济和商业立法的研讨会，以及关于与罗马尼亚合作伙伴的双边关系的谈判和沟通培训课程，以及学习罗马尼亚语。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会的业务平台上列出公司的商业机会或项目提议，这些机会或项目提议是公司的成员。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供协助，以准备特定文件，以获得公司在罗马尼亚的员工的过境签证、短期或长期签证，以及工作许可。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "确保公司可以以优惠价格获得双边商会与其合作伙伴签订的物流服务：国内和国际货物运输（陆地、海洋和空运）、进口或出口产品的检验和控制、海关代理等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "确保公司可以以优惠价格获得双边商会与其合作伙伴签订的旅游援助和商务旅行服务：国内和国际客运、酒店服务预订、汽车租赁、陪同导游等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "确保公司可以使用双边商会总部的会议和礼仪空间和房间，以及技术设施，以组织自己的会议和活动。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "为分析、评估和解决公司与罗马尼亚合作伙伴的商业纠纷和冲突提供专业知识，以防止它们进入法庭，并提供通过调解、调解和仲裁解决它们的替代方法。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "协助公司在罗马尼亚工商业联合会附属的国际商事仲裁法庭面前解决商业纠纷。",
        destinatari: ["战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "信息和文件",
    beneficii: [
      {
        descriere:
          "确保公司可以访问由双边商会编制的关于罗马尼亚经济和社会发展的数据库、文献研究、分析文件、评估和统计数据。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "提供有关罗马尼亚和欧盟的商业环境、竞争政策和知识产权实践的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司传达对罗马尼亚和欧盟的商业和投资环境产生影响的立法框架的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司提供有关罗马尼亚为在其领土上进行的外国投资提供的便利和优势的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司的商业项目在罗马尼亚和欧盟的资金来源的识别和吸引提供信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司提供有关在罗马尼亚设立、组织和运营商业公司的形式、规范和程序的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司提供有关公司代表参加罗马尼亚各种培训形式或在罗马尼亚学习奖学金的机会的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "形象和推广",
    beneficii: [
      {
        descriere:
          "确保公司可以参加双边商会组织的活动，以开展促进其项目、形象和品牌的广告活动。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会参加的展会和展览上推广公司的产品和服务，无论是在罗马尼亚还是在中华人民共和国。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会活动的广告文件和材料上推广公司的标志，条件是双方达成一致。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会的印刷出版物和目录以及双边商会的网站或社交媒体页面上推广公司的形象，并发布与公司活动相关的新闻。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere: "授予公司在其形象和推广文件上使用双边商会标志的权利。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "授予公司或其代表荣誉称号，以表彰其在双边经济和商业合作中的活动、项目或合作伙伴关系。",
        destinatari: ["成员", "战略合作伙伴"],
      },
    ],
  },
];

export default function FacilitatiB() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container mx-auto bg-white rounded-xl p-4">
      <h1 className="text-4xl font-bold text-center mb-8 mt-4">
        {t("lista_beneficii_2")}
      </h1>
      {i18n.language === "ro" && (
        <>
          {facilitati.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {i18n.language === "en" && (
        <>
          {facilitatiInEnglish.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {i18n.language === "ch" && (
        <>
          {facilitatiInChinese.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
