import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";

export default function PostPage() {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null); // Stat pentru erori
  const { userInfo } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate(); // Navigare pentru redirect
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const role = userInfo?.role;

  useEffect(() => {
    async function fetchPost() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/post/${id}`,
          {
            credentials: "include", // Include cookie-urile pentru autentificare
          }
        );

        if (response.status === 401) {
          setError(
            "Trebuie să fii autentificat pentru a accesa această postare."
          );
        } else if (response.status === 403) {
          setError("Nu ai permisiunea să accesezi această postare.");
        } else if (response.ok) {
          const data = await response.json();
          setPost(data);
        } else {
          setError("A apărut o eroare la încărcarea postării.");
        }
      } catch (err) {
        console.error("Eroare la preluarea postării:", err);
        setError("A apărut o eroare la preluarea postării.");
      }
    }
    fetchPost();
  }, [id]);

  if (error) {
    return (
      <div className="text-center flex flex-col mt-10 text-red-500">
        {error}
        <button
          className="mt-4 bg-gray-500 hover:bg-gray-700 w-fit self-center text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate("/login")}
        >
          Autentifică-te
        </button>
      </div>
    );
  }

  if (!post) {
    return <p className="text-center mt-10 text-gray-500">Se încarcă...</p>;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-md overflow-hidden py-6">
        <p className="text-sm text-gray-500 text-center mb-3">
          {post.date[i18n.language]}
        </p>
        {/* Titlul postării */}
        <h1 className="text-3xl w-11/12 mx-auto text-center font-bold text-gray-900 mb-6">
          {post.title[i18n.language]}
        </h1>
        {role === "admin" && (
          <div className="flex justify-center mb-4">
            <Link
              to={`/edit/${post._id}`}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg flex gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
              Editează postarea
            </Link>
          </div>
        )}

        {/* Imaginea postării */}
        <div className="mb-4">
          <img
            src={post.image}
            alt={post.title}
            className="w-11/12 mx-auto h-96 object-cover object-center rounded-md shadow-md"
          />
        </div>

        {/* Conținutul postării */}
        <div
          className="w-11/12 mx-auto max-w-none text-gray-800 leading-relaxed ql-editor"
          dangerouslySetInnerHTML={{ __html: post.content[i18n.language] }}
        />
      </div>
    </>
  );
}
