import React from "react";
import { useTranslation } from "react-i18next";

const studiesSectionA = [
  {
    docNumber: "B.I /01",
    title: "Tratate și acorduri diplomatice bilaterale în vigoare",
    type: "Studiu Statistic",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /02",
    title: "Pagini semnificative din istoria relațiilor bilaterale",
    type: "Studiu Documentar",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /03",
    title: "Relații bilaterale la nivel înalt (Președinție, Parlament, Guvern)",
    type: "Studiu Documentar",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /04",
    title:
      "Relații bilaterale la nivelul instituțiilor și organizațiilor centrale",
    type: "Studiu Documentar",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /05",
    title:
      "Relații bilaterale la nivelul instituțiilor și organizațiilor locale",
    type: "Studiu Documentar",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /06",
    title:
      "Relații bilaterale la nivel educațional, academic și în domeniul cercetării științifice",
    type: "Studiu Documentar",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /07",
    title:
      "Sinologi români si vorbitori de limba chineza din România și R.P. Chineză",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /08",
    title:
      "Românologi chinezi si vorbitori de limba romana din R.P. Chineză și România",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /09",
    title:
      "Personalități și promotori din R.P. Chineză, activi in cooperarea bilaterală",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.I /10",
    title:
      "Înfrățiri la nivelul unităților administrativ teritoriale din România și R.P. Chineză",
    type: "Studiu Statistic",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
];

const studiesSectionB = [
  {
    docNumber: "B.II /01",
    title: "Cooperarea bilaterala economica și comerciala",
    type: "Studiu Documentar",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /02",
    title:
      "Stadiul acordurilor ANSVSA/GACC privind exportul de produse agro-alimentare în R.P. Chineză",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /03",
    title:
      "Stadiul dialogului privind deschiderea unei linii aeriene directe Romania-R.P.Chineza",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /04",
    title:
      "Companii chineze din România și principalele investiții ale acestora",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /05",
    title:
      "Companii românești din R.P. Chineză și principalele investiții ale acestora",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /06",
    title:
      "Procedurile privind înființarea unei reprezentanțe sau a unei companii în România cu acționariat chinez",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /07",
    title:
      "Procedurile privind înființarea unei reprezentanțe sau a unei companii în R.P. Chineză cu acționariat românesc",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /08",
    title:
      "Acorduri UE – China privind protejarea produselor cu indicatie geografica",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /09",
    title:
      "Evaluări privind producția și potențialul pentru export a companiilor românești din domeniul vini-viticol",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.II /10",
    title:
      "Evaluări privind producția și potențialul pentru export a companiilor românești din domeniul alimentar",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
];

const studiesSectionC = [
  {
    docNumber: "B.III /01",
    title:
      "Dialogul cooperării UE - R.P. Chineză (tratate bilaterale și documente strategice)",
    type: "Studiu Documentar",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /02",
    title:
      "Asociații naționale de promovare a relațiilor bilaterale cu R.P. Chineză din Europa",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /03",
    title:
      "Liniile aeriene care operează destinații între Europa și R.P.Chineză",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /04",
    title: "Cooperarea în cadrul inițiativei R.P. Chineză-CEEC (17+1)",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /05",
    title:
      "Analiza și prezentarea activității CHINA-CEE EUROPE INVESTMENT COOP. FUND",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /06",
    title: "Principalele investiții ale companiilor chineze în Europa",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /07",
    title:
      "Politica europeană anti-dumping asupra unor produse și materiale din R.P. Chineză",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /08",
    title:
      "Companii chineze din domeniul auto cu activități comerciale în Europa și politica UE în domeniu",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.III /09",
    title:
      "Companii chineze din domeniul energiei verzi cu activități comerciale în Europa și politica UE în domeniu",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
];

const studiesSectionD = [
  {
    docNumber: "B.IV /01",
    title:
      "Analiza parteneriatelor și cooperării în cadrul inițiativei ONE BELT ONE ROAD (OBOR)",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /02",
    title:
      "Analiza parteneriatelor și cooperării în cadrul ASIA INFRASTRUCTURE INVESTMENT BANK (AIIB)",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /03",
    title: "Participare la TRANS-EURASIA EXPRESS RAILWAY",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /04",
    title: "Stadiul actual al relațiilor bilaterale R.P. Chineză - SUA",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /05",
    title: "Stadiul actual al relațiilor bilaterale R.P. Chineză - RUSIA",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /06",
    title: "Stadiul actual al relațiilor bilaterale R.P. Chineză - AFRICA",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /07",
    title: "Stadiul actual al cooperării în cadrul ASEAN",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /08",
    title: "Stadiul actual al cooperării în cadrul BRICS",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.IV /09",
    title:
      "Temele sensibile pe agenda relațiilor internaționale ale R.P. Chineză",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
];

const studiesSectionE = [
  {
    docNumber: "B.V /01",
    title: "Ghid INFO TRIP România",
    type: "Studiu Statistic",
    accessLevel: "General",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /02",
    title:
      "Instituții cu activități dedicate promovării istoriei și culturii chineze din România",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /03",
    title:
      "Organizații și asociații cu activitate în domeniul promovării relațiilor bilaterale",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /04",
    title: "Comunitatea chineză din România",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /05",
    title: "Catalogul oportunităților chineze din România",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /06",
    title:
      "Proiecte educaționale și schimburi de studenți la nivel universitar",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Perisabil",
  },
  {
    docNumber: "B.V /07",
    title:
      "Procedura acordării vizelor de călătorie în România pentru cetățenii chinezi",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /08",
    title:
      "Institutele Confucius din România și instituții cu obiective educaționale chineze",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /09",
    title:
      "Calendarul principalelor evenimente economice și târguri din România",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Perisabil",
  },
  {
    docNumber: "B.V /10",
    title:
      "Compendiu de legislație economică și comercială specifică investițiilor străine",
    type: "Studiu Statistic",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /11",
    title: "Programul Guvernului în domeniul promovării investițiilor străine",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Perisabil",
  },
  {
    docNumber: "B.V /12",
    title:
      "Studii cu privire la produsele românești cu potențial pentru export în R.P. Chineză",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
  {
    docNumber: "B.V /13",
    title:
      "Responsabilități instituționale asupra promovării economice și comerciale internaționale a României",
    type: "Studiu Documentar",
    accessLevel: "Intern",
    validity: "Up (upgradare periodica)",
  },
];

const studiesSectionAinEnglish = [
  {
    docNumber: "B.I /01",
    title: "Treaties and bilateral diplomatic agreements in force",
    type: "Statistical Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /02",
    title: "Significant pages from the history of bilateral relations",
    type: "Documentary Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /03",
    title:
      "Bilateral relations at the highest level (Presidency, Parliament, Government)",
    type: "Documentary Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /04",
    title:
      "Bilateral relations at the level of central institutions and organizations",
    type: "Documentary Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /05",
    title:
      "Bilateral relations at the level of local institutions and organizations",
    type: "Documentary Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /06",
    title:
      "Bilateral relations at the educational, academic and scientific research level",
    type: "Documentary Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /07",
    title: "Romanian sinologists and Chinese speakers from Romania and the PRC",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /08",
    title:
      "Chinese romanologists and Romanian speakers from the PRC and Romania",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /09",
    title:
      "Personalities and promoters from the PRC, active in bilateral cooperation",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.I /10",
    title:
      "Twinning at the level of administrative-territorial units from Romania and the PRC",
    type: "Statistical Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
];

const studiesSectionAinChinese = [
  {
    docNumber: "B.I /01",
    title: "现行条约和双边外交协议",
    type: "统计研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /02",
    title: "双边关系历史上的重要页面",
    type: "纪录片研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /03",
    title: "最高级别的双边关系（总统、议会、政府）",
    type: "纪录片研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /04",
    title: "中央机构和组织层面的双边关系",
    type: "纪录片研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /05",
    title: "地方机构和组织层面的双边关系",
    type: "纪录片研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /06",
    title: "教育、学术和科学研究层面的双边关系",
    type: "纪录片研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /07",
    title: "罗马尼亚汉学家和罗马尼亚和中华人民共和国的汉语演讲者",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /08",
    title: "中国罗马学家和中华人民共和国和罗马尼亚的汉语演讲者",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /09",
    title: "中华人民共和国的个性和促进者，活跃于双边合作",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.I /10",
    title: "罗马尼亚和中华人民共和国的行政地区单位的结对",
    type: "统计研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
];

const studiesSectionBinEnglish = [
  {
    docNumber: "B.II /01",
    title: "Bilateral economic and commercial cooperation",
    type: "Documentary Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /02",
    title:
      "Status of ANSVSA/GACC agreements on the export of agri-food products to the PRC",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /03",
    title:
      "Status of the dialogue on opening a direct air route Romania-PR China",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /04",
    title: "Chinese companies in Romania and their main investments",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /05",
    title: "Romanian companies in the PRC and their main investments",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /06",
    title:
      "Procedures for setting up a representative office or a company in Romania with Chinese shareholders",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /07",
    title:
      "Procedures for setting up a representative office or a company in the PRC with Romanian shareholders",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /08",
    title:
      "EU agreements with China on the protection of products with geographical indication",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /09",
    title:
      "Evaluations on the production and export potential of Romanian companies in the wine sector",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.II /10",
    title:
      "Evaluations on the production and export potential of Romanian companies in the food sector",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
];

const studiesSectionBinChinese = [
  {
    docNumber: "B.II /01",
    title: "双边经济和商业合作",
    type: "纪录片研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /02",
    title: "ANSVSA/GACC协议关于农产品出口到中华人民共和国的状态",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /03",
    title: "罗马尼亚-中华人民共和国直航航线对话的状态",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /04",
    title: "罗马尼亚的中国公司及其主要投资",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /05",
    title: "中华人民共和国的罗马尼亚公司及其主要投资",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /06",
    title: "在罗马尼亚设立代表处或公司的程序，拥有中国股东",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /07",
    title: "在中华人民共和国设立代表处或公司的程序，拥有罗马尼亚股东",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /08",
    title: "欧盟与中国关于地理标志产品保护的协议",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /09",
    title: "罗马尼亚葡萄酒行业公司的生产和出口潜力评估",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.II /10",
    title: "罗马尼亚食品行业公司的生产和出口潜力评估",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
];

const studiesSectionCinEnglish = [
  {
    docNumber: "B.III /01",
    title:
      "EU-China cooperation dialogue (bilateral treaties and strategic documents)",
    type: "Documentary Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /02",
    title:
      "National associations promoting bilateral relations with the PRC in Europe",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /03",
    title: "Airlines operating destinations between Europe and the PRC",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /04",
    title: "Cooperation within the China-CEEC initiative (17+1)",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /05",
    title:
      "Analysis and presentation of the activity of CHINA-CEE EUROPE INVESTMENT COOP. FUND",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /06",
    title: "Main investments of Chinese companies in Europe",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /07",
    title: "EU anti-dumping policy on some products and materials from the PRC",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /08",
    title:
      "Chinese auto companies with commercial activities in Europe and EU policy in the field",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.III /09",
    title:
      "Chinese green energy companies with commercial activities in Europe and EU policy in the field",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
];

const studiesSectionCinChinese = [
  {
    docNumber: "B.III /01",
    title: "欧盟-中国合作对话（双边条约和战略文件）",
    type: "纪录片研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /02",
    title: "欧洲推动与中华人民共和国双边关系的国家协会",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /03",
    title: "在欧洲和中华人民共和国之间运营航线的航空公司",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /04",
    title: "中国-中东欧国家合作倡议（17+1）内的合作",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /05",
    title: "中国-中东欧欧洲投资合作基金活动分析和展示",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /06",
    title: "中国公司在欧洲的主要投资",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /07",
    title: "欧盟对中华人民共和国某些产品和材料的反倾销政策",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /08",
    title: "中国汽车公司在欧洲的商业活动和欧盟政策",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.III /09",
    title: "中国绿色能源公司在欧洲的商业活动和欧盟政策",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
];

const studiesSectionDinEnglish = [
  {
    docNumber: "B.IV /01",
    title:
      "Analysis of partnerships and cooperation within the ONE BELT ONE ROAD initiative (OBOR)",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /02",
    title:
      "Analysis of partnerships and cooperation within the ASIA INFRASTRUCTURE INVESTMENT BANK (AIIB)",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /03",
    title: "Participation at TRANS-EURASIA EXPRESS RAILWAY",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /04",
    title: "Current status of bilateral relations PRC - USA",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /05",
    title: "Current status of bilateral relations PRC - RUSSIA",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /06",
    title: "Current status of bilateral relations PRC - AFRICA",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /07",
    title: "Current status of cooperation within ASEAN",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /08",
    title: "Current status of cooperation within BRICS",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.IV /09",
    title: "Sensitive topics on the PRC's international relations agenda",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
];

const studiesSectionDinChinese = [
  {
    docNumber: "B.IV /01",
    title: "ONE BELT ONE ROAD倡议（OBOR）合作伙伴关系和合作分析",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /02",
    title: "亚洲基础设施投资银行（AIIB）合作伙伴关系和合作分析",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /03",
    title: "参与跨欧亚大陆快铁",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /04",
    title: "中华人民共和国-美国双边关系的现状",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /05",
    title: "中华人民共和国-俄罗斯双边关系的现状",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /06",
    title: "中华人民共和国-非洲双边关系的现状",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /07",
    title: "东盟内合作的现状",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /08",
    title: "金砖国家内合作的现状",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.IV /09",
    title: "中华人民共和国国际关系议程上的敏感话题",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
];

const studiesSectionEinEnglish = [
  {
    docNumber: "B.V /01",
    title: "INFO TRIP Romania Guide",
    type: "Statistical Study",
    accessLevel: "General",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /02",
    title:
      "Institutions with activities dedicated to promoting Chinese history and culture in Romania",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /03",
    title:
      "Organizations and associations with activity in promoting bilateral relations",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /04",
    title: "The Chinese community in Romania",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /05",
    title: "Catalog of Chinese opportunities in Romania",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /06",
    title: "Educational projects and student exchanges at university level",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Perishable",
  },
  {
    docNumber: "B.V /07",
    title: "Procedure for granting travel visas to Chinese citizens in Romania",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /08",
    title:
      "Confucius Institutes in Romania and institutions with Chinese educational objectives",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /09",
    title: "Calendar of the main economic events and fairs in Romania",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Perishable",
  },
  {
    docNumber: "B.V /10",
    title:
      "Compendium of economic and commercial legislation specific to foreign investments",
    type: "Statistical Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /11",
    title: "Government program in the field of promoting foreign investments",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Perishable",
  },
  {
    docNumber: "B.V /12",
    title: "Studies on Romanian products with export potential to the PRC",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
  {
    docNumber: "B.V /13",
    title:
      "Institutional responsibilities for the international economic and commercial promotion of Romania",
    type: "Documentary Study",
    accessLevel: "Internal",
    validity: "Up (periodic upgrade)",
  },
];

const studiesSectionEinChinese = [
  {
    docNumber: "B.V /01",
    title: "INFO TRIP罗马尼亚指南",
    type: "统计研究",
    accessLevel: "一般",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /02",
    title: "在罗马尼亚推广中国历史和文化的活动机构",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /03",
    title: "在推动双边关系方面活动的组织和协会",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /04",
    title: "罗马尼亚的中国社区",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /05",
    title: "罗马尼亚的中国机会目录",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /06",
    title: "大学层面的教育项目和学生交流",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "易腐",
  },
  {
    docNumber: "B.V /07",
    title: "在罗马尼亚为中国公民发放旅行签证的程序",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /08",
    title: "罗马尼亚的孔子学院和具有中国教育目标的机构",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /09",
    title: "罗马尼亚主要经济活动和展会日历",
    type: "统计研究",
    accessLevel: "内部",
    validity: "易腐",
  },
  {
    docNumber: "B.V /10",
    title: "外国投资特定经济和商业立法汇编",
    type: "统计研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /11",
    title: "促进外国投资领域的政府计划",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "易腐",
  },
  {
    docNumber: "B.V /12",
    title: "具有向中华人民共和国出口潜力的罗马尼亚产品研究",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
  {
    docNumber: "B.V /13",
    title: "罗马尼亚国际经济和商业促进的机构责任",
    type: "纪录片研究",
    accessLevel: "内部",
    validity: "升级（定期升级）",
  },
];

export default function StudiiDocumentare() {
  const { t, i18n } = useTranslation();
  return (
    <section className="p-8 space-y-12 bg-white rounded-lg">
      {/* Titlu principal */}
      <h1 className="text-3xl font-bold text-center text-gray-800">
        {t("studii_documentare")}
      </h1>

      {/* Section A */}
      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          {t("studii_d1")}
        </h2>

        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
                <th className="py-3 px-4 text-left">Nr. Doc.</th>
                <th className="py-3 px-4 text-left">Titlul Documentului</th>
                <th className="py-3 px-4 text-left">Tip Document</th>
                <th className="py-3 px-4 text-left">Nivel Acces</th>
                <th className="py-3 px-4 text-left">Valabilitate</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {i18n.language === "ro" && (
                <>
                  {studiesSectionA.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "en" && (
                <>
                  {studiesSectionAinEnglish.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "ch" && (
                <>
                  {studiesSectionAinChinese.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Section B */}

      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          {t("studii_d2")}
        </h2>

        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
                <th className="py-3 px-4 text-left">{t("nr_document")}</th>
                <th className="py-3 px-4 text-left">
                  {t("titlul_documentului")}
                </th>
                <th className="py-3 px-4 text-left">{t("tip_document")}</th>
                <th className="py-3 px-4 text-left">{t("nivel_acces")}</th>
                <th className="py-3 px-4 text-left">{t("valabilitate")}</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {i18n.language === "ro" && (
                <>
                  {studiesSectionB.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "en" && (
                <>
                  {studiesSectionBinEnglish.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "ch" && (
                <>
                  {studiesSectionBinChinese.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Section C */}

      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          {t("studii_d3")}
        </h2>

        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
                <th className="py-3 px-4 text-left">{t("nr_document")}</th>
                <th className="py-3 px-4 text-left">
                  {t("titlul_documentului")}
                </th>
                <th className="py-3 px-4 text-left">{t("tip_document")}</th>
                <th className="py-3 px-4 text-left">{t("nivel_acces")}</th>
                <th className="py-3 px-4 text-left">{t("valabilitate")}</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {i18n.language === "ro" && (
                <>
                  {studiesSectionC.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "en" && (
                <>
                  {studiesSectionCinEnglish.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "ch" && (
                <>
                  {studiesSectionCinChinese.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Section D */}
      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          {t("studii_d4")}
        </h2>

        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
                <th className="py-3 px-4 text-left">{t("nr_document")}</th>
                <th className="py-3 px-4 text-left">
                  {t("titlul_documentului")}
                </th>
                <th className="py-3 px-4 text-left">{t("tip_document")}</th>
                <th className="py-3 px-4 text-left">{t("nivel_acces")}</th>
                <th className="py-3 px-4 text-left">{t("valabilitate")}</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {i18n.language === "ro" && (
                <>
                  {studiesSectionD.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "en" && (
                <>
                  {studiesSectionDinEnglish.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "ch" && (
                <>
                  {studiesSectionDinChinese.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Section E */}
      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          {t("studii_d5")}
        </h2>

        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
                <th className="py-3 px-4 text-left">{t("nr_document")}</th>
                <th className="py-3 px-4 text-left">
                  {t("titlul_documentului")}
                </th>
                <th className="py-3 px-4 text-left">{t("tip_document")}</th>
                <th className="py-3 px-4 text-left">{t("nivel_acces")}</th>
                <th className="py-3 px-4 text-left">{t("valabilitate")}</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {i18n.language === "ro" && (
                <>
                  {studiesSectionE.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "en" && (
                <>
                  {studiesSectionEinEnglish.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
              {i18n.language === "ch" && (
                <>
                  {studiesSectionEinChinese.map((study, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                    >
                      <td className="py-3 px-4 text-left font-medium">
                        {study.docNumber}
                      </td>
                      <td className="py-3 px-4">{study.title}</td>
                      <td className="py-3 px-4">{study.type}</td>
                      <td className="py-3 px-4">{study.accessLevel}</td>
                      <td className="py-3 px-4">{study.validity}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
