import { useTranslation } from "react-i18next";

export default function ParteneriServicii() {
  const { t } = useTranslation();

  const sponsori = [
    { name: "Price Water House", src: "/images/logos/pwc.png" },
    { name: "Libra Bank", src: "/images/logos/librabank.png" },
    { name: "Musat", src: "/images/logos/musat.png" },
    { name: "Bank of China", src: "/images/logos/bankofchina.png" },
    { name: "Marsh", src: "/images/logos/marsh.png" },
    { name: "Paunescu & Assoc", src: "/images/logos/paunescu.png" },
    { name: "RomAqua", src: "/images/logos/romaqua.png" },
    { name: "Wisse Financial Solutions", src: "/images/logos/wfs.png" },
    { name: "National Cert", src: "/images/logos/nationalcert.png" },
    { name: "Iorgulescu & Asoc", src: "/images/logos/iorgulescu.png" },
    { name: "Capisizu & Asoc", src: "/images/logos/capisizu.png" },
    { name: "Gorunescu & Asoc", src: "/images/logos/gorunescu.png" },
    { name: "Phoenix Tour", src: "/images/logos/phoenix.png" },
    { name: "Lucky Print", src: "/images/logos/luckyprint.png" },
    { name: "SM Containers", src: "/images/logos/sm.png" },
    { name: "AMMA Print", src: "/images/logos/amma.png" },
    { name: "Lighthouse Golf Resort", src: "/images/logos/lighthouse.png" },
    { name: "Romanita Holiday Club", src: "/images/logos/romanita.png" },
  ];

  return (
    <div className="bg-white p-4 rounded-md mt-6 shadow-md">
      <h2 className="text-2xl font-bold text-center mb-6">
        {t("parteneri_servicii")}
      </h2>
      <div className="grid grid-cols-2 gap-2">
        {sponsori.map((sponsor) => (
          <div
            key={sponsor.name}
            className="flex justify-center items-center hover:scale-105 transition-transform duration-200"
          >
            <img
              src={sponsor.src}
              alt={sponsor.name}
              className="max-h-20 object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
