import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function BlogPost({
  title,
  date,
  content,
  image,
  id,
  visibility,
}) {
  const { t } = useTranslation();
  return (
    <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-md blog-post overflow-hidden my-5">
      <img
        src={image}
        alt={title} // Afișează titlul în funcție de limbă
        className="w-full h-64 object-cover object-center transition-opacity duration-300 hover:opacity-85"
      />
      <div className="p-4 flex flex-col justify-around h-full">
        {/* Blog Title */}
        <p className="text-sm text-gray-500 mb-1 italic text-end">{date}</p>
        <h2 className="text-xl font-bold text-gray-900 mb-2 transition-colors duration-300 line-clamp-3 overflow-hidden text-ellipsis">
          {title}{" "}
          {visibility === "private" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 inline ml-2 text-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
              />
            </svg>
          )}
        </h2>

        {/* Blog Content with line clamp */}
        <p className="text-gray-700 text-sm leading-relaxed line-clamp-3 mb-4 overflow-hidden text-ellipsis">
          {content}
        </p>

        <Link
          to={`/blog/${id}`}
          className="text-red-500 hover:text-red-600 font-semibold w-fit flex items-center gap-1 translate-on-hover"
        >
          {t("citeste_mai_mult")}{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5 transform duration-300"
          >
            <path
              fillRule="evenodd"
              d="M2 10a.75.75 0 0 1 .75-.75h12.59l-2.1-1.95a.75.75 0 1 1 1.02-1.1l3.5 3.25a.75.75 0 0 1 0 1.1l-3.5 3.25a.75.75 0 1 1-1.02-1.1l2.1-1.95H2.75A.75.75 0 0 1 2 10Z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}
