import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export default function CreatePost() {
  const [postData, setPostData] = useState({
    title: { ro: "", en: "", ch: "" },
    summary: { ro: "", en: "", ch: "" },
    content: { ro: "", en: "", ch: "" },
    date: { ro: "", en: "", ch: "" },
    visibility: "public",
    file: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();

  const handleChange = (field, lang, value) => {
    setPostData((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [lang]: value,
      },
    }));
  };

  const createNewPost = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    const data = new FormData();
    data.append("titleRo", postData.title.ro);
    data.append("titleEn", postData.title.en);
    data.append("titleCh", postData.title.ch);
    data.append("summaryRo", postData.summary.ro);
    data.append("summaryEn", postData.summary.en);
    data.append("summaryCh", postData.summary.ch);
    data.append("contentRo", postData.content.ro);
    data.append("contentEn", postData.content.en);
    data.append("contentCh", postData.content.ch);
    data.append("dateRo", postData.date.ro);
    data.append("dateEn", postData.date.en);
    data.append("dateCh", postData.date.ch);
    data.append("visibility", postData.visibility);
    if (postData.file) data.append("file", postData.file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/post`, {
        method: "POST",
        body: data,
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Something went wrong!");
      }

      setSuccess("Post created successfully!");
      setPostData({
        title: { ro: "", en: "", ch: "" },
        summary: { ro: "", en: "", ch: "" },
        content: { ro: "", en: "", ch: "" },
        date: { ro: "", en: "", ch: "" },
        visibility: "public",
        file: null,
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 200);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-10">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">
        Crează o nouă postare
      </h2>
      <form onSubmit={createNewPost} className="space-y-6">
        {["ro", "en", "ch"].map((lang) => (
          <div key={lang}>
            <label
              className="block text-gray-700 font-medium mb-2"
              htmlFor={`title_${lang}`}
            >
              Titlu ({lang.toUpperCase()})
            </label>
            <input
              id={`title_${lang}`}
              type="text"
              placeholder={`Enter the title in ${lang.toUpperCase()}`}
              value={postData.title[lang]}
              onChange={(e) => handleChange("title", lang, e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ))}

        {["ro", "en", "ch"].map((lang) => (
          <div key={lang}>
            <label
              className="block text-gray-700 font-medium mb-2"
              htmlFor={`summary_${lang}`}
            >
              Rezumat ({lang.toUpperCase()})
            </label>
            <input
              id={`summary_${lang}`}
              type="text"
              placeholder={`Enter the summary in ${lang.toUpperCase()}`}
              value={postData.summary[lang]}
              onChange={(e) => handleChange("summary", lang, e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ))}

        {["ro", "en", "ch"].map((lang) => (
          <div key={lang}>
            <label
              className="block text-gray-700 font-medium mb-2"
              htmlFor={`content_${lang}`}
            >
              Conținutul postării ({lang.toUpperCase()})
            </label>
            <ReactQuill
              id={`content_${lang}`}
              value={postData.content[lang]}
              modules={modules}
              formats={formats}
              onChange={(value) => handleChange("content", lang, value)}
              className="bg-white"
            />
          </div>
        ))}

        {/* Other form elements go here, such as date, visibility, file upload */}
        {["ro", "en", "ch"].map((lang) => (
          <div key={lang}>
            <label
              className="block text-gray-700 font-medium mb-2"
              htmlFor={`date_${lang}`}
            >
              Data ({lang.toUpperCase()})
            </label>
            <input
              id={`date_${lang}`}
              type="text"
              placeholder={`Enter the date in ${lang.toUpperCase()}`}
              value={postData.date[lang]}
              onChange={(e) => handleChange("date", lang, e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ))}

        {/* Visibility */}

        <div>
          <label
            className="block text-gray-700 font-medium mb-2"
            htmlFor="visibility"
          >
            Vizibilitate
          </label>
          <select
            id="visibility"
            value={postData.visibility}
            onChange={(e) =>
              setPostData({ ...postData, visibility: e.target.value })
            }
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="public">Public</option>
            <option value="private">Doar pentru membri</option>
          </select>
        </div>

        <div>
          <label
            className="block text-gray-700 font-medium mb-2"
            htmlFor="file"
          >
            Încarcă imaginea
          </label>
          <input
            id="file"
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={(e) =>
              setPostData({ ...postData, file: e.target.files[0] })
            }
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300 disabled:bg-gray-400"
          >
            {loading ? "Se crează..." : "Crează postare"}
          </button>
        </div>

        {error && <p className="text-red-500 mt-2">{error}</p>}
        {success && <p className="text-green-500 mt-2">{success}</p>}
      </form>
    </div>
  );
}
