import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCalendarAlt, FaMapMarkerAlt, FaBuilding } from "react-icons/fa";

const events = [
  {
    date: "01-05 Ianuarie",
    location: "Shanghai",
    event: "CNIBF - Târg internațional pentru industria de baterii cu litiu",
    organizer: "Beijing Zhenwei Exhibition Co. Ltd.",
  },
  {
    date: "06-09 Ianuarie",
    location: "Hong Kong",
    event: "HKTGF - Târg de jucării",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "06-09 Ianuarie",
    location: "Hong Kong",
    event: "HKISF - Târg pentru produse de papetărie și birotică",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "10-12 Ianuarie",
    location: "Beijing",
    event:
      "ISPO - Târg internațional de echipamente de fitness, sportive, textile și camping",
    organizer: "Messe Munchen Shanghai Co. Ltd",
  },
  {
    date: "15-17 Februarie",
    location: "Guangzhou",
    event:
      "DPES Sign Expo China - Târg internațional de sisteme de imprimare digitală, gravură și semnalistică",
    organizer: "Dpes China",
  },
  {
    date: "17-19 Februarie",
    location: "Shenzhen",
    event: "LED CHINA - Serie de expoziții LED",
    organizer: "Trust Exhibition Co. Ltd",
  },
  {
    date: "19-21 Februarie",
    location: "Guangzhou",
    event:
      "PCHI - Târg de ingrediente pentru produse de îngrijire personală și a casei",
    organizer: "Reed Sinopharm Exhibitions Co. Limited",
  },
  {
    date: "20-22 Februarie",
    location: "Shanghai",
    event: "SIOF - Târg internațional de optică",
    organizer: "China Optometric & Optical Association",
  },
  {
    date: "20-22 Februarie",
    location: "Beijing",
    event:
      "ISH China & CIHE - Târg internațional de sisteme de încălzire, ventilație și aer condiționat",
    organizer: "Messe Frankfurt (Shanghai) Co. Limited",
  },
  {
    date: "01-04 Martie",
    location: "Shanghai",
    event: "East China Fair - Târg asiatic de produse textile și îmbracăminte",
    organizer: "Shanghai ECF Co. Ltd.",
  },
  {
    date: "02-06 Martie",
    location: "Hong Kong",
    event:
      "Hong Kong International Diamond, Gem & Pearl Show - Târg de Pietre prețioase și perle",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "03-06 Martie",
    location: "Guangzhou",
    event:
      "Dental South China - Expoziție internațională de echipamente și tehnologii dentare",
    organizer:
      "Guangdong International Science & Technology Exhibition Company",
  },
  {
    date: "04-06 Martie",
    location: "Guangzhou",
    event:
      "Printing South China - Expoziție internațională pentru industria sistemelor de tipărire",
    organizer: "China Foreign Trade Centre (Group)",
  },
  {
    date: "04-07 Martie",
    location: "Shanghai",
    event:
      "APPPEXPO Shanghai International Printing Exhibition - Expoziție internațională pentru industria sistemelor de tipărire, publicitate și semnalistică",
    organizer: "Shanghai Modern International Exhibition CO. Limited",
  },
  {
    date: "04-08 Martie",
    location: "Hong Kong",
    event: "Hong Kong International Jewellery Show - Târg de bijuterii",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "05-07 Martie",
    location: "Shanghai",
    event: "SIFE - Expoziție internațională de Fitness",
    organizer: "Shanghai Donnor Exhibition Service Co., Ltd.",
  },
  {
    date: "05-07 Martie",
    location: "Guangzhou",
    event:
      "International Baby Products and Toys Expo - Expoziție Internațională de produse și jucării pentru copii",
    organizer: "Guangdong Chaoyu Exhibition Co., Ltd",
  },
  {
    date: "06-09 Martie",
    location: "Beijing",
    event:
      "Beijing Building Materials Exhibition - Târg internațional pentru materiale de construcție și decorațiuni",
    organizer: "China B&D Exhibition CO., Ltd.",
  },
  {
    date: "07-09 Martie",
    location: "Shenzhen",
    event:
      "International Smart Display and Integrated System Exhibition - Târg internațional pentru sisteme de display interactiv",
    organizer: "Canton Fair Advertising Co., Ltd.",
  },
  {
    date: "10-12 Martie",
    location: "Guangzhou",
    event:
      "China International Beauty Expo - Târg internațional pentru produse cosmetice",
    organizer: "Guangzhou Jiamei Exhibition Co., Ltd",
  },
  {
    date: "11-13 Martie",
    location: "Shanghai",
    event: "CHIC Spring - Expoziție internațională de modă",
    organizer: "China National Garment Association",
  },
  {
    date: "11-13 Martie",
    location: "Shanghai",
    event: "Intertextile Shanghai Appareal Fabrics - Târg de materiale textile",
    organizer: "Messe Frankfurt (Shanghai) Co. Limited",
  },
  {
    date: "17-19 Martie",
    location: "Shanghai",
    event:
      "China International Agrochemical & Crop Protection Exhibition - Târg internațional de Agrochimie",
    organizer: "CCPIT Sub-Council of Chemical Industry",
  },
  {
    date: "17-19 Martie",
    location: "Shanghai",
    event:
      "China International Food Additivies and Ingredients Exhibition - Expoziție de ingrediente și aditivi alimentari",
    organizer: "CCPIT Sub-Council of Light Industry",
  },
  {
    date: "20-23 Martie",
    location: "Shanghai",
    event:
      "Appliance & Electronics Worlds Expo - Târg internațional de electrocasnice",
    organizer: "China Household Electrical Appliances Association (CHEAA)",
  },
  {
    date: "28-31 Martie",
    location: "Guangzhou",
    event: "Interzum - Târg internațional de design interior și mobilă",
    organizer: "Koelnmesse (Beijing) Co., Ltd.",
  },
  {
    date: "30-02 Aprilie",
    location: "Shanghai",
    event:
      "HOTELEX International Hotel and Catering Expo - Expoziție în domeniul ospitalității și industriei serviciilor alimentare",
    organizer:
      "Shanghai Sinoexpo Informa Markets International Exhibition Co., Ltd.",
  },
  {
    date: "01-03 Aprilie",
    location: "Beijing",
    event:
      "International Beauty And Cosmetics Expo - Expoziție internațională de produse cosmetice",
    organizer: "Beijing Expo Exhibition Services Ltd.",
  },
  {
    date: "07-09 Aprilie",
    location: "Shenzhen",
    event: "International Toy & Hobby Fair - Târg internațional de jucării",
    organizer: "Messe Frankfurt (HK) Ltd.",
  },
  {
    date: "08-11 Aprilie",
    location: "Shanghai",
    event:
      "China International Medical Equipment Fair - Târg internațional de echipamente medicale",
    organizer: "RX Global HQ",
  },
  {
    date: "11-14 Aprilie",
    location: "Hong Kong",
    event:
      "Global Sources Consumer Electronics Show - Expoziție de electronice",
    organizer: "Global Sources Exhibitions",
  },
  {
    date: "13-16 Aprilie",
    location: "Hong Kong",
    event: "Hong Kong Electronics Fair - Târg de electronice",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "15-17 Aprilie",
    location: "Shanghai",
    event: "Electronica China - Târg de electronice",
    organizer: "Meese Munchen Shanghai Co. Ltd",
  },
  {
    date: "15-19 Aprilie",
    location: "Guangzhou",
    event:
      "Canton Fair Prima Fază a Ediției de Primăvară - Târg de electronice și lumini",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "15-18 Aprilie",
    location: "Shenzhen",
    event: "Chinaplas - Târg internațional de plastic și cauciuc",
    organizer: "ADSALE",
  },
  {
    date: "16-18 Aprilie",
    location: "Suzhou",
    event:
      "CLNB - Expoziție internațională a industriei energetice regenerabile",
    organizer: "SMM Information & Technology Co., Ltd.",
  },
  {
    date: "24-26 Aprilie",
    location: "Beijing",
    event: "China Outbound Travel & Tourism Market - Turism",
    organizer: "Tarsus China",
  },
  {
    date: "23-27 Aprilie",
    location: "Guangzhou",
    event:
      "Canton Fair a Doua Fază a Ediției de Primăvară - Târg de ceramică și bunuri de consum",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "23-02 Aprilie-Mai",
    location: "Shanghai",
    event: "Auto Shanghai - Expoziție internațională de automobile",
    organizer: "Messe Munchen Shanghai Co. Ltd",
  },
  {
    date: "1-5 Mai",
    location: "Guangzhou",
    event:
      "Canton Fair a Treia Fază a Ediției de Primăvară - Târg de materiale textile și produse medicale",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "Mai",
    location: "Ningbo",
    event: "China CEEC Expo",
    organizer: "China-CEEC Expo; International Consumer Goods Fair",
  },
  {
    date: "10-12 Mai",
    location: "Guangzhou",
    event:
      "China International Metal & Metallurgy Exhibition - Expoziție internațională de metal și metalurgie",
    organizer: "Guangzhou Julang Exhibition Design Co., Ltd.",
  },
  {
    date: "10-12 Mai",
    location: "Guangzhou",
    event:
      "HOTELEX - Expoziție în domeniul ospitalității și industriei serviciilor alimentare",
    organizer:
      "Shanghai Sinoexpo Informa Markets International Exhibition Co., Ltd.",
  },
  {
    date: "12-14 Mai",
    location: "Shanghai",
    event: "China Beauty Expo - Expoziție internațională de produse cosmetice",
    organizer: "Informa Markets - Sinoexpo",
  },
  {
    date: "15-17 Mai",
    location: "Guangzhou",
    event: "GITF - International Travel Fair (turism)",
    organizer: "Hannover Milano Exhibition (Shanghai) Co., Ltd.",
  },
  {
    date: "15-17 Mai",
    location: "Guangzhou",
    event:
      "Shoes & Leather - Expoziție internațională de pantofi și produse din piele",
    organizer: "Top Repute Co. Ltd.",
  },
  {
    date: "19-21 Mai",
    location: "Shanghai",
    event: "SIAL - Târg de alimente și răcoritoare",
    organizer: "Comexposium-Sial Exhibition Co. Ltd.",
  },
  {
    date: "19-22 Mai",
    location: "Shanghai",
    event: "Bakery China - Expoziție internațională de produse de brutărie",
    organizer: "Bakery China Exhibitions Co., Ltd.",
  },
  {
    date: "26-28 Mai",
    location: "Shanghai",
    event: "DOMOTEX ASIA/CHINAFLOOR - Expoziție pentru industria de pardoseli",
    organizer: "Globus Events Limited",
  },
  {
    date: "26-28 Mai",
    location: "Hong Kong",
    event:
      "International Medical and Healthcare Fair - Târg internațional de medicină",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "27-29 Mai",
    location: "Hong Kong",
    event: "Vinexpo - Târg de vinuri",
    organizer: "Vinexposium",
  },
  {
    date: "27-30 Mai",
    location: "Shanghai",
    event:
      "Kitchen & Bath China - Târg internațional pentru produse de baie și bucătărie",
    organizer: "Worldwide Exhibitions Service Co. Limited",
  },
  {
    date: "27-30 Mai",
    location: "Guangzhou",
    event: "Prolight & Sound - Expoziție pentru industria de lumini și sunet",
    organizer: "Messe Frankfurt (HK) Ltd.",
  },
  {
    date: "09-12 Iunie",
    location: "Guangzhou",
    event:
      "International Lighting Exhibition - Expoziție de produse din industria de lumini",
    organizer: "Guangzhou Guangya Messe Frankfurt Co Ltd",
  },
  {
    date: "09-12 Iunie",
    location: "Beijing",
    event:
      "China International Dental Exhibition & Scientific Conference - Expoziție internațională de echipamente și tehnologii dentare",
    organizer: "International Health Exchange & Cooperation Centre",
  },
  {
    date: "12-15 Iunie",
    location: "Hong Kong",
    event: "Hong Kong International Travel Expo (turism)",
    organizer: "TKS Exhibition Services Limited",
  },
  {
    date: "11-13 Iunie",
    location: "Shanghai",
    event:
      "International Photovoltaic Power Generation and Smart Energy Conference & Exhibition - Energie Solară",
    organizer: "SNEC",
  },
  {
    date: "12-14 Iunie",
    location: "Guangzhou",
    event:
      "International Health Industry Expo - Expoziție Internațională pentru sănătate și nutriție",
    organizer: "Guangzhou Yifan Exhibition Co. Ltd.",
  },
  {
    date: "24-26 Iunie",
    location: "Shanghai",
    event:
      "Healthplex Expo - Expoziție internațională farmaceutică și de produse medicale naturale",
    organizer:
      "Shanghai Sinoexpo Informa Markets International Exhibition Co., Ltd.",
  },
  {
    date: "09-11 Iulie",
    location: "Shanghai",
    event:
      "Aluminum China Expo - Expoziție internațională de produse din aluminiu",
    organizer: "RX Shanghai",
  },
  {
    date: "09-11 Iulie",
    location: "Shanghai",
    event:
      "International Automotive Manufacturing Technology & Materials Exhibition - Expoziție internațională de automobile",
    organizer: "Reed Exhibitions Hengjin (Shanghai) Co., Ltd",
  },
  {
    date: "16-18 Iulie",
    location: "Shanghai",
    event:
      "CBME Maternity, Infant and Child Exhibition & Supply Chain - Expoziție internațională de produse pentru copii și bebeluși",
    organizer: "Informa Markets - Shanghai, China",
  },
  {
    date: "08-10 August",
    location: "Guangzhou",
    event: "Solar PV & Energy Storage World Expo - Energie Solară",
    organizer: "Guangdong Grandeur International Exhibition Group",
  },
  {
    date: "13-15 August",
    location: "Shanghai",
    event:
      "China International Automotive Interiors and Exteriors Exhibition - Expoziție internațională de automobile",
    organizer: "Info Convention and Exhibition (Shanghai) Co., Ltd",
  },
  {
    date: "14-18 August",
    location: "Hong Kong",
    event: "Food Expo PRO - Expoziție de produse alimentare",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "14-18 August",
    location: "Hong Kong",
    event:
      "Beauty & Wellness Expo - Expoziție de cosmetice și produse de îngrijire a pielii",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "23-25 August",
    location: "Hong Kong",
    event:
      "Hong Kong International Dental Expo & Symposium - Expoziție internațională de echipamente și tehnologii dentare",
    organizer: "Hong Kong Dental Association",
  },
  {
    date: "25-27 August",
    location: "Shanghai",
    event: "World Seafood - Expoziție internațională de fructe de mare",
    organizer: "Shanghai Aige Exhibition Service Co., Ltd.",
  },
  {
    date: "27-29 August",
    location: "Shenzhen",
    event:
      "International Internet of Things Exhibition - Expoziție internațională de IT și telecomunicații",
    organizer: "Shenzhen Ulink Media Co., Ltd.",
  },
  {
    date: "02-04 Septembrie",
    location: "Guangzhou",
    event:
      "China International Hair Fair - Târg Internațional de produse și accesorii pentru păr",
    organizer: "International Hair Fair",
  },
  {
    date: "03-05 Septembrie",
    location: "Hong Kong",
    event:
      "Asia Fruit Logistica - Expoziție internațională pentru fructe și legume",
    organizer: "Global Produce Events GmbH",
  },
  {
    date: "09-12 Septembrie",
    location: "Shanghai",
    event: "China International Furnitre Fair - Târg internațional de mobilă",
    organizer: "China Foreign Trade Guangzhou Exhibition General Corporation",
  },
  {
    date: "11-13 Septembrie",
    location: "Guangzhou",
    event:
      "China International Fisheries & Seafood Expo - Expoziție internațională de fructe de mare",
    organizer: "Guangzhou Boyi Global Exhibition Co. Ltd.",
  },
  {
    date: "24-26 Septembrie",
    location: "Shenzhen",
    event:
      "China International Logistics and Supply Chain Fair - Târg internațional de logistică și transport",
    organizer: "Shenzhen Logistics and Supply Chain Management Association",
  },
  {
    date: "23-27 Septembrie",
    location: "Shanghai",
    event: "China International Industry Fair",
    organizer: "Donghao Lansheng (Group) Co., Ltd.",
  },
  {
    date: "13-15 Octombrie",
    location: "Shanghai",
    event: "AgrochemEx - Târg internațional de agrochimicale",
    organizer: "China Crop Protection Industry Association",
  },
  {
    date: "15-17 Octombrie",
    location: "Shanghai",
    event: "China Toy Expo - Expoziție internațională de jucării",
    organizer: "China Toy & Juvenile Products Association",
  },
  {
    date: "15-19 Octombrie",
    location: "Guangzhou",
    event:
      "Canton Fair Prima Fază a Ediției de Toamnă - Târg de electronice și lumini",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "20 Octombrie",
    location: "Shanghai",
    event: "China International Hardware Show",
    organizer: "Koelnmesse (Beijing) Co., Ltd.",
  },
  {
    date: "21-24 Octombrie",
    location: "Yiwu",
    event: "Yiwu Fair - Yiwu Commodities Fair",
    organizer: "Yiwu International Commodities Fair",
  },
  {
    date: "23-27 Octombrie",
    location: "Guangzhou",
    event:
      "Canton Fair a Doua Fază a Ediției de Toamnă - Târg de ceramică și bunuri de consum",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "31-04 Octombrie-Noiembrie",
    location: "Guangzhou",
    event:
      "Canton Fair a Treia Fază a Ediției de Toamnă - Târg de materiale textile și produse medicale",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "20-22 Noiembrie",
    location: "Guangzhou",
    event: "China Automotive Technology Expo - Expoziție de automobile",
    organizer: "Wuhan J&S Watson Exhibition Co., Ltd.",
  },
  {
    date: "Noiembrie",
    location: "Shanghai",
    event: "Prowine - Târg de băuturi spirtoase și vinuri",
    organizer: "Messe Dusseldorf GmbH",
  },
  {
    date: "Noiembrie",
    location: "Shanghai",
    event: "China International Import Expo - Târg de import și export",
    organizer:
      "China Intl. Import Expo Bureau National Exhibition and Convention Center (Shanghai)",
  },
  {
    date: "Noiembrie",
    location: "Shanghai",
    event: "Bauma China - Târg pentru echipamente și tehnologii de construcție",
    organizer: "Messe Munchen Shanghai Co. Ltd.",
  },
  {
    date: "Noiembrie",
    location: "Shenzhen",
    event: "China Hi-Tech Fair - Târg de electronice",
    organizer: "Shenzhen Zhenwei International Exhibition Co., Ltd.",
  },
  {
    date: "TBC",
    location: "Beijing",
    event: "International Automotive Exhibition - Expoziție de automobile",
    organizer: "China Auto Modification Accessories Association",
  },
];

const conference = [
  {
    date: "10-12 Ianuarie",
    location: "Beijing",
    event: "Beijing Model United Nations Conference",
    organizer: "Beimun",
  },
  {
    date: "15-17 Ianuarie",
    location: "Shanghai",
    event: "B2B Marketing Chief Congress",
    organizer: "Global ConsignIndex Co., Ltd.",
  },
  {
    date: "16-19 Ianuarie",
    location: "Hong Kong",
    event: "HKTDC - Convenție pentru prospecte de carieră și educație",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "03-04 Februarie",
    location: "Guangzhou",
    event:
      "ICASS - International Conference on Anthropological and Sociological Sciences",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "15-16 Februarie",
    location: "Chengdu",
    event:
      "ICCAE - International Conference on Civil and Architectural Engineering",
    organizer:
      "International Academic of Science Technology Engineering and Management",
  },
  {
    date: "15-16 Martie",
    location: "Chengdu",
    event:
      "ICEBR - International Conference on Economics and Business Research",
    organizer: "ISER",
  },
  {
    date: "10-11 Aprilie",
    location: "Chengdu",
    event: "ICSG - International Conference of Sociology of Gender",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "11-13 Aprilie",
    location: "Chongqing",
    event:
      "IEEE - International Conference on Power System and Smart Grid Technologies",
    organizer: "CBEES",
  },
  {
    date: "15-16 Aprilie",
    location: "Chengdu",
    event:
      "ICENS - International Conference on Environment and Natural Science",
    organizer: "ISERD - Malaysia",
  },
  {
    date: "16-17 Aprilie",
    location: "Guangzhou",
    event:
      "ICCARVE - International Conference on Control, Automation, Robotics and Vision Engineering",
    organizer:
      "International Academic of Science Technology Engineering and Management",
  },
  {
    date: "28-30 Aprilie",
    location: "Hong Kong",
    event: "Asian Licensing Conference",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "08-10 Mai",
    location: "Shanghai",
    event: "FBIF - Food & Beverage Innovation Forum",
    organizer: "Simbai Events",
  },
  {
    date: "17-20 Mai",
    location: "Chengdu",
    event: "IEEE - The International Conference on Electronics Technology",
    organizer: "Sichuan Institute of Electronics",
  },
  {
    date: "30-31 Mai",
    location: "Shanghai",
    event:
      "ICRAET - International Conference on Recent Advances in Engineering and Technology",
    organizer:
      "International Academic of Science Technology Engineering and Management (IASTEM)",
  },
  {
    date: "18-20 Iunie",
    location: "Shanghai",
    event: "Mobile World Congress",
    organizer: "GSMA Association",
  },
  {
    date: "02-04 Iulie",
    location: "Qingdao",
    event: "QIWC - Qingdao International Water Conference",
    organizer: "Qingdao International Desalination Center",
  },
  {
    date: "16-17 Iulie",
    location: "Shanghai",
    event: "ASSS - Automotive Safety Summit",
    organizer: "Carhs gmbh",
  },
  {
    date: "15-17 August",
    location: "Beijing",
    event:
      "IEEE - International Conference on Computer and Communication Engineering Technology",
    organizer: "North China University of Technology",
  },
  {
    date: "21-22 August",
    location: "Shanghai",
    event: "CSSOPE - China Sourcing Summit on Petroleum & Chemical Equipment",
    organizer: "The Original Pro-Energy Consulting Organization (Topco)",
  },
  {
    date: "09-12 Septembrie",
    location: "Shenzhen",
    event: "OGC - Optoelectronics Global Conference",
    organizer: "IEEE Photonics Society",
  },
  {
    date: "27-28 Septembrie",
    location: "Hong Kong",
    event:
      "ICGSDS - International Conference on Gender, Sexuality and Diversity Studies",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "18-19 Octombrie",
    location: "Shanghai",
    event:
      "ICASS - International Conference on Anthropological and Sociological Sciences",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "09-10 Decembrie",
    location: "Macao",
    event: "ICWPP - International Conference on Women, Power and Politics",
    organizer: "World Academy of Science, Engineering and Technology",
  },
];

const conferenceInEnglish = [
  {
    date: "10-12 January",
    location: "Beijing",
    event: "Beijing Model United Nations Conference",
    organizer: "Beimun",
  },
  {
    date: "15-17 January",
    location: "Shanghai",
    event: "B2B Marketing Chief Congress",
    organizer: "Global ConsignIndex Co., Ltd.",
  },
  {
    date: "16-19 January",
    location: "Hong Kong",
    event: "HKTDC - Career & Education Expo",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "03-04 February",
    location: "Guangzhou",
    event:
      "ICASS - International Conference on Anthropological and Sociological Sciences",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "15-16 February",
    location: "Chengdu",
    event:
      "ICCAE - International Conference on Civil and Architectural Engineering",
    organizer:
      "International Academic of Science Technology Engineering and Management",
  },
  {
    date: "15-16 March",
    location: "Chengdu",
    event:
      "ICEBR - International Conference on Economics and Business Research",
    organizer: "ISER",
  },
  {
    date: "10-11 April",
    location: "Chengdu",
    event: "ICSG - International Conference of Sociology",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "11-13 April",
    location: "Chongqing",
    event:
      "IEEE - International Conference on Power System and Smart Grid Technologies",
    organizer: "CBEES",
  },
  {
    date: "15-16 April",
    location: "Chengdu",
    event:
      "ICENS - International Conference on Environment and Natural Science",
    organizer: "ISERD - Malaysia",
  },
  {
    date: "16-17 April",
    location: "Guangzhou",
    event:
      "ICCARVE - International Conference on Control, Automation, Robotics and Vision Engineering",
    organizer:
      "International Academic of Science Technology Engineering and Management",
  },
  {
    date: "28-30 April",
    location: "Hong Kong",
    event: "Asian Licensing Conference",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "08-10 May",
    location: "Shanghai",
    event: "FBIF - Food & Beverage Innovation Forum",
    organizer: "Simbai Events",
  },
  {
    date: "17-20 May",
    location: "Chengdu",
    event: "IEEE - The International Conference on Electronics Technology",
    organizer: "Sichuan Institute of Electronics",
  },
  {
    date: "30-31 May",
    location: "Shanghai",
    event:
      "ICRAET - International Conference on Recent Advances in Engineering and Technology",
    organizer:
      "International Academic of Science Technology Engineering and Management (IASTEM)",
  },
  {
    date: "18-20 June",
    location: "Shanghai",
    event: "Mobile World Congress",
    organizer: "GSMA Association",
  },
  {
    date: "02-04 July",
    location: "Qingdao",
    event: "QIWC - Qingdao International Water Conference",
    organizer: "Qingdao International Desalination Center",
  },
  {
    date: "16-17 July",
    location: "Shanghai",
    event: "ASSS - Automotive Safety Summit",
    organizer: "Carhs gmbh",
  },
  {
    date: "15-17 August",
    location: "Beijing",
    event:
      "IEEE - International Conference on Computer and Communication Engineering Technology",
    organizer: "North China University of Technology",
  },
  {
    date: "21-22 August",
    location: "Shanghai",
    event: "CSSOPE - China Sourcing Summit on Petroleum & Chemical Equipment",
    organizer: "The Original Pro-Energy Consulting Organization (Topco)",
  },
  {
    date: "09-12 September",
    location: "Shenzhen",
    event: "OGC - Optoelectronics Global Conference",
    organizer: "IEEE Photonics Society",
  },
  {
    date: "27-28 September",
    location: "Hong Kong",
    event: "ICGSDS - International Conference",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "18-19 October",
    location: "Shanghai",
    event:
      "ICASS - International Conference on Anthropological and Sociological Sciences",
    organizer: "World Academy of Science, Engineering and Technology",
  },
  {
    date: "09-10 December",
    location: "Macao",
    event: "ICWPP - International Conference",
    organizer: "World Academy of Science, Engineering and Technology",
  },
];

const conferenceInChinese = [
  {
    date: "10-12 一月",
    location: "北京",
    event: "北京模拟联合国大会",
    organizer: "Beimun",
  },
  {
    date: "15-17 一月",
    location: "上海",
    event: "B2B营销主席大会",
    organizer: "Global ConsignIndex Co., Ltd.",
  },
  {
    date: "16-19 一月",
    location: "香港",
    event: "HKTDC - 职业和教育博览会",
    organizer: "香港贸易发展局（HKTDC）",
  },
  {
    date: "03-04 二月",
    location: "广州",
    event: "ICASS - 人类学和社会学科学国际会议",
    organizer: "世界科学、工程和技术学院",
  },
  {
    date: "15-16 二月",
    location: "成都",
    event: "ICCAE - 土木和建筑工程国际会议",
    organizer: "国际科学技术工程和管理学术",
  },
  {
    date: "15-16 三月",
    location: "成都",
    event: "ICEBR - 经济和商业研究国际会议",
    organizer: "ISER",
  },
  {
    date: "10-11 四月",
    location: "成都",
    event: "ICSG - 社会学国际会议",
    organizer: "世界科学、工程和技术学院",
  },
  {
    date: "11-13 四月",
    location: "重庆",
    event: "IEEE - 电力系统和智能电网技术国际会议",
    organizer: "CBEES",
  },
  {
    date: "15-16 四月",
    location: "成都",
    event: "ICENS - 环境和自然科学国际会议",
    organizer: "ISERD - 马来西亚",
  },
  {
    date: "16-17 四月",
    location: "广州",
    event: "ICCARVE - 控制、自动化、机器人和视觉工程国际会议",
    organizer: "国际科学技术工程和管理学术",
  },
  {
    date: "28-30 四月",
    location: "香港",
    event: "亚洲特许权会议",
    organizer: "香港贸易发展局（HKTDC）",
  },
  {
    date: "08-10 五月",
    location: "上海",
    event: "FBIF - 食品和饮料创新论坛",
    organizer: "Simbai Events",
  },
  {
    date: "17-20 五月",
    location: "成都",
    event: "IEEE - 电子技术国际会议",
    organizer: "四川电子学会",
  },
  {
    date: "30-31 五月",
    location: "上海",
    event: "ICRAET - 工程和技术最新进展国际会议",
    organizer: "国际科学技术工程和管理学术（IASTEM）",
  },
  {
    date: "18-20 六月",
    location: "上海",
    event: "移动世界大会",
    organizer: "GSMA协会",
  },
  {
    date: "02-04 七月",
    location: "青岛",
    event: "QIWC - 青岛国际水会议",
    organizer: "青岛国际海水淡化中心",
  },
  {
    date: "16-17 七月",
    location: "上海",
    event: "ASSS - 汽车安全峰会",
    organizer: "Carhs gmbh",
  },
  {
    date: "15-17 八月",
    location: "北京",
    event: "IEEE - 计算机和通信工程技术国际会议",
    organizer: "华北工业大学",
  },
  {
    date: "21-22 八月",
    location: "上海",
    event: "CSSOPE - 中国石油和化学设备采购峰会",
    organizer: "原始的Pro-Energy咨询组织（Topco）",
  },
  {
    date: "09-12 九月",
    location: "深圳",
    event: "OGC - 光电全球会议",
    organizer: "IEEE光子学协会",
  },
  {
    date: "27-28 九月",
    location: "香港",
    event: "ICGSDS - 性别、性取向和多样性研究国际会议",
    organizer: "世界科学、工程和技术学院",
  },
  {
    date: "18-19 十月",
    location: "上海",
    event: "ICASS - 人类学和社会学科学国际会议",
    organizer: "世界科学、工程和技术学院",
  },
  {
    date: "09-10 十二月",
    location: "澳门",
    event: "ICWPP - 妇女、权力和政治国际会议",
    organizer: "世界科学、工程和技术学院",
  },
];

const eventsInEnglish = [
  {
    date: "01-05 January",
    location: "Shanghai",
    event: "CNIBF - International Lithium Battery Industry Fair",
    organizer: "Beijing Zhenwei Exhibition Co. Ltd.",
  },

  {
    date: "06-09 January",
    location: "Hong Kong",
    event: "HKTGF - Toy Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "06-09 January",
    location: "Hong Kong",
    event: "HKISF - Stationery Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "10-12 January",
    location: "Beijing",
    event:
      "ISPO - International Fitness, Sports, Textile and Camping Equipment Fair",
    organizer: "Messe Munchen Shanghai Co. Ltd",
  },
  {
    date: "15-17 February",
    location: "Guangzhou",
    event:
      "DPES Sign Expo China - International Digital Printing, Engraving and Signage Systems Fair",
    organizer: "Dpes China",
  },
  {
    date: "17-19 February",
    location: "Shenzhen",
    event: "LED CHINA - LED Exhibition Series",
    organizer: "Trust Exhibition Co. Ltd",
  },
  {
    date: "19-21 February",
    location: "Guangzhou",
    event: "PCHI - Personal Care and Home Ingredients Fair",
    organizer: "Reed Sinopharm Exhibitions Co. Limited",
  },
  {
    date: "20-22 February",
    location: "Shanghai",
    event: "SIOF - International Optics Fair",
    organizer: "China Optometric & Optical Association",
  },
  {
    date: "20-22 February",
    location: "Beijing",
    event:
      "ISH China & CIHE - International Heating, Ventilation and Air Conditioning Systems Fair",
    organizer: "Messe Frankfurt (Shanghai) Co. Limited",
  },
  {
    date: "01-04 March",
    location: "Shanghai",
    event: "East China Fair - Asian Textile and Clothing Fair",
    organizer: "Shanghai ECF Co. Ltd.",
  },
  {
    date: "02-06 March",
    location: "Hong Kong",
    event:
      "Hong Kong International Diamond, Gem & Pearl Show - Gemstone and Pearl Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "03-06 March",
    location: "Guangzhou",
    event:
      "Dental South China - International Dental Equipment and Technology Exhibition",
    organizer:
      "Guangdong International Science & Technology Exhibition Company",
  },
  {
    date: "04-06 March",
    location: "Guangzhou",
    event: "Printing South China - International Printing Systems Exhibition",
    organizer: "China Foreign Trade Centre (Group)",
  },
  {
    date: "04-07 March",
    location: "Shanghai",
    event:
      "APPPEXPO Shanghai International Printing Exhibition - International Printing, Advertising and Signage Exhibition",
    organizer: "Shanghai Modern International Exhibition CO. Limited",
  },
  {
    date: "04-08 March",
    location: "Hong Kong",
    event: "Hong Kong International Jewellery Show - Jewelry Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "05-07 March",
    location: "Shanghai",
    event: "SIFE - International Fitness Exhibition",
    organizer: "Shanghai Donnor Exhibition Service Co., Ltd.",
  },
  {
    date: "05-07 March",
    location: "Guangzhou",
    event:
      "International Baby Products and Toys Expo - International Children's Products and Toys Exhibition",
    organizer: "Guangdong Chaoyu Exhibition Co., Ltd",
  },
  {
    date: "06-09 March",
    location: "Beijing",
    event:
      "Beijing Building Materials Exhibition - International Building Materials and Decoration Fair",
    organizer: "China B&D Exhibition CO., Ltd.",
  },
  {
    date: "07-09 March",
    location: "Shenzhen",
    event:
      "International Smart Display and Integrated System Exhibition - International Interactive Display Systems Fair",
    organizer: "Canton Fair Advertising Co., Ltd.",
  },
  {
    date: "10-12 March",
    location: "Guangzhou",
    event: "China International Beauty Expo - International Cosmetics Fair",
    organizer: "Guangzhou Jiamei Exhibition Co., Ltd",
  },
  {
    date: "11-13 March",
    location: "Shanghai",
    event: "CHIC Spring - International Fashion Exhibition",
    organizer: "China National Garment Association",
  },
  {
    date: "11-13 March",
    location: "Shanghai",
    event: "Intertextile Shanghai Appareal Fabrics - Textile Fair",
    organizer: "Messe Frankfurt (Shanghai) Co. Limited",
  },
  {
    date: "17-19 March",
    location: "Shanghai",
    event:
      "China International Agrochemical & Crop Protection Exhibition - International Agrochemical Fair",
    organizer: "CCPIT Sub-Council of Chemical Industry",
  },
  {
    date: "17-19 March",
    location: "Shanghai",
    event:
      "China International Food Additivies and Ingredients Exhibition - Food Ingredients and Additives Exhibition",
    organizer: "CCPIT Sub-Council of Light Industry",
  },
  {
    date: "20-23 March",
    location: "Shanghai",
    event:
      "Appliance & Electronics Worlds Expo - International Home Appliance Fair",
    organizer: "China Household Electrical Appliances Association (CHEAA)",
  },
  {
    date: "28-31 March",
    location: "Guangzhou",
    event: "Interzum - International Interior Design and Furniture Fair",
    organizer: "Koelnmesse (Beijing) Co., Ltd.",
  },
  {
    date: "30-02 April",
    location: "Shanghai",
    event:
      "HOTELEX International Hotel and Catering Expo - Hospitality and Catering Fair",
    organizer:
      "Shanghai Sinoexpo Informa Markets International Exhibition Co., Ltd.",
  },
  {
    date: "01-03 April",
    location: "Beijing",
    event:
      "International Beauty And Cosmetics Expo - International Cosmetics Fair",
    organizer: "Beijing Expo Exhibition Services Ltd.",
  },
  {
    date: "07-09 April",
    location: "Shenzhen",
    event: "International Toy & Hobby Fair - International Toy Fair",
    organizer: "Messe Frankfurt (HK) Ltd.",
  },
  {
    date: "08-11 April",
    location: "Shanghai",
    event:
      "China International Medical Equipment Fair - International Medical Equipment Fair",
    organizer: "RX Global HQ",
  },
  {
    date: "11-14 April",
    location: "Hong Kong",
    event: "Global Sources Consumer Electronics Show - Electronics Fair",
    organizer: "Global Sources Exhibitions",
  },
  {
    date: "13-16 April",
    location: "Hong Kong",
    event: "Hong Kong Electronics Fair - Electronics Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "15-17 April",
    location: "Shanghai",
    event: "Electronica China - Electronics Fair",
    organizer: "Meese Munchen Shanghai Co. Ltd",
  },
  {
    date: "15-19 April",
    location: "Guangzhou",
    event:
      "Canton Fair Prima Fază a Ediției de Primăvară - Electronics and Lighting Fair",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "15-18 April",
    location: "Shenzhen",
    event: "Chinaplas - International Plastic and Rubber Fair",
    organizer: "ADSALE",
  },
  {
    date: "16-18 April",
    location: "Suzhou",
    event: "CLNB - International Renewable Energy Industry Exhibition",
    organizer: "SMM Information & Technology Co., Ltd.",
  },
  {
    date: "24-26 April",
    location: "Beijing",
    event: "China Outbound Travel & Tourism Market - Tourism",
    organizer: "Tarsus China",
  },
  {
    date: "23-27 April",
    location: "Guangzhou",
    event:
      "Canton Fair a Doua Fază a Ediției de Primăvară - Ceramics and Consumer Goods Fair",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "23-02 April-May",
    location: "Shanghai",
    event: "Auto Shanghai - International Automobile Exhibition",
    organizer: "Messe Munchen Shanghai Co. Ltd",
  },
  {
    date: "1-5 May",
    location: "Guangzhou",
    event:
      "Canton Fair a Treia Fază a Ediției de Primăvară - Textile and Medical Products Fair",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "May",
    location: "Ningbo",
    event: "China CEEC Expo",
    organizer: "China-CEEC Expo; International Consumer Goods Fair",
  },
  {
    date: "10-12 May",
    location: "Guangzhou",
    event:
      "China International Metal & Metallurgy Exhibition - International Metal and Metallurgy Fair",
    organizer: "Guangzhou Julang Exhibition Design Co., Ltd.",
  },
  {
    date: "10-12 May",
    location: "Guangzhou",
    event: "HOTELEX - Hospitality and Catering Fair",
    organizer:
      "Shanghai Sinoexpo Informa Markets International Exhibition Co., Ltd.",
  },
  {
    date: "12-14 May",
    location: "Shanghai",
    event: "China Beauty Expo - International Cosmetics Fair",
    organizer: "Informa Markets - Sinoexpo",
  },
  {
    date: "15-17 May",
    location: "Guangzhou",
    event: "GITF - International Travel Fair",
    organizer: "Hannover Milano Exhibition (Shanghai) Co., Ltd.",
  },
  {
    date: "15-17 May",
    location: "Guangzhou",
    event: "Shoes & Leather - International Footwear and Leather Products Fair",
    organizer: "Top Repute Co. Ltd.",
  },
  {
    date: "19-21 May",
    location: "Shanghai",
    event: "SIAL - Food and Beverage Fair",
    organizer: "Comexposium-Sial Exhibition Co. Ltd.",
  },
  {
    date: "19-22 May",
    location: "Shanghai",
    event: "Bakery China - International Bakery Products Fair",
    organizer: "Bakery China Exhibitions Co., Ltd.",
  },
  {
    date: "26-28 May",
    location: "Shanghai",
    event: "DOMOTEX ASIA/CHINAFLOOR - Flooring Industry Fair",
    organizer: "Globus Events Limited",
  },
  {
    date: "26-28 May",
    location: "Hong Kong",
    event: "International Medical and Healthcare Fair - Medical Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "27-29 May",
    location: "Hong Kong",
    event: "Vinexpo - Wine Fair",
    organizer: "Vinexposium",
  },
  {
    date: "27-30 May",
    location: "Shanghai",
    event:
      "Kitchen & Bath China - International Bathroom and Kitchen Products Fair",
    organizer: "Worldwide Exhibitions Service Co. Limited",
  },
  {
    date: "27-30 May",
    location: "Guangzhou",
    event: "Prolight & Sound - Lighting and Sound Industry Fair",
    organizer: "Messe Frankfurt (HK) Ltd.",
  },
  {
    date: "09-12 June",
    location: "Guangzhou",
    event: "International Lighting Exhibition - Lighting Products Fair",
    organizer: "Guangzhou Guangya Messe Frankfurt Co Ltd",
  },
  {
    date: "09-12 June",
    location: "Beijing",
    event:
      "China International Dental Exhibition & Scientific Conference - International Dental Equipment and Technology Fair",
    organizer: "International Health Exchange & Cooperation Centre",
  },
  {
    date: "12-15 June",
    location: "Hong Kong",
    event: "Hong Kong International Travel Expo - Tourism Fair",
    organizer: "TKS Exhibition Services Limited",
  },
  {
    date: "11-13 June",
    location: "Shanghai",
    event:
      "International Photovoltaic Power Generation and Smart Energy Conference & Exhibition - Solar Energy",
    organizer: "SNEC",
  },
  {
    date: "12-14 June",
    location: "Guangzhou",
    event:
      "International Health Industry Expo - International Health and Nutrition Fair",
    organizer: "Guangzhou Yifan Exhibition Co. Ltd.",
  },
  {
    date: "24-26 June",
    location: "Shanghai",
    event:
      "Healthplex Expo - International Pharmaceutical and Natural Medical Products Fair",
    organizer:
      "Shanghai Sinoexpo Informa Markets International Exhibition Co., Ltd.",
  },
  {
    date: "09-11 July",
    location: "Shanghai",
    event: "Aluminum China Expo - International Aluminum Products Fair",
    organizer: "RX Shanghai",
  },
  {
    date: "09-11 July",
    location: "Shanghai",
    event:
      "International Automotive Manufacturing Technology & Materials Exhibition - International Automobile Fair",
    organizer: "Reed Exhibitions Hengjin (Shanghai) Co., Ltd",
  },
  {
    date: "16-18 July",
    location: "Shanghai",
    event:
      "CBME Maternity, Infant and Child Exhibition & Supply Chain - International Children's Products Fair",
    organizer: "Informa Markets - Shanghai, China",
  },
  {
    date: "08-10 August",
    location: "Guangzhou",
    event: "Solar PV & Energy Storage World Expo - Solar Energy",
    organizer: "Guangdong Grandeur International Exhibition Group",
  },
  {
    date: "13-15 August",
    location: "Shanghai",
    event:
      "China International Automotive Interiors and Exteriors Exhibition - International Automobile Fair",
    organizer: "Info Convention and Exhibition (Shanghai) Co., Ltd",
  },
  {
    date: "14-18 August",
    location: "Hong Kong",
    event: "Food Expo PRO - Food Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "14-18 August",
    location: "Hong Kong",
    event: "Beauty & Wellness Expo - Cosmetics and Skincare Fair",
    organizer: "Hong Kong Trade Development Council (HKTDC)",
  },
  {
    date: "23-25 August",
    location: "Hong Kong",
    event:
      "Hong Kong International Dental Expo & Symposium - International Dental Equipment and Technology Fair",
    organizer: "Hong Kong Dental Association",
  },
  {
    date: "25-27 August",
    location: "Shanghai",
    event: "World Seafood - International Seafood Fair",
    organizer: "Shanghai Aige Exhibition Service Co., Ltd.",
  },
  {
    date: "27-29 August",
    location: "Shenzhen",
    event:
      "International Internet of Things Exhibition - International IT and Telecommunications Fair",
    organizer: "Shenzhen Ulink Media Co., Ltd.",
  },
  {
    date: "02-04 September",
    location: "Guangzhou",
    event:
      "China International Hair Fair - International Hair Products and Accessories Fair",
    organizer: "International Hair Fair",
  },
  {
    date: "03-05 September",
    location: "Hong Kong",
    event: "Asia Fruit Logistica - International Fruit and Vegetable Fair",
    organizer: "Global Produce Events GmbH",
  },
  {
    date: "09-12 September",
    location: "Shanghai",
    event: "China International Furnitre Fair - International Furniture Fair",
    organizer: "China Foreign Trade Guangzhou Exhibition General Corporation",
  },
  {
    date: "11-13 September",
    location: "Guangzhou",
    event:
      "China International Fisheries & Seafood Expo - International Seafood Fair",
    organizer: "Guangzhou Boyi Global Exhibition Co. Ltd.",
  },
  {
    date: "24-26 September",
    location: "Shenzhen",
    event:
      "China International Logistics and Supply Chain Fair - International Logistics and Transport Fair",
    organizer: "Shenzhen Logistics and Supply Chain Management Association",
  },
  {
    date: "23-27 September",
    location: "Shanghai",
    event: "China International Industry Fair",
    organizer: "Donghao Lansheng (Group) Co., Ltd.",
  },
  {
    date: "13-15 October",
    location: "Shanghai",
    event: "AgrochemEx - International Agrochemical Fair",
    organizer: "China Crop Protection Industry Association",
  },
  {
    date: "15-17 October",
    location: "Shanghai",
    event: "China Toy Expo - International Toy Fair",
    organizer: "China Toy & Juvenile Products Association",
  },
  {
    date: "15-19 October",
    location: "Guangzhou",
    event:
      "Canton Fair Prima Fază a Ediției de Toamnă - Electronics and Lighting Fair",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "20 October",
    location: "Shanghai",
    event: "China International Hardware Show",
    organizer: "Koelnmesse (Beijing) Co., Ltd.",
  },
  {
    date: "21-24 October",
    location: "Yiwu",
    event: "Yiwu Fair - Yiwu Commodities Fair",
    organizer: "Yiwu International Commodities Fair",
  },
  {
    date: "23-27 October",
    location: "Guangzhou",
    event:
      "Canton Fair a Doua Fază a Ediției de Toamnă - Ceramics and Consumer Goods Fair",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "31-04 October-November",
    location: "Guangzhou",
    event:
      "Canton Fair a Treia Fază a Ediției de Toamnă - Textile and Medical Products Fair",
    organizer: "China Foreign Trade Centre",
  },
  {
    date: "20-22 November",
    location: "Guangzhou",
    event: "China Automotive Technology Expo - International Automobile Fair",
    organizer: "Wuhan J&S Watson Exhibition Co., Ltd.",
  },
  {
    date: "November",
    location: "Shanghai",
    event: "Prowine - Wine Fair",
    organizer: "Messe Dusseldorf GmbH",
  },
  {
    date: "November",
    location: "Shanghai",
    event: "China International Import Expo - Import and Export Fair",
    organizer:
      "China Intl. Import Expo Bureau National Exhibition and Convention Center (Shanghai)",
  },
  {
    date: "November",
    location: "Shanghai",
    event: "Bauma China - Construction Equipment and Technology Fair",
    organizer: "Messe Munchen Shanghai Co. Ltd.",
  },
  {
    date: "November",
    location: "Shenzhen",
    event: "China Hi-Tech Fair - Electronics Fair",
    organizer: "Shenzhen Zhenwei International Exhibition Co., Ltd.",
  },
  {
    date: "TBC",
    location: "Beijing",
    event: "International Automotive Exhibition - Automobile Fair",
    organizer: "China Auto Modification Accessories Association",
  },
];

const eventsInChinese = [
  {
    date: "01-05 一月",
    location: "上海",
    event: "CNIBF - 国际锂电池产业博览会",
    organizer: "北京振威展览有限公司",
  },
  {
    date: "06-09 一月",
    location: "香港",
    event: "HKTGF - 玩具展",
    organizer: "香港贸易发展局",
  },
  {
    date: "06-09 一月",
    location: "香港",
    event: "HKISF - 文具展",
    organizer: "香港贸易发展局",
  },
  {
    date: "10-12 一月",
    location: "北京",
    event: "ISPO - 国际健身、体育、纺织和露营设备博览会",
    organizer: "慕尼黑展览（上海）有限公司",
  },
  {
    date: "15-17 二月",
    location: "广州",
    event: "DPES Sign Expo China - 国际数字印刷、雕刻和标识系统博览会",
    organizer: "Dpes China",
  },
  {
    date: "17-19 二月",
    location: "深圳",
    event: "LED CHINA - LED展览系列",
    organizer: "信任展览有限公司",
  },
  {
    date: "19-21 二月",
    location: "广州",
    event: "PCHI - 个人护理和家庭成分博览会",
    organizer: "瑞信信和展览有限公司",
  },
  {
    date: "20-22 二月",
    location: "上海",
    event: "SIOF - 国际光学博览会",
    organizer: "中国验光师协会",
  },
  {
    date: "20-22 二月",
    location: "北京",
    event: "ISH China & CIHE - 国际供暖、通风和空调系统博览会",
    organizer: "法兰克福展览（上海）有限公司",
  },
  {
    date: "01-04 三月",
    location: "上海",
    event: "华东博览会 - 亚洲纺织服装博览会",
    organizer: "上海华东博览有限公司",
  },
  {
    date: "02-06 三月",
    location: "香港",
    event: "香港国际钻石、宝石和珍珠展 - 宝石和珍珠博览会",
    organizer: "香港贸易发展局",
  },
  {
    date: "03-06 三月",
    location: "广州",
    event: "Dental South China - 国际牙科设备和技术展览会",
    organizer: "广东国际科技展览公司",
  },
  {
    date: "04-06 三月",
    location: "广州",
    event: "Printing South China - 国际印刷系统展览会",
    organizer: "中国外贸中心（集团）",
  },
  {
    date: "04-07 三月",
    location: "上海",
    event: "APPPEXPO上海国际印刷展 - 国际印刷、广告和标识展览会",
    organizer: "上海现代国际展览有限公司",
  },
  {
    date: "04-08 三月",
    location: "香港",
    event: "香港国际珠宝展 - 珠宝博览会",
    organizer: "香港贸易发展局",
  },
  {
    date: "05-07 三月",
    location: "上海",
    event: "SIFE - 国际健身展",
    organizer: "上海东诺展览服务有限公司",
  },
  {
    date: "05-07 三月",
    location: "广州",
    event: "国际婴儿用品和玩具博览会 - 国际儿童用品和玩具展览会",
    organizer: "广东朝宇展览有限公司",
  },
  {
    date: "06-09 三月",
    location: "北京",
    event: "北京建材展 - 国际建材和装饰博览会",
    organizer: "中国建博展览有限公司",
  },
  {
    date: "07-09 三月",
    location: "深圳",
    event: "国际智能显示和综合系统展览会 - 国际互动显示系统博览会",
    organizer: "广交会广告有限公司",
  },
  {
    date: "10-12 三月",
    location: "广州",
    event: "中国国际美容博览会 - 国际化妆品博览会",
    organizer: "广州嘉美展览有限公司",
  },
  {
    date: "11-13 三月",
    location: "上海",
    event: "CHIC春季 - 国际时尚展览会",
    organizer: "中国服装协会",
  },
  {
    date: "11-13 三月",
    location: "上海",
    event: "Intertextile Shanghai Appareal Fabrics - 纺织博览会",
    organizer: "法兰克福展览（上海）有限公司",
  },
  {
    date: "17-19 三月",
    location: "上海",
    event: "中国国际农药和作物保护展览会 - 国际农药博览会",
    organizer: "中国化工行业理事会",
  },
  {
    date: "17-19 三月",
    location: "上海",
    event: "中国国际食品添加剂和配料展览会 - 食品配料和添加剂展览会",
    organizer: "中国轻工业理事会",
  },
  {
    date: "20-23 三月",
    location: "上海",
    event: "家电及电子世界博览会 - 国际家电博览会",
    organizer: "中国家用电器协会",
  },
  {
    date: "28-31 三月",
    location: "广州",
    event: "Interzum - 国际室内设计和家具博览会",
    organizer: "科隆展览（北京）有限公司",
  },
  {
    date: "30-02 四月",
    location: "上海",
    event: "HOTELEX国际酒店及餐饮博览会 - 酒店和餐饮博览会",
    organizer: "上海新博国际展览有限公司",
  },
  {
    date: "01-03 四月",
    location: "北京",
    event: "国际美容和化妆品博览会 - 国际化妆品博览会",
    organizer: "北京博览展览服务有限公司",
  },
  {
    date: "07-09 四月",
    location: "深圳",
    event: "国际玩具及业余展览会 - 国际玩具博览会",
    organizer: "慕尼黑展览（香港）有限公司",
  },
  {
    date: "08-11 四月",
    location: "上海",
    event: "中国国际医疗器械展览会 - 国际医疗器械博览会",
    organizer: "RX全球总部",
  },
  {
    date: "11-14 四月",
    location: "香港",
    event: "全球资源消费电子展 - 电子博览会",
    organizer: "全球资源展览",
  },
  {
    date: "13-16 四月",
    location: "香港",
    event: "香港电子展 - 电子博览会",
    organizer: "香港贸易发展局",
  },
  {
    date: "15-17 四月",
    location: "上海",
    event: "Electronica China - 电子博览会",
    organizer: "慕尼黑展览（上海）有限公司",
  },
  {
    date: "15-19 四月",
    location: "广州",
    event: "广交会春季第一阶段 - 电子和照明博览会",
    organizer: "中国外贸中心",
  },
  {
    date: "15-18 四月",
    location: "深圳",
    event: "Chinaplas - 国际塑料和橡胶博览会",
    organizer: "ADSALE",
  },
  {
    date: "16-18 四月",
    location: "苏州",
    event: "CLNB - 国际可再生能源产业展览会",
    organizer: "SMM信息与技术有限公司",
  },
  {
    date: "24-26 四月",
    location: "北京",
    event: "中国出境旅游及旅游市场 - 旅游",
    organizer: "塔尔苏斯中国",
  },
  {
    date: "23-27 四月",
    location: "广州",
    event: "广交会春季第二阶段 - 陶瓷和消费品博览会",
    organizer: "中国外贸中心",
  },
  {
    date: "23-02 四月-五月",
    location: "上海",
    event: "上海国际汽车展览会 - 国际汽车展览会",
    organizer: "慕尼黑展览（上海）有限公司",
  },
  {
    date: "1-5 五月",
    location: "广州",
    event: "广交会春季第三阶段 - 纺织品和医疗产品博览会",
    organizer: "中国外贸中心",
  },
  {
    date: "五月",
    location: "宁波",
    event: "中国中东欧博览会",
    organizer: "中国-中东欧博览会; 国际消费品博览会",
  },
  {
    date: "10-12 五月",
    location: "广州",
    event: "中国国际金属与冶金展览会 - 国际金属和冶金博览会",
    organizer: "广州巨浪展览设计有限公司",
  },
  {
    date: "10-12 五月",
    location: "广州",
    event: "HOTELEX - 酒店和餐饮博览会",
    organizer: "上海新博国际展览有限公司",
  },
  {
    date: "12-14 五月",
    location: "上海",
    event: "中国美容博览会 - 国际化妆品博览会",
    organizer: "Informa Markets - Sinoexpo",
  },
  {
    date: "15-17 五月",
    location: "广州",
    event: "GITF - 国际旅游博览会",
    organizer: "汉诺威米兰展览（上海）有限公司",
  },
  {
    date: "15-17 五月",
    location: "广州",
    event: "鞋类及皮革 - 国际鞋类和皮革制品博览会",
    organizer: "顶级声誉有限公司",
  },
  {
    date: "19-21 五月",
    location: "上海",
    event: "SIAL - 食品和饮料博览会",
    organizer: "Comexposium-Sial Exhibition Co. Ltd.",
  },
  {
    date: "19-22 五月",
    location: "上海",
    event: "Bakery China - 国际烘焙产品博览会",
    organizer: "Bakery China Exhibitions Co., Ltd.",
  },
  {
    date: "26-28 五月",
    location: "上海",
    event: "DOMOTEX ASIA/CHINAFLOOR - 地板行业博览会",
    organizer: "Globus Events Limited",
  },
  {
    date: "26-28 五月",
    location: "香港",
    event: "国际医疗和保健博览会 - 医疗博览会",
    organizer: "香港贸易发展局",
  },
  {
    date: "27-29 五月",
    location: "香港",
    event: "Vinexpo - 葡萄酒博览会",
    organizer: "Vinexposium",
  },
  {
    date: "27-30 五月",
    location: "上海",
    event: "厨房和浴室中国 - 国际浴室和厨房产品博览会",
    organizer: "Worldwide Exhibitions Service Co. Limited",
  },
  {
    date: "27-30 五月",
    location: "广州",
    event: "Prolight & Sound - 灯光和声音行业博览会",
    organizer: "慕尼黑展览（香港）有限公司",
  },
  {
    date: "09-12 六月",
    location: "广州",
    event: "国际照明展览会 - 照明产品博览会",
    organizer: "广州广雅慕尼黑展览有限公司",
  },
  {
    date: "09-12 六月",
    location: "北京",
    event: "中国国际牙科展览会和科学会议 - 国际牙科设备和技术博览会",
    organizer: "国际卫生交流与合作中心",
  },
  {
    date: "12-15 六月",
    location: "香港",
    event: "香港国际旅游博览会 - 旅游博览会",
    organizer: "TKS展览服务有限公司",
  },
  {
    date: "11-13 六月",
    location: "上海",
    event: "国际光伏发电和智能能源会议与展览 - 太阳能能源",
    organizer: "SNEC",
  },
  {
    date: "12-14 六月",
    location: "广州",
    event: "国际健康产业博览会 - 国际健康和营养博览会",
    organizer: "广州亿帆展览有限公司",
  },
  {
    date: "24-26 六月",
    location: "上海",
    event: "Healthplex Expo - 国际制药和天然医疗产品博览会",
    organizer: "上海信和展览有限公司",
  },
  {
    date: "09-11 七月",
    location: "上海",
    event: "铝中国博览会 - 国际铝制品博览会",
    organizer: "RX上海",
  },
  {
    date: "09-11 七月",
    location: "上海",
    event: "国际汽车制造技术和材料展览会 - 国际汽车博览会",
    organizer: "Reed Exhibitions Hengjin (Shanghai) Co., Ltd",
  },
  {
    date: "16-18 七月",
    location: "上海",
    event: "CBME孕婴童展览会及供应链 - 国际儿童产品博览会",
    organizer: "Informa Markets - 上海，中国",
  },
  {
    date: "08-10 八月",
    location: "广州",
    event: "太阳能光伏和储能世界博览会 - 太阳能能源",
    organizer: "广东大丰国际展览集团",
  },
  {
    date: "13-15 八月",
    location: "上海",
    event: "中国国际汽车内饰和外饰展览会 - 国际汽车博览会",
    organizer: "信息会议和展览（上海）有限公司",
  },
  {
    date: "14-18 八月",
    location: "香港",
    event: "食品博览会PRO - 食品博览会",
    organizer: "香港贸易发展局",
  },
  {
    date: "14-18 八月",
    location: "香港",
    event: "美容与健康博览会 - 化妆品和护肤品博览会",
    organizer: "香港贸易发展局",
  },
  {
    date: "23-25 八月",
    location: "香港",
    event: "香港国际牙科展览会和研讨会 - 国际牙科设备和技术博览会",
    organizer: "香港牙科协会",
  },
  {
    date: "25-27 八月",
    location: "上海",
    event: "世界海鲜 - 国际海鲜博览会",
    organizer: "上海爱格展览服务有限公司",
  },
  {
    date: "27-29 八月",
    location: "深圳",
    event: "国际物联网展览会 - 国际信息技术和电信博览会",
    organizer: "深圳友联传媒有限公司",
  },
  {
    date: "02-04 九月",
    location: "广州",
    event: "中国国际发型博览会 - 国际发型产品和配件博览会",
    organizer: "国际发型博览会",
  },
  {
    date: "03-05 九月",
    location: "香港",
    event: "亚洲水果博览会 - 国际水果和蔬菜博览会",
    organizer: "全球生产活动有限公司",
  },
  {
    date: "09-12 九月",
    location: "上海",
    event: "中国国际家具展览会 - 国际家具博览会",
    organizer: "中国外贸广州展览总公司",
  },
  {
    date: "11-13 九月",
    location: "广州",
    event: "中国国际渔业和海鲜博览会 - 国际海鲜博览会",
    organizer: "广州博亿国际展览有限公司",
  },
  {
    date: "24-26 九月",
    location: "深圳",
    event: "中国国际物流和供应链博览会 - 国际物流和运输博览会",
    organizer: "深圳物流和供应链管理协会",
  },
  {
    date: "23-27 九月",
    location: "上海",
    event: "中国国际工业博览会",
    organizer: "东浩蓝盛（集团）有限公司",
  },
  {
    date: "13-15 十月",
    location: "上海",
    event: "AgrochemEx - 国际农药博览会",
    organizer: "中国作物保护行业协会",
  },
  {
    date: "15-17 十月",
    location: "上海",
    event: "中国玩具博览会 - 国际玩具博览会",
    organizer: "中国玩具和少年用品协会",
  },
  {
    date: "15-19 十月",
    location: "广州",
    event: "广交会秋季第一阶段 - 电子和照明博览会",
    organizer: "中国外贸中心",
  },
  {
    date: "20 十月",
    location: "上海",
    event: "中国国际五金展览会",
    organizer: "科隆展览（北京）有限公司",
  },
  {
    date: "21-24 十月",
    location: "义乌",
    event: "义乌展 - 义乌商品博览会",
    organizer: "义乌国际商品博览会",
  },
  {
    date: "23-27 十月",
    location: "广州",
    event: "广交会秋季第二阶段 - 陶瓷和消费品博览会",
    organizer: "中国外贸中心",
  },
  {
    date: "31-04 十月-十一月",
    location: "广州",
    event: "广交会秋季第三阶段 - 纺织品和医疗产品博览会",
    organizer: "中国外贸中心",
  },
  {
    date: "20-22 十一月",
    location: "广州",
    event: "中国汽车技术博览会 - 国际汽车博览会",
    organizer: "武汉J&S沃森展览有限公司",
  },
  {
    date: "十一月",
    location: "上海",
    event: "Prowine - 葡萄酒博览会",
    organizer: "杜塞尔多夫展览有限公司",
  },
  {
    date: "十一月",
    location: "上海",
    event: "中国国际进口博览会 - 进出口博览会",
    organizer: "中国国际进口博览局国家会展中心（上海）",
  },
  {
    date: "十一月",
    location: "上海",
    event: "Bauma China - 建筑设备和技术博览会",
    organizer: "慕尼黑展览（上海）有限公司",
  },
  {
    date: "十一月",
    location: "深圳",
    event: "中国高交会 - 电子博览会",
    organizer: "深圳振威国际展览有限公司",
  },
  {
    date: "TBC",
    location: "北京",
    event: "国际汽车展览会 - 汽车博览会",
    organizer: "中国汽车改装配件协会",
  },
];

const EventsTable = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-white rounded-lg p-8">
      <h1 className="text-4xl font-extrabold text-red-500 mb-8">
        China Agenda 2025
      </h1>
      <p className="text-lg text-gray-600 mb-4">{t("agenda_paragraph")}</p>

      <div className="w-full">
        {/* Tab-uri */}
        <div className="flex w-full mb-8">
          {/* Tab 1 */}
          <button
            className={` text-white sm:text-lg rounded-lg py-1 font-medium w-full ${
              activeTab === 0 ? "bg-red-600" : "bg-red-300"
            }`}
            onClick={() => setActiveTab(0)}
          >
            {t("targuri_expozitii")}
          </button>

          {/* Tab 2 */}
          <button
            className={` text-white sm:text-lg rounded-lg py-1 font-medium w-full ${
              activeTab === 1 ? "bg-red-600" : "bg-red-300"
            }`}
            onClick={() => setActiveTab(1)}
          >
            {t("conferinte")}
          </button>
        </div>

        {/* Conținut Tab */}
        <div>
          {activeTab === 0 && (
            <>
              {" "}
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                {t("targuri_expozitii")}
              </h2>
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr className="bg-red-500 text-[16px] text-white">
                      <th>{t("data_locatie")}</th>
                      <th className="text-start">{t("eveniment")}</th>
                      <th className="text-center">{t("organizator")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {i18n.language === "ro" && (
                      <>
                        {events.map((event, index) => (
                          <tr
                            key={index}
                            className="hover:bg-red-100 transition"
                          >
                            <td className="p-2 flex items-center gap-2">
                              <FaCalendarAlt className="text-red-500" />
                              {event.date}
                            </td>
                            <td className="p-2 flex items-center gap-2">
                              <FaMapMarkerAlt className="text-green-500" />
                              {event.location}
                            </td>
                            <td className="p-2">{event.event}</td>
                            <td className="p-2 text-center gap-2">
                              <FaBuilding className="text-blue-500 mx-auto" />
                              {event.organizer}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                    {i18n.language === "en" && (
                      <>
                        {eventsInEnglish.map((event, index) => (
                          <tr
                            key={index}
                            className="hover:bg-red-100 transition"
                          >
                            <td className="p-2 flex items-center gap-2">
                              <FaCalendarAlt className="text-red-500" />
                              {event.date}
                            </td>
                            <td className="p-2 flex items-center gap-2">
                              <FaMapMarkerAlt className="text-green-500" />
                              {event.location}
                            </td>
                            <td className="p-2">{event.event}</td>
                            <td className="p-2 text-center gap-2">
                              <FaBuilding className="text-blue-500 mx-auto" />
                              {event.organizer}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                    {i18n.language === "ch" && (
                      <>
                        {eventsInChinese.map((event, index) => (
                          <tr
                            key={index}
                            className="hover:bg-red-100 transition"
                          >
                            <td className="p-2 flex items-center gap-2">
                              <FaCalendarAlt className="text-red-500" />
                              {event.date}
                            </td>
                            <td className="p-2 flex items-center gap-2">
                              <FaMapMarkerAlt className="text-green-500" />
                              {event.location}
                            </td>
                            <td className="p-2">{event.event}</td>
                            <td className="p-2 text-center gap-2">
                              <FaBuilding className="text-blue-500 mx-auto" />
                              {event.organizer}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {activeTab === 1 && (
            <>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                {t("conferinte")}
              </h2>
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr className="bg-red-500 text-[16px] text-white">
                      <th>{t("data_locatie")}</th>
                      <th>{t("eveniment")}</th>
                      <th className="text-center">{t("organizator")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {i18n.language === "ro" && (
                      <>
                        {conference.map((event, index) => (
                          <tr
                            key={index}
                            className="hover:bg-red-100 transition"
                          >
                            <td className="p-2 flex items-center gap-2">
                              <FaCalendarAlt className="text-red-500" />
                              {event.date}
                            </td>
                            <td className="p-2 flex items-center gap-2">
                              <FaMapMarkerAlt className="text-green-500" />
                              {event.location}
                            </td>
                            <td className="p-2">{event.event}</td>
                            <td className="p-2 text-center gap-2">
                              <FaBuilding className="text-blue-500 mx-auto" />
                              {event.organizer}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                    {i18n.language === "en" && (
                      <>
                        {conferenceInEnglish.map((event, index) => (
                          <tr
                            key={index}
                            className="hover:bg-red-100 transition"
                          >
                            <td className="p-2 flex items-center gap-2">
                              <FaCalendarAlt className="text-red-500" />
                              {event.date}
                            </td>
                            <td className="p-2 flex items-center gap-2">
                              <FaMapMarkerAlt className="text-green-500" />
                              {event.location}
                            </td>
                            <td className="p-2">{event.event}</td>
                            <td className="p-2 text-center gap-2">
                              <FaBuilding className="text-blue-500 mx-auto" />
                              {event.organizer}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                    {i18n.language === "ch" && (
                      <>
                        {conferenceInChinese.map((event, index) => (
                          <tr
                            key={index}
                            className="hover:bg-red-100 transition"
                          >
                            <td className="p-2 flex items-center gap-2">
                              <FaCalendarAlt className="text-red-500" />
                              {event.date}
                            </td>
                            <td className="p-2 flex items-center gap-2">
                              <FaMapMarkerAlt className="text-green-500" />
                              {event.location}
                            </td>
                            <td className="p-2">{event.event}</td>
                            <td className="p-2 text-center gap-2">
                              <FaBuilding className="text-blue-500 mx-auto" />
                              {event.organizer}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventsTable;
