import React from "react";
import AllBlogPosts from "../components/AllBlogPosts";
import NewProfileCard from "../components/NewProfileCard";

export default function Home() {
  return (
    <div>
      <NewProfileCard />
      <AllBlogPosts />
    </div>
  );
}
