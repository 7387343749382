import React from "react";
import { Link } from "react-router-dom";

export default function ButonPrincipalProba() {
  return (
    <>
      <button className="relative flex h-12 w-full justify-center items-center card-div bg-gradient-to-r from-red-500 via-red-500 to-red-600 rounded-md">
        <img
          src="/handshake.png"
          alt="Plane"
          className="absolute left-0 object-cover w-12 h-12 drop-shadow-lg"
        />
        <h1 className="md:text-lg z-10 text-white font-bold whitespace-nowrap drop-shadow-lg card-text transition-all duration-200">
          Business Center
        </h1>
        <img
          src="/business.png"
          alt="business"
          className="absolute right-0 object-cover w-12 h-12 drop-shadow-lg"
        />
      </button>

      <button className="relative flex h-12 w-full justify-center items-center card-div bg-gradient-to-r from-red-500 via-red-500 to-red-600 rounded-md">
        <img
          src="/plane2.png"
          alt="Plane"
          className="absolute left-1 object-cover w-12 h-12 drop-shadow-lg"
        />
        <h1 className="md:text-lg z-10 text-white font-bold whitespace-nowrap drop-shadow-lg card-text transition-all duration-200">
          Travel Center
        </h1>
        <img
          src="/luggage.png"
          alt="luggage"
          className="absolute right-0 object-cover w-12 h-12 drop-shadow-lg"
        />
      </button>
      <Link
        to="/china-agenda"
        className="relative flex h-12 w-full justify-center items-center card-div bg-gradient-to-r from-red-700 via-red-700 to-red-800 rounded-md"
      >
        <img
          src="/calendar.png"
          alt="Calendar"
          className="absolute left-0 object-cover w-12 h-12 drop-shadow-lg"
        />
        <h1 className="md:text-lg z-10 text-white font-bold whitespace-nowrap drop-shadow-lg card-text transition-all duration-200">
          China Agenda
        </h1>
        <img
          src="/expo.png"
          alt="EXPO"
          className="absolute right-1 object-cover w-12 h-12"
        />
      </Link>
      <button className="relative flex h-12 w-full justify-center items-center card-div bg-gradient-to-r from-red-700 via-red-700 to-red-800 rounded-md">
        <img
          src="/lens.png"
          alt="Calendar"
          className="absolute left-0 object-cover w-12 h-12 drop-shadow-lg"
        />
        <h1 className="md:text-lg z-10 text-white font-bold whitespace-nowrap drop-shadow-lg card-text transition-all duration-200">
          Media Scanner
        </h1>
        <img
          src="/jurnal.png"
          alt="EXPO"
          className="absolute right-1 object-cover w-12 h-12"
        />
      </button>
    </>
  );
}
