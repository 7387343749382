import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Obiectele de traducere
const resources = {
  ro: {
    translation: {
      acasa: "Acasă",
      prezentare: "Prezentare",
      echipa: "Echipă",
      agenda: "Agendă",
      parteneriate: "Parteneriate",
      facilitati: "Facilități",
      servicii: "Servicii",
      filiale: "Filiale",
      filiale_ro: "Filiale RO",
      reprezentante_ch: "Reprezentanțe CH",
      membri: "Membri",
      membri_ccirc: "Membri CCIRC",
      membri_onoare: "Membri de onoare",
      aderare: "Aderare",
      statut_ccirc: "Statut CCIRC",
      procedura: "Procedură",
      adeziune: "Adeziune",
      fisa_companiei: "Fișa companiei",
      contact: "Contact",
      sustine_ccirc: "Susține CCIRC",
      relatii_bilaterale: "RELAȚII BILATERALE",
      romania_china: "România - China",
      studii_documentare: "STUDII DOCUMENTARE",
      ministerul_afacerilor_externe: "Ministerul Afacerilor Externe",
      sfaturi_calatorie_china: "Sfaturi de călătorie - China",
      ambasada_romaniei: "Ambasada României în Republica Populară Chineză",
      ambasada_chinei: "Ambasada Republicii Populare Chineze în România",
      institutul_cultural_roman: "Institutul Cultural Român",
      icr_beijing: "ICR Beijing",
      centrul_cultural_chinez: "Centrul Cultural Chinez",
      ccc_bucuresti: "CCC București",
      institutul_confucius: "Institutul Confucius",
      universitati_romania: "Universități România",
      radio_china_international: "Radio China Internațional",
      sectia_romana: "Secția Română",
      centrul_de_vize: "Centrul de vize pentru China",
      invest_romania: "Invest România",
      investeste_in_romania: "Investește în România",
      parteneri: "Parteneri",
      meniu: "Meniu",
      cauta_postari: "Caută postări...",
      anterior: "Anterior",
      urmator: "Următor",
      pagina: "Pagina",
      din: "din",
      ing_jur_nicolae_vasilescu: "Ing. Jur. Nicolae Vasilescu",
      presedinte_ccirc: "Președinte CCIRC",
      descriere_nicolae_vasilescu:
        "Nicolae-Vasilescu este un politician român, membru al Parlamentului României. Începând cu 19 decembrie 2012, este președinte al Comisiei pentru Regulament a Camerei Deputaților.",
      citeste_mai_mult: "Citește mai mult",
      amb_viorel_isticioaia_budura: "Amb. Viorel Isticioaia-Budura",
      presedinte_de_onoare_ccirc: "Președinte de onoare CCIRC",
      descriere_viorel_isticioaia:
        "Viorel Isticioaia Budura este directorul general al Departamentului Asia Pacific din cadrul Serviciului European de Acțiuni Externe (SEAE) al Uniunii Europene, Serviciu diplomatic european.",
      sediul_central: "Sediul Central",
      telefon: "Telefon",
      email: "E-mail",
      unde_ne_gasesti: "Unde ne găsești",
      trimite_ne_un_mesaj: "Trimite-ne un mesaj",
      conectare_membri: "LOG-IN MEMBRI",
      conectare: "Conectare",
      numele_utilizator: "Numele utilizatorului",
      parola: "Parola",
      introduceti_numele_utilizator: "Introduceți numele utilizatorului",
      introduceti_parola: "Introduceți parola",
      loading: "Se încarcă...",
      bine_ai_venit: "Bine ați venit",
      deconectare: "Deconectare",
      catalog_servicii_de_afaceri:
        "Catalog Servicii de Afaceri pentru Companii",
      consultanta_in_afaceri: "Consultanță în Domeniile Afacerilor Bilaterale",
      consultanta_comerciala:
        "Consultanță Comercială, Juridică, Financiară și de Risc",
      targuri_expozitii: "Târguri și expoziții",
      conferinte: "Conferințe",
      data_locatie: "Data / Locație",
      eveniment: "Eveniment",
      organizator: "Organizator",
      agenda_paragraph:
        "Explorați evenimentele economice și comerciale din China pentru 2025. Descoperiți oportunitățile oferite de expozițiile și conferințele din cele mai importante industrii.",
      despre_noi: "Despre noi",
      politica_de_confidentialitate: "Politica de confidențialitate",
      politica_de_cookies: "Politica de cookies",
      cerere_de_aderare: "Cerere de aderare",
      contract_de_adeziune: "Contract de adeziune membru",
      fisa_companiei_domenii: "Fișa companiei - domenii de interes",
      fisa_companii: "Fișă companii - info contact",
      mandat_de_reprezentare: "Mandat de reprezentare",
      catalog_servicii_afaceri: "Catalog servicii de afaceri pentru companii",
      catalog_servicii_logistica:
        "Catalog servicii de logistica pentru import-export",
      camera_de_comert_si_industrie_romania_china:
        "Camera de Comerț și Industrie România-China",
      prezentare_p1:
        "Camera de Comerț și Industrie România-China reprezintă un model de succes în promovarea și dezvoltarea relațiilor economice dintre România și R.P. Chineză, având o experiență de peste 20 de ani și peste 600 de companii membre care contribuie activ la proiectele sale.",
      prezentare_p2:
        "Organizația noastră colaborează strâns cu instituțiile guvernamentale românești și susține dialogul de afaceri B2B între companiile din România și cele din China, promovând exporturile românești și echilibrând balanța comercială.",
      identitate: "Identitate",
      prezentare_p3:
        "CCIRC este o organizație autonomă, neguvernamentală, apolitică și nonprofit, recunoscută pentru contribuția sa semnificativă la relațiile bilaterale dintre România și R.P. Chineză.",
      prezentare_p4:
        "CCIRC se angajează să fie partenerul principal de dialog în domeniul economic și comercial bilateral, oferind soluții și expertiză pentru proiectele membrilor săi și comunitatea de afaceri.",
      obiective_institutionale: "Obiective Instituționale",
      obiectiv_1:
        "Extinderea rețelei teritoriale prin deschiderea de filiale și reprezentanțe în România și China.",
      obiectiv_2:
        "Atragerea de noi membri, inclusiv companii chineze interesate de proiecte de investiții în România.",
      obiectiv_3:
        "Optimizarea structurii interne de management și a bazelor de date pentru eficiență.",
      obiectiv_4:
        "Creșterea vizibilității publice a CCIRC și promovarea imaginii membrilor.",
      prezentare_p5:
        "CCIRC își desfășoară activitățile specifice în CCIR BUSINESS CENTER, etajul 6, dispunând de mai multe spații amenajate ca birouri de lucru sau de coordonare pentru departamentele interne și management, precum și o sală de conferințe dotată cu cea mai modernă aparatură multimedia. Managamentul central al activităților CCIRC este asigurat de un Secretariat General din care fac parte secretari executivi și angajați sau colaboratori permanenți ai camerei.",
      suprafata: "Suprafață",
      mp_250: "250 mp",
      suprafata_p1: "de birouri și săli de conferință",
      prezentare_p6: " Angajați și colaboratori permanenți",
      servicii_si_activitati: "Servicii și Activități",
      colaborare_instiutionala: "Colaborare Instituțională",
      prezentare_p7:
        "CCIRC colaborează cu instituții și asociații pentru promovarea proiectelor bilaterale și pentru susținerea evenimentelor economice.",
      dialog_diplomatic: "Dialog Diplomatic",
      prezentare_p8:
        "Menținerea unui dialog constant cu misiunile diplomatice și consulatele pentru dezvoltarea colaborărilor economice.",
      organizare_evenimente: "Organizare Evenimente",
      prezentare_p9:
        "Organizarea forumurilor de afaceri, expozițiilor și misiunilor economice în România și R.P. Chineză.",
      consultanta: "Consultanță",
      prezentare_p10:
        "Oferim consultanță în tranzacții economice internaționale, aspecte juridice, financiare și riscuri pentru companiile membre.",
      obiective_de_dezvoltare_interna: "Obiective de Dezvoltare Internă",
      prezentare_p11: "Extinderea rețelei teritoriale",
      prezentare_p12:
        "Înființarea de sucursale/filiale în județele țării și în București, precum și deschiderea de reprezentanțe în principalele municipii din R.P. Chineză pentru a extinde activitatea și vizibilitatea camerei.",
      prezentare_p13: "Creșterea numărului de membri",
      prezentare_p14:
        "Atragerea companiilor de top care desfășoară activități economice bilaterale și a companiilor chineze interesate de investiții în România, pentru a consolida comunitatea de afaceri a CCIRC.",
      prezentare_p15: "Dezvoltarea structurilor interne de management",
      prezentare_p16:
        "Perfecționarea activității departamentelor și direcțiilor CCIRC, gestionarea eficientă a bazelor de date și arhivarea documentelor pentru o administrare internă optimizată.",
      prezentare_p17: "Promovarea publică",
      prezentare_p18:
        "Dezvoltarea unui sistem de relații publice și protocol activ pentru a promova imaginea CCIRC și a membrilor săi, asigurând o prezență publică activă și eficientă.",
      prezentare_p19: "Aderarea la organizații internaționale",
      prezentare_p20:
        "Implicarea în organizații și asociații cu obiective comune de promovare a relațiilor bilaterale cu R.P. Chineză și susținerea dezvoltării relațiilor economice internaționale.",
      prezentare_p21: "Încheierea de protocoale și acorduri",
      prezentare_p22:
        "Colaborarea cu autoritățile și instituțiile publice din România și R.P. Chineză prin protocoale, acorduri și memorandumuri pentru a susține obiectivele și misiunea camerei bilaterale.",
      promovare_si_reprezentare: "Promovare și Reprezentare",
      prezentare_p23:
        "promovează interesele economice ale membrilor săi prin inițiative de susținere a exporturilor, promovarea investițiilor străine și colaborarea bilaterală cu parteneri din R.P. Chineză.",
      prezentare_p24:
        "Promovarea brandurilor și produselor românești în R.P. Chineză",
      prezentare_p25:
        "Încurajarea exporturilor pentru echilibrarea balanței comerciale",
      prezentare_p26:
        "Promovarea colaborărilor în educație și cercetare științifică",
      prezentare_p27:
        "Dezvoltarea parteneriatelor pentru atragerea investițiilor străine",
      organizare_de_evenimente: "Organizare de Evenimente",
      participare_la_forumuri: "Participare la forumuri",
      prezentare_p28:
        "Organizăm participarea membrilor CCIRC la forumuri de afaceri, târguri și expoziții.",
      misiuni_economice: "Misiuni economice",
      prezentare_p29:
        "Inițiem și participăm la misiuni economice în R.P. Chineză și primim delegații chineze în România.",
      eveniment_de_suport: "Eveniment de suport",
      prezentare_p30:
        "Organizăm evenimente de consultanță și servicii pentru dezvoltarea proiectelor bilaterale.",
      acordare_de_burse: "Acordare de burse",
      prezentare_p31:
        "Facilităm acordarea de burse de studiu pentru studenții din România și R.P. Chineză.",
      colaborare_cu_mediul_diplomatic: "Colaborare cu Mediul Diplomatic",
      prezentare_p32:
        "CCIRC menține un contact permanent cu instituțiile diplomatice din România și R.P. Chineză, asigurând un parteneriat solid pentru susținerea relațiilor economice și comerciale bilaterale.",
      informarea_reciproca: "Informarea reciprocă",
      prezentare_p33:
        "Asigurarea unui schimb constant de informații despre delegațiile instituțiilor și companiilor care efectuează vizite în România și R.P. Chineză.",
      schimb_de_informatii: "Schimb de informații",
      prezentare_p34:
        "Menținerea unui flux de informații utile din domeniile de interes bilateral pentru cooperarea economică și comercială.",
      promovarea_initiativelor: "Promovarea inițiativelor",
      prezentare_p35:
        "Facilitarea identificării parteneriatelor de afaceri prin informarea reciprocă asupra proiectelor companiilor românești și chineze.",
      sprijin_in_relatii: "Sprijin în relațiile B2B",
      prezentare_p36:
        "CCIRC este recomandată ca partener principal pentru colaborările B2B între companiile din România și cele din R.P. Chineză.",
      evenimente_dedicate: "Evenimente dedicate",
      prezentare_p37:
        "Organizarea de evenimente în colaborare cu instituțiile diplomatice pentru promovarea cooperării bilaterale.",
      informarea_comunitatii: "Informarea comunității chineze",
      prezentare_p38:
        "Activități informative despre legislația românească, în colaborare cu Ambasada R.P. Chineze la București.",
      cercetare_informare_expertiza: "Cercetare, Informare și Expertiză",
      prezentare_p39:
        "sprijină companiile membre și autoritățile prin activități de cercetare și informare, oferind expertiză în domeniile esențiale pentru cooperarea economică și comercială bilaterală.",
      elaborarea_de_evaluari: "Elaborarea de evaluări și rapoarte",
      prezentare_p40:
        "Analize detaliate despre stadiul cooperării bilaterale economice și comerciale dintre România și R.P. Chineză.",
      informarea_entitatilor: "Informarea entităților economice chineze",
      prezentare_p41:
        "Oferirea de informații despre normele, standardele și practicile comerciale ale Uniunii Europene, esențiale pentru companiile din China.",
      sustinerea_proiectelor: "Susținerea proiectelor de cercetare-dezvoltare",
      prezentare_p42:
        "Inițierea de proiecte proprii sau parteneriate cu instituții specializate pentru cercetare în domenii de interes bilateral.",
      publicatii_informative: "Publicații informative",
      prezentare_p43:
        "Editarea de buletine, cataloage și alte materiale informative, tipărite sau online, cu caracter periodic sau ocazional.",
      crearea_de_programe:
        "Crearea de programe informatice și platforme online",
      prezentare_p44:
        "Dezvoltarea de site-uri și pagini pe platforme sociale pentru promovarea activităților CCIRC și ale companiilor membre.",
      constituirea_de_baze: "Constituirea de baze de date și documente",
      prezentare_p45:
        "Colectarea și facilitarea accesului la informații specifice activității bilaterale, disponibile pentru companiile membre și autorități.",
      structurile_nationale: "Structurile Naționale de Conducere",
      prezentare_p46:
        "Camera de Comerț și Industrie România-China este condusă de structuri naționale organizate pentru a asigura implementarea și coordonarea strategică a inițiativelor și proiectelor bilaterale. Acestea includ conducerea colectivă și coordonarea operativă a activităților curente.",
      conferinta_nationala: "Conferința Națională",
      prezentare_p47:
        "Este forul național de conducere al CCIRC, responsabil pentru adoptarea documentelor strategice și rezoluțiilor privind relațiile economice și comerciale bilaterale.",
      colegiul_national: "Colegiul Național de Conducere",
      prezentare_p48:
        "Organul de coordonare colectivă care discută periodic agenda CCIRC și ia decizii pentru implementarea obiectivelor camerei bilaterale.",
      biroul_permanent: "Biroul Permanent Național",
      prezentare_p49:
        "Structura executivă care coordonează activitățile curente și asigură reprezentarea publică a CCIRC în România și la nivel internațional.",
      certificari: "Certificări",
      prezentare_p50:
        "CCIRC a obținut certificarea sistemelor sale de management, atât din punct de vedere al calității",
      prezentare_p51: "cât și al securității informației",
      prezentare_p52:
        "este operator de date cu caracter personal, în condițiile dispozițiilor Legii nr. 677/2001 cu privire la prelucrarea datelor cu caracter personal",
      prezentare_p53:
        "este înregistrată în Registrul unic al transparenței și intereselor",
      afilieri: "Afilieri",
      afilieri_nationale: "Afilieri naționale",
      prezentare_p54:
        "este membră a Camerei de Comerț și Industrie a României din anul 2003.",
      prezentare_p55:
        "este membru fondator al Federației Naționale a Camerelor Bilaterale de Comerț.",
      prezentare_p56: "Afilieri internaționale",
      parteneriate_in_rp: "Parteneriate în R.P Chineză",
      parteneriate_regionale_locale: "Parteneriate regionale și locale",
      descarca_fisierul: "Descarcă fișierul",
      descarca_fisa: "Descarcă fișa",
      descarca_contract: "Descarcă contractul",
      descarca_cererea: "Descarcă cererea",
      facilitati_1: "Prezentare facilități pentru membrii CCIRC",
      facilitati_2:
        "Listă beneficii și facilități pentru companii cu acționariat românesc",
      facilitati_3:
        "Listă beneficii și facilități pentru companii cu acționariat chinezesc",
      facilitati_4:
        "Listă beneficii și facilități pentru companii chineze sau filiale ale acestora din România",
      descarca_brosura: "Descarcă broșura",
      catalog_servicii_de_logistica:
        "Catalog servicii de logistică pentru activități comerciale de import-export",
      studii_d1:
        "A. RELAȚIILE BILATERALE INSTITUȚIONALE ROMÂNIA - R.P. CHINEZĂ",
      studii_d2: "B. RELAȚIILE BILATERALE ECONOMICE ROMÂNIA - R.P. CHINEZĂ",
      studii_d3: "C. RELAȚIILE BILATERALE R.P. CHINEZĂ - EUROPA",
      studii_d4: "D. RELAȚIILE INTERNAȚIONALE ALE R.P. CHINEZĂ",
      studii_d5: "E. ROMÂNIA - INFORMAȚII GENERALE",
      titlul_documentului: "Titlul Documentului",
      tip_document: "Tip Document",
      nivel_acces: "Nivel Acces",
      valabilitate: "Valabilitate",
      nr_document: "Nr. Doc.",
      capitolul_1: "Capitolul I",
      capitolul_2: "Capitolul II",
      logistica_1:
        "Inspecția și controlul calității produselor importate / exportate",
      logistica_2: "Transportul și logistica produselor importate și exportate",
      nu_exista_postari: "Nu există postări",
      pagina_in_lucru: "Pagina este în lucru!",
      pagina_in_lucru_p1:
        "Ne străduim să o finalizăm cât mai curând. Vă mulțumim pentru înțelegere!",
      inapoi_la_pagina: "Înapoi la pagina principală",
      membri_ccirc_p1:
        "Peste 500 de entități economice și comerciale românești și chineze sunt membri ai CCIRC. Dintre acestea, 22 de companii au primit calitatea de Parteneri Strategici ai camerei.",
      membri_ccirc_p2:
        "Membrii CCIRC sunt entități economice și comerciale din România și R.P. Chineză:",
      membri_ccirc_l1: "Societăţi comerciale",
      membri_ccirc_l2: "Companii multinaționale",
      membri_ccirc_l3: "Societăţi naționale și regii autonome",
      membri_ccirc_l4: "Grupuri de interes economic",
      membri_ccirc_l5: "Organizații cooperatiste",
      membri_ccirc_l6: "Persoane fizice autorizate",
      membri_ccirc_l7:
        "Alte categorii de profesioniști care efectuează activități economice",
      membri_ccirc_l8: "Asociații economice, comerciale și profesionale",
      membri_ccirc_l9: "Organizații patronale",
      membri_ccirc_l10:
        "Companiile înregistrate în R.P. Chineză, sucursalele sau reprezentanțele acestora în România",
      facilitati_membri: "Facilități pentru Membrii CCIRC",
      reprezentare_si_promovare: "Reprezentare și Promovare",
      facilitati_p1:
        "susține și reprezintă interesele generale ale companiilor în relația cu instituțiile administrației publice centrale și locale, precum și în fața organismelor și organizațiilor naționale din care face parte camera bilaterală.",
      facilitati_p2:
        "cooptează reprezentanții companiilor în delegațiile camerei bilaterale care participă la întâlniri oficiale în România sau în cadrul misiunilor economice ale autorităților române în R.P. Chineză.",
      facilitati_p3:
        "facilitează companiilor contactele și dialogurile cu reprezentanții Ambasadei R.P. Chineze la București și legăturile cu misiunile diplomatice ale României din R.P. Chineză.",
      facilitati_p4:
        "apară interesele strategice ale companiilor în cadrul mediului de afaceri și în fața autorităților, inclusiv prin susținerea campaniilor de advocacy ale acestora.",
      facilitati_p5:
        "facilitează întâlnirile, dialogurile și vizitele de prezentare ale companiilor cu reprezentanții autorităților, instituțiilor sau structurile asociative economice, camerale, patronale și sindicale, la nivel central și local în România și R.P. Chineză.",
      facilitati_p6:
        "promovează serviciile sau produsele companiilor la nivelul comunității de afaceri chineze din România și în cadrul întâlnirilor cu delegațiile companiilor chineze care prospectează prezența lor în țara noastră cu scopul derulării unor proiecte de investiții pe termen lung.",
      facilitati_p7:
        "promovează serviciile sau produsele companiilor, inclusiv ofertele speciale ale acestora către membrii camerei bilaterale, direct sau prin intermediului sistemului M2M.",
      facilitati_p8:
        "promovează proiectele de investiții și inițiativele de tip parteneriat public - privat ale companiilor în fața unităților administrativ teritoriale din România.",
      actiuni_si_evenimente: "Acțiuni și Evenimente",
      facilitati_p9:
        "facilitează participarea reprezentanților companiilor la evenimentele camerei bilaterale, proprii sau organizate în parteneriat, precum și la evenimentele sau activitățile la care aceasta este invitată să participe, în România sau în R.P. Chineză, cu scopul dezvoltării relațiilor publice și comerciale ale companiilor.",
      facilitati_p10:
        "facilitează obținerea de invitații și accesul la evenimente publice sau private din România și R.P. Chineză, care prezintă interes pentru dezvoltarea relațiilor economice bilaterale ale companiilor",
      facilitati_p11:
        "forumuri, conferințe, congrese, seminarii, work-shopuri s.a.",
      facilitati_p12:
        "acordă companiilor suport pentru organizarea evenimentelor proprii, în România sau în R.P. Chineză, prin asigurarea participarii în cadrul acestora a reprezentanților autorităților, instituțiilor sau structurilor asociative economice, camerale, patronale și sindicale, la nivel central și local din țară și din R.P. Chineză, precum și ai comunității de afaceri chineze din Romania.",
      facilitati_p13:
        "organizează sau facilitează înscrierea și participarea reprezentanților sau personalului companiilor la seminarii de prezentare a mediului de afaceri și legislației economice și comerciale din România sau R.P. Chineză și la cursuri de perfecționare a negocierii și comunicării specifice relațiilor bilaterale, precum și la cursuri de învățare a limbilor română și chineză.",
      facilitati_p14:
        "facilitează accesul și participarea companiilor la târguri, expoziții și alte asemenea evenimente economice și comerciale care au loc în România sau R.P. Chineză.",
      facilitati_p15:
        "informează despre oportunitățile de participare a reprezentanților companiilor la diverse forme de training sau la accesarea de burse de studii în România sau R.P. Chineză.",
      imagine_si_publicitate: "Imagine și Publicitate",
      facilitati_p16:
        "Asigură accesul companiilor la evenimentele organizate de camera bilaterală pentru realizarea unor acțiuni sau campanii publicitare de promovare a proiectelor, imaginii și a brand-urilor acestora.",
      facilitati_p17:
        "Promovează produsele și serviciile companiilor în cadrul standurilor organizate de camera bilaterală la târgurile și expozițiile la care este prezentă, în România sau în R.P. Chineză.",
      facilitati_p18:
        "Promovează logo-urile companiilor pe documentele și materialele de publicitate ale evenimentelor camerei bilaterale, în condițiile convenite de comun acord.",
      facilitati_p19:
        "Promovează imaginea companiilor și distribuie știrile care privesc activitatea acestora în publicațiile și cataloagele tipărite, precum și pe web-siteul sau pe paginile de socializare ale camerei bilaterale.",
      facilitati_p20:
        "Acordă dreptul de a utiliza logo-ul camerei bilaterale pe documentele și materialele de imagine și promovare ale companiilor.",
      facilitati_p21:
        "Acordă distincții și titluri onorifice companiilor sau reprezentanților acestora, pentru activitatea, proiectele sau parteneriatele economice și comerciale cu caracter bilateral.",
      suport_pentru_afaceri: "Suport pentru Afaceri",
      facilitati_p22:
        "Identifică și verifică potențialii parteneri de afaceri ai companiilor din România sau R.P. Chineză",
      facilitati_p23: "analize juridice, financiare și de bonitate",
      facilitati_p24:
        "prezintă companiilor proiecte sau oportunități de afaceri comunicate camerei bilaterale de către companiile românești sau chineze sau de partenerii săi din R.P. Chineză, care pot fi considerate ca fiind de interes pentru acestea.",
      facilitati_p25:
        "organizează întâlniri de tip B2B pentru reprezentanții companiilor cu societăți sau entități economice și comerciale din România sau R.P. Chineză.",
      facilitati_p26:
        "acordă asistență de specialitate la negocierile reprezentanților companiilor cu entități economice și comerciale din România sau R.P. Chineză.",
      facilitati_p27:
        "facilitează contactele companiilor cu parteneri din România sau R.P. Chineză pentru derularea în condiții de siguranță și calitate a operațiunilor specifice activităților comerciale de import - export.",
      facilitati_p28: "listează pe platforma de business a camerei bilaterale.",
      facilitati_p29:
        "asigură accesul companiilor la baza de date, studiile documentare, documentele de analiză, evaluări și statistici elaborate de camera bilaterală, referitoare la evoluția economică și socială din România sau R.P. Chineză.",
      facilitati_p30:
        "informează companiile cu privire la mediul de afaceri, politicile de concurență și practicile de proprietate intelectuală, cadrul legislativ cu incidență asupra mediului de afaceri și investițional din România sau R.P. Chineză.",
      consultanta_si_servicii: "Consultanță și Servicii",
      facilitati_p31:
        "acordă companiilor, în condiții contractuale, consultanță de afaceri, juridică și financiară, precum și alte servicii specifice dezvoltării proiectelor bilaterale.",
      facilitati_p32:
        "furnizează companiilor informații privind identificarea și atragerea surselor de finanțare pentru proiectele de afaceri, formalitățile, normele și procedurile privind înființarea, organizarea și funcționarea societăților comerciale ale acestora din România sau R.P. Chineză.",
      facilitati_p33:
        "acordă companiilor asistență pentru întocmirea documentelor specifice obținerii vizelor de tranzit, de scurtă sau lungă ședere în România sau R.P. Chineză, pentru personalul sau reprezentanții acestora.",
      facilitati_p34:
        "asigură accesul companiilor la serviciile de logistică contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de marfă",
      facilitati_p35: "terestru, naval și aerian",
      facilitati_p36:
        ", inspecție și control al produselor importate sau exportate, comisionari vamali s.a.",
      facilitati_p37:
        "asigură accesul companiilor la serviciile de asistență turistică și călătorii de afaceri contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de persoane, rezervări servicii hoteliere, rent-a-car, ghizi insoțitori s.a.",
      facilitati_p38:
        "asigură accesul companiilor în spațiile și sălile de ședință și protocol, precum și la dotările tehnice din sediul central al camerei bilaterale pentru organizarea întâlnirilor și evenimentelor proprii.",
      facilitati_p39:
        "asigură companiilor expertiză pentru analizarea, evaluarea și soluționarea disputelor și conflictelor comerciale, înainte ca acestea să ajungă în instanță și oferă metode alternative de soluționare a acestora prin mediere, conciliere și arbitraj.",
      facilitati_p40:
        "asistă companiile în fața Curții de Arbitraj Comercial Internațional de pe lângă Camera de Comerț și Industrie a României pentru soluționarea litigiilor comerciale.",
      facilitati_p41:
        "Cele mai multe servicii sunt gratuite sau au un tarif redus pentru membrii CCIRC.",
      consultanta_import: "Consultanță Import/Export",
      lista_beneficii_1:
        "Listă beneficii și facilități pentru companii cu acționariat românesc",
      lista_beneficii_2:
        "Listă beneficii și facilități pentru companii cu acționariat chinezesc",
      lista_beneficii_3:
        "Listă beneficii și facilități pentru companii chineze sau filiale ale acestora din România",
      parteneri_strategici: "Parteneri Strategici",
      parteneri_servicii: "Parteneri Servicii",
      contacteaza_ne: "Contactează-ne",
      ai_intrebari:
        "Ai întrebări? Ne poți găsi folosind informațiile de mai jos.",
      hotarare_inregistrare: "Hotărâre de înregistrare",
      certificat_de_inregistrare: "Certificat de înregistrare fiscală",
      registrul_national: "Registrul Național",
      nr_regist:
        "Nr. Registrul Național al persoanelor juridice fără scop patrimonial",
      banca: "Banca",
      adresa: "Adresa",
      sc_p1:
        "Modalități prin care poate fi susținută financiar activitatea camerei de comerț și industrie România - China",
      sc_p2:
        "este cameră de comerț bilaterală și funcționează în conformitate cu prevederile Legii camerelor de comerț din România nr. 335/2007 și a O.G. nr. 26/2000 cu privire la asociații și fundații, cu modificările și completările ulterioare.",
      sc_p3:
        "este organizație autonomă, neguvernamentală, apolitică, cu caracter nonprofit, cu personalitate juridică și de utilitate publică recunoscută în confomitate cu prevederile legii.",
      sc_p4:
        "Activitățile camerei bilaterale sunt finanțate din surse de venit conforme cu prevederile legale specifice organizațiilor neguvernamentale și cu dispozițiile statutului propriu.",
      sc_p5:
        "Susținerea organizării și funcționării camerei bilaterale reprezintă o prioritate pentru realizarea și îndeplinirea în bune condiții a scopului și obiectivelor asumate de CCIRC și respectarea atribuțiilor prevăzute de lege.",
      sc_p6: "Varianta I - Cotizații și taxe de înscriere deductibile",
      sc_p7:
        "CADRUL LEGAL: Legea nr. 227/2015 privind Codul Fiscal, art. 25. alin.(1)",
      sc_p8:
        "Pentru determinarea rezultatului fiscal sunt considerate cheltuieli deductibile cheltuielile efectuate în scopul desfăşurării activităţii economice, inclusiv cele reglementate prin acte normative în vigoare, precum şi taxele de înscriere, cotizaţiile şi contribuţiile datorate către camerele de comerţ şi industrie, organizaţiile patronale şi organizaţiile sindicale.",
      sc_p9: "DOCUMENT BILATERAL: Contract de cotizație",
      sc_p10:
        "Contractul de cotizație este un document bilateral semnat între o companie și CCIRC, prin care cele două părți își asumă, prima, responsabilități privind finanțarea activităților cu o sumă certă iar a doua, acordă beneficii și facilități în conformitate cu statutul propriu și legislația specifică camerelor de comerț.",
      sc_p11: "Descarcă model - Contract de cotizație",
      sc_p12: "Varianta II - Contribuții deductibile",
      sc_p13:
        "CADRUL LEGAL: Legea nr. 227/2015 privind Codul Fiscal, art. 25. alin.(1)",
      sc_p14:
        "Pentru determinarea rezultatului fiscal sunt considerate cheltuieli deductibile cheltuielile efectuate în scopul desfăşurării activităţii economice, inclusiv cele reglementate prin acte normative în vigoare, precum şi taxele de înscriere, cotizaţiile şi contribuţiile datorate către camerele de comerţ şi industrie, organizaţiile patronale şi organizaţiile sindicale.",
      sc_p15: "DOCUMENT BILATERAL: Contract de contribuție",
      sc_p16:
        "Contractul de contribuție este un document bilateral semnat între o companie și CCIRC, prin care cele două părți își asumă, prima, responsabilități privind finanțarea activităților cu o sumă certă iar a doua, acordă beneficii și facilități în conformitate cu statutul propriu și legislația specifică camerelor de comerț.",
      sc_p17: "Descarcă model - Contract de contribuție",
      sc_p18:
        "Varianta III - Sponsorizări, sume scăzute din impozitul pe profit datorat",
      sc_p19: "CADRUL LEGAL: Legea nr. 32/1994 privind Sponsorizarea",
      sc_p20:
        "Sponsorizarea este activitatea care se desfăşoară pe baza unui contract încheiat între sponsor şi beneficiar, care consimt asupra mijloacelor financiare şi a bunurilor materiale care se acordă ca sprijin, precum şi asupra duratei sponsorizării. În cazul sponsorizării constând în bunuri materiale, acestea vor fi evaluate prin contractul de sponsorizare la valoarea lor reală din momentul predării lor către beneficiar.",
      sc_p21:
        "Legea nr. 227/2015 privind Codul Fiscal și normele de aplicare, art. 25 alin.(4)",
      sc_p22:
        "Suma plătită ca sponsorizare se scade din impozitul pe profit datorat “[...] la nivelul valorii minime dintre limita a 0,75% din cifra de afaceri și 20% din impozitul pe profit datorat”.",
      sc_p23: "DOCUMENT BILATERAL: Contract de sponsorizare",
      sc_p24:
        "Contractul de sponsorizare este un document bilateral semnat între o companie și CCIRC, prin care cele două părți își asumă, prima, responsabilități privind finanțarea activităților sau proiectelor cu o sumă certă iar a doua, va aduce la cunoștința publicului, în mod gratuit, sponsorizarea prin promovarea numelui mărcii sau imaginii companiei.",
      sc_p25: "Descarcă model - Contract de sponsorizare",
      sc_p26:
        "Varianta IV - Redirecționarea a 3,5% din valoarea impozitului pe venit al contribuabilului",
      sc_p27:
        "CADRUL LEGAL: Legea nr. 227/2015 privind Codul Fiscal, art. 1231, alin. (1)",
      sc_p28:
        "Contribuabilii care înregistrează venituri din salarii, pensii, activități independente, drepturi de proprietate intelectuală sau din cedarea folosinței bunurilor pot dispune asupra destinației unei sume reprezentând până la 3,5% din impozit pentru susținerea entităților non profit care se înființează și funcționează în condițiile legii [...]",
      sc_p29: "DOCUMENT UNILATERAL: Formularul fiscal 230 sau 212",
      sc_p30:
        "Formularul fiscal 230 se completează, anual, document prin care se redirecționează către CCIRC, 3,5% din impozitul pe venit de persoane care obțin venituri din: salarii, pensii și activități independente impozitate pe bază de normă de venit. Documentul se depune la Administrația Financiară (ANAF) de care persoana aparține, prin depunere directă la ghișeu sau prin poștă cu scrisoare recomandată. Nu este nevoie să se completeze suma!",
      sc_p31: "Descarcă model - Formular 230",
      sc_p32:
        "Formularul fiscal 212 se completează, anual, document prin care se redirecționează către CCIRC, 3,5% din impozitul pe venit de persoane care obțin venituri din: venituri din activități independente pentru care venitul se determină în sistem real, venituri din drepturi de proprietate intelectuală, venituri din investiții și venituri din străinătate. Documentul se depune la Administrația Financiară (ANAF), prin depunere directă la ghișeu sau prin poștă cu scrisoare recomandată.",
      sc_p33: "Descarcă model - Formular 212",
      sc_p34: "Datele principale ale CCIRC",
      sc_p35:
        "Toate informațiile necesare pentru completarea contractelor și formularelor.",
      sediu: "Sediu",
      punct_de_lucru: "Punct de lucru",
      reprezentant_legal: "Reprezentant legal",
      informatii_legale: "Informații legale",
      nr_inreg: "Nr. înreg.",
      registrul_asociatilor:
        "Registrul Asociaţiilor şi Fundaţiilor nr. 11966/A/2010",
      informatii_bancare: "Informații bancare",
    },
  },
  en: {
    translation: {
      acasa: "Home",
      prezentare: "Presentation",
      echipa: "Team",
      agenda: "Agenda",
      parteneriate: "Partnerships",
      facilitati: "Facilities",
      servicii: "Services",
      filiale: "Branches",
      filiale_ro: "RO Branches",
      reprezentante_ch: "CH Representations",
      membri: "Members",
      membri_ccirc: "CCIRC Members",
      membri_onoare: "Honorary Members",
      aderare: "Membership",
      statut_ccirc: "CCIRC Statute",
      procedura: "Procedure",
      adeziune: "Adhesion",
      fisa_companiei: "Company Profile",
      contact: "Contact",
      sustine_ccirc: "Support CCIRC",
      relatii_bilaterale: "BILATERAL RELATIONS",
      romania_china: "Romania - China",
      studii_documentare: "DOCUMENTARY STUDIES",
      ministerul_afacerilor_externe: "Ministry of Foreign Affairs",
      sfaturi_calatorie_china: "Travel Advice - China",
      ambasada_romaniei: "Romanian Embassy in the People's Republic of China",
      ambasada_chinei: "Embassy of the People's Republic of China in Romania",
      institutul_cultural_roman: "Romanian Cultural Institute",
      icr_beijing: "Romanian Cultural Institute Beijing",
      centrul_cultural_chinez: "Chinese Cultural Center",
      ccc_bucuresti: "CCC Bucharest",
      institutul_confucius: "Confucius Institute",
      universitati_romania: "Romanian Universities",
      radio_china_international: "Radio China International",
      sectia_romana: "Romanian Section",
      centrul_de_vize: "China Visa Center",
      invest_romania: "Invest Romania",
      investeste_in_romania: "Invest in Romania",
      parteneri: "Partners",
      meniu: "Menu",
      cauta_postari: "Search posts...",
      anterior: "Previous",
      urmator: "Next",
      pagina: "Page",
      din: "of",
      ing_jur_nicolae_vasilescu: "Eng. Jur. Nicolae Vasilescu",
      presedinte_ccirc: "CCIRC President",
      descriere_nicolae_vasilescu:
        "Nicolae Vasilescu is a Romanian politician and member of the Romanian Parliament. Since December 19, 2012, he has been the President of the Chamber of Deputies' Rules Committee.",
      citeste_mai_mult: "Read more",
      amb_viorel_isticioaia_budura: "Amb. Viorel Isticioaia-Budura",
      presedinte_de_onoare_ccirc: "Honorary President of CCIRC",
      descriere_viorel_isticioaia:
        "Viorel Isticioaia Budura is the Director General of the Asia Pacific Department within the European External Action Service (EEAS) of the European Union, the EU's diplomatic service.",
      sediul_central: "Head Office",
      telefon: "Phone",
      email: "Email",
      unde_ne_gasesti: "Where to Find Us",
      trimite_ne_un_mesaj: "Send Us a Message",
      conectare_membri: "LOG-IN MEMBERS",
      conectare: "Login",
      numele_utilizator: "Username",
      parola: "Password",
      introduceti_numele_utilizator: "Enter your username",
      introduceti_parola: "Enter your password",
      loading: "Loading...",
      bine_ai_venit: "Welcome",
      deconectare: "Logout",
      catalog_servicii_de_afaceri: "Business Services Catalog for Companies",
      consultanta_in_afaceri: "Consulting in Bilateral Business Areas",
      consultanta_comerciala:
        "Commercial, Legal, Financial and Risk Consulting",
      targuri_expozitii: "Fairs and exhibitions",
      conferinte: "Conferences",
      data_locatie: "Date / Location",
      eveniment: "Event",
      organizator: "Organizer",
      agenda_paragraph:
        "Explore the economic and commercial events in China for 2025. Discover the opportunities offered by exhibitions and conferences in the most important industries.",
      despre_noi: "About us",
      politica_de_confidentialitate: "Privacy Policy",
      politica_de_cookies: "Cookies Policy",
      cerere_de_aderare: "Membership Application",
      contract_de_adeziune: "Membership Adhesion Contract",
      fisa_companiei_domenii: "Company Profile - fields of interest",
      fisa_companii: "Company profile - contact info",
      mandat_de_reprezentare: "Representation mandate",
      catalog_servicii_afaceri: "Business services catalog for companies",
      catalog_servicii_logistica:
        "Logistics services catalog for import-export",
      camera_de_comert_si_industrie_romania_china:
        "Romania-China Chamber of Commerce and Industry",
      prezentare_p1:
        "The Romania-China Chamber of Commerce and Industry represents a successful model in promoting and developing economic relations between Romania and the People's Republic of China, with over 20 years of experience and over 600 member companies actively contributing to its projects.",
      prezentare_p2:
        "Our organization collaborates closely with Romanian government institutions and supports B2B business dialogue between companies in Romania and China, promoting Romanian exports and balancing the trade balance.",
      identitate: "Identity",
      prezentare_p3:
        "CCIRC is an autonomous, non-governmental, non-political and non-profit organization, recognized for its significant contribution to bilateral relations between Romania and the People's Republic of China.",
      prezentare_p4:
        "CCIRC is committed to being the main partner for dialogue in the field of bilateral economic and commercial relations, providing solutions and expertise for its members' projects and the business community.",
      obiective_institutionale: "Institutional Objectives",
      obiectiv_1:
        "Expanding the territorial network by opening branches and representations in Romania and China.",
      obiectiv_2:
        "Attracting new members, including Chinese companies interested in investment projects in Romania.",
      obiectiv_3:
        "Optimizing the internal management structure and databases for efficiency.",
      obiectiv_4:
        "Increasing the public visibility of CCIRC and promoting the image of its members.",
      prezentare_p5:
        "CCIRC carries out its specific activities in the CCIR BUSINESS CENTER, 6th floor, having several spaces arranged as work or coordination offices for internal departments and management, as well as a conference room equipped with the most modern multimedia equipment. The central management of CCIRC activities is ensured by a General Secretariat composed of executive secretaries and employees or permanent collaborators of the chamber.",
      suprafata: "Surface",
      mp_250: "250 sqm",
      suprafata_p1: "of offices and conference rooms",
      prezentare_p6: "Employees and permanent collaborators",
      servicii_si_activitati: "Services and Activities",
      colaborare_instiutionala: "Institutional Collaboration",
      prezentare_p7:
        "CCIRC collaborates with institutions and associations to promote bilateral projects and support economic events.",
      dialog_diplomatic: "Diplomatic Dialogue",
      prezentare_p8:
        "Maintaining a constant dialogue with diplomatic missions and consulates to develop economic collaborations.",
      organizare_evenimente: "Event Organization",
      prezentare_p9:
        "Organizing business forums, exhibitions and economic missions in Romania and the People's Republic of China.",
      consultanta: "Consulting",
      prezentare_p10:
        "We offer consulting in international economic transactions, legal, financial and risk aspects for member companies.",
      obiective_de_dezvoltare_interna: "Internal Development Objectives",
      prezentare_p11: "Expanding the territorial network",
      prezentare_p12:
        "Establishing branches in the country's counties and in Bucharest, as well as opening representations in the main municipalities of the People's Republic of China to expand the chamber's activity and visibility.",
      prezentare_p13: "Increasing the number of members",
      prezentare_p14:
        "Attracting top companies conducting bilateral economic activities and Chinese companies interested in investments in Romania to consolidate the CCIRC business community.",
      prezentare_p15: "Developing internal management structures",
      prezentare_p16:
        "Improving the activities of CCIRC departments and directions, efficiently managing databases and archiving documents for optimized internal administration.",
      prezentare_p17: "Public promotion",
      prezentare_p18:
        "Developing a system of public relations and active protocol to promote the image of CCIRC and its members, ensuring an active and efficient public presence.",
      prezentare_p19: "Joining international organizations",
      prezentare_p20:
        "Involvement in organizations and associations with common objectives to promote bilateral relations with the People's Republic of China and support international economic relations.",
      prezentare_p21: "Concluding protocols and agreements",
      prezentare_p22:
        "Collaborating with Romanian and Chinese public authorities and institutions through protocols, agreements and memoranda to support the objectives and mission of the bilateral chamber.",
      promovare_si_reprezentare: "Promotion and Representation",
      prezentare_p23:
        "promotes the economic interests of its members through initiatives to support exports, promote foreign investments and bilateral collaboration with partners from the People's Republic of China.",
      prezentare_p24:
        "Promoting Romanian brands and products in the People's Republic of China",
      prezentare_p25: "Encouraging exports to balance the trade balance",
      prezentare_p26:
        "Promoting collaborations in education and scientific research",
      prezentare_p27: "Developing partnerships to attract foreign investments",
      organizare_de_evenimente: "Event Organization",
      participare_la_forumuri: "Participation in forums",
      prezentare_p28:
        "We organize the participation of CCIRC members in business forums, fairs and exhibitions.",
      misiuni_economice: "Economic missions",
      prezentare_p29:
        "We initiate and participate in economic missions in the People's Republic of China and receive Chinese delegations in Romania.",
      eveniment_de_suport: "Support event",
      prezentare_p30:
        "We organize consultancy and service events for the development of bilateral projects.",
      acordare_de_burse: "Granting scholarships",
      prezentare_p31:
        "We facilitate the granting of study scholarships for students from Romania and the People's Republic of China.",
      colaborare_cu_mediul_diplomatic:
        "Collaboration with the Diplomatic Environment",
      prezentare_p32:
        "CCIRC maintains permanent contact with diplomatic institutions in Romania and the People's Republic of China, ensuring a solid partnership to support bilateral economic and commercial relations.",
      informarea_reciproca: "Mutual information",
      prezentare_p33:
        "Ensuring a constant exchange of information about the delegations of institutions and companies visiting Romania and the People's Republic of China.",
      schimb_de_informatii: "Information exchange",
      prezentare_p34:
        "Maintaining a flow of useful information from the areas of bilateral interest for economic and commercial cooperation.",
      promovarea_initiativelor: "Promoting initiatives",
      prezentare_p35:
        "Facilitating the identification of business partnerships through mutual information on projects of Romanian and Chinese companies.",
      sprijin_in_relatii: "Support in B2B relations",
      prezentare_p36:
        "CCIRC is recommended as the main partner for B2B collaborations between companies in Romania and the People's Republic of China.",
      evenimente_dedicate: "Dedicated events",
      prezentare_p37:
        "Organizing events in collaboration with diplomatic institutions to promote bilateral cooperation.",
      informarea_comunitatii: "Informing the Chinese community",
      prezentare_p38:
        "Informative activities about Romanian legislation, in collaboration with the Embassy of the People's Republic of China in Bucharest.",
      cercetare_informare_expertiza: "Research, Information and Expertise",
      prezentare_p39:
        "supports member companies and authorities through research and information activities, providing expertise in areas essential for bilateral economic and commercial cooperation.",
      elaborarea_de_evaluari: "Elaboration of evaluations and reports",
      prezentare_p40:
        "Detailed analyzes of the status of bilateral economic and commercial cooperation between Romania and the People's Republic of China.",
      informarea_entitatilor: "Informing Chinese economic entities",
      prezentare_p41:
        "Providing information on the norms, standards and commercial practices of the European Union, essential for companies in China.",
      sustinerea_proiectelor: "Supporting research and development projects",
      prezentare_p42:
        "Initiating own projects or partnerships with specialized institutions for research in areas of bilateral interest.",
      publicatii_informative: "Informative publications",
      prezentare_p43:
        "Editing bulletins, catalogs and other informative materials, printed or online, with periodic or occasional character.",
      crearea_de_programe: "Creating software and online platforms",
      prezentare_p44:
        "Developing websites and pages on social platforms to promote CCIRC activities and those of member companies.",
      constituirea_de_baze: "Establishing databases and documents",
      prezentare_p45:
        "Collecting and facilitating access to specific information on bilateral activities, available to member companies and authorities.",
      structurile_nationale: "National Leadership Structures",
      prezentare_p46:
        "The Romania-China Chamber of Commerce and Industry is led by national structures organized to ensure the implementation and strategic coordination of bilateral initiatives and projects. These include collective leadership and operational coordination of current activities.",
      conferinta_nationala: "National Conference",
      prezentare_p47:
        "It is the national leadership forum of CCIRC, responsible for adopting strategic documents and resolutions regarding economic and commercial bilateral relations.",
      colegiul_national: "National Leadership College",
      prezentare_p48:
        "The collective coordination body that periodically discusses the CCIRC agenda and makes decisions for the implementation of the bilateral chamber's objectives.",
      biroul_permanent: "National Permanent Office",
      prezentare_p49:
        "The executive structure that coordinates current activities and ensures the public representation of CCIRC in Romania and internationally.",
      certificari: "Certifications",
      prezentare_p50:
        "CCIRC has obtained certification of its management systems, both in terms of quality",
      prezentare_p51: "and information security",
      prezentare_p52:
        "is a personal data operator, in accordance with the provisions of Law no. 677/2001 on the processing of personal data",
      prezentare_p53:
        "is registered in the Single Register of Transparency and Interests",
      afilieri: "Affiliations",
      afilieri_nationale: "National affiliations",
      prezentare_p54:
        "is a member of the Chamber of Commerce and Industry of Romania since 2003.",
      prezentare_p55:
        "is a founding member of the National Federation of Bilateral Chambers of Commerce.",
      prezentare_p56: "International affiliations",
      parteneriate_in_rp: "Partnerships in the People's Republic of China",
      parteneriate_regionale_locale: "Regional and local partnerships",
      descarca_fisierul: "Download file",
      descarca_fisa: "Download file",
      descarca_contract: "Download file",
      descarca_cererea: "Download file",
      facilitati_1: "Facilities presentation for CCIRC members",
      facilitati_2:
        "List of benefits and facilities for companies with Romanian shareholding",
      facilitati_3:
        "List of benefits and facilities for companies with Chinese shareholding",
      facilitati_4:
        "List of benefits and facilities for Chinese companies or their subsidiaries in Romania",
      descarca_brosura: "Download brochure",
      catalog_servicii_de_logistica:
        "Logistics services catalog for import-export business activities",
      studii_d1: "A. ROMANIA - CHINA INSTITUTIONAL BILATERAL RELATIONS",
      studii_d2: "B. ROMANIA - CHINA ECONOMIC BILATERAL RELATIONS",
      studii_d3: "C. CHINA - EUROPE BILATERAL RELATIONS",
      studii_d4: "D. CHINA INTERNATIONAL RELATIONS",
      studii_d5: "E. ROMANIA - GENERAL INFORMATION",
      titlul_documentului: "Document Title",
      tip_document: "Document Type",
      nivel_acces: "Access Level",
      valabilitate: "Validity",
      nr_document: "Doc. No.",
      capitolul_1: "Chapter I",
      capitolul_2: "Chapter II",
      logistica_1:
        "Inspection and quality control of imported / exported products",
      logistica_2: "Transport and logistics of imported and exported products",
      nu_exista_postari: "No posts",
      pagina_in_lucru: "Page under construction!",
      pagina_in_lucru_p1:
        "We are working to complete it as soon as possible. Thank you for understanding!",
      inapoi_la_pagina: "Back to home page",
      membri_ccirc_p1:
        "Over 500 Romanian and Chinese economic and commercial entities are members of CCIRC. Of these, 22 companies have been awarded the status of Strategic Partners of the chamber.",
      membri_ccirc_p2:
        "CCIRC members are economic and commercial entities from Romania and the People's Republic of China:",
      membri_ccirc_l1: "Commercial companies",
      membri_ccirc_l2: "Multinational companies",
      membri_ccirc_l3: "National companies and autonomous regions",
      membri_ccirc_l4: "Economic interest groups",
      membri_ccirc_l5: "Cooperative organizations",
      membri_ccirc_l6: "Authorized natural persons",
      membri_ccirc_l7:
        "Other categories of professionals conducting economic activities",
      membri_ccirc_l8: "Economic, commercial and professional associations",
      membri_ccirc_l9: "Employers' organizations",
      membri_ccirc_l10:
        "Companies registered in the People's Republic of China, their branches or representations in Romania",
      facilitati_membri: "Facilities for CCIRC members",
      facilitati_p1: "CCIRC offers its members a wide range of facilities:",
      facilitati_p2:
        "facilitates the establishment of partnerships and collaborations between member companies, as well as with other economic entities in Romania and the People's Republic of China.",
      facilitati_p3:
        "facilitates the establishment of partnerships and collaborations between member companies, as well as with other economic entities in Romania and the People's Republic of China.",
      facilitati_p4:
        "facilitates the establishment of partnerships and collaborations between member companies, as well as with other economic entities in Romania and the People's Republic of China.",
      facilitati_p5:
        "facilitates the establishment of partnerships and collaborations between member companies, as well as with other economic entities in Romania and the People's Republic of China.",
      facilitati_p6:
        "facilitates the establishment of partnerships and collaborations between member companies, as well as with other economic entities in Romania and the People's Republic of China.",
      facilitati_p7:
        "facilitates the establishment of partnerships and collaborations between member companies, as well as with other economic entities in Romania and the People's Republic of China.",
      facilitati_p8:
        "promotes investment projects and public-private partnership initiatives of companies in front of the administrative-territorial units in Romania.",
      facilitati_p9:
        "facilitates the participation of company representatives in CCIRC's own events or organized in partnership, as well as in events or activities to which it is invited to participate, in Romania or in the People's Republic of China, with the aim of developing the public and commercial relations of the companies.",
      facilitati_p10:
        "facilitates the obtaining of invitations and access to public or private events in Romania and the People's Republic of China, which are of interest to the bilateral economic relations of the companies.",
      facilitati_p11:
        "forums, conferences, congresses, seminars, workshops, etc.",
      facilitati_p12:
        "provides companies with support for organizing their own events in Romania or the People's Republic of China, by ensuring the participation of representatives of authorities, institutions or associative economic, chamber, employer and trade union structures, at central and local level from the country and from the People's Republic of China, as well as the Chinese business community in Romania.",
      facilitati_p13:
        "organizes or facilitates the registration and participation of company representatives or staff in seminars presenting the business environment and economic and commercial legislation in Romania or the People's Republic of China and in negotiation and communication training courses specific to bilateral relations, as well as in courses to learn Romanian and Chinese languages.",
      facilitati_p14:
        "facilitates the access and participation of companies in fairs, exhibitions and other similar economic and commercial events taking place in Romania or the People's Republic of China.",
      facilitati_p15:
        "informs about the opportunities for company representatives to participate in various forms of training or access study scholarships in Romania or the People's Republic of China.",
      imagine_si_publicitate: "Image and Advertising",
      facilitati_p16:
        "Ensures companies' access to events organized by the bilateral chamber to carry out advertising campaigns to promote their projects, image and brands.",
      facilitati_p17:
        "Promotes companies' products and services at the stands organized by the bilateral chamber at the fairs and exhibitions it attends, in Romania or in the People's Republic of China.",
      facilitati_p18:
        "Promotes companies' logos on the advertising documents and materials of the bilateral chamber's events, under the conditions agreed upon.",
      facilitati_p19:
        "Promotes companies' image and distributes news related to their activity in the printed publications and catalogs, as well as on the website or social media pages of the bilateral chamber.",
      facilitati_p20:
        "Grants the right to use the logo of the bilateral chamber on the image and promotion documents and materials of the companies.",
      facilitati_p21:
        "Awards distinctions and honorary titles to companies or their representatives, for their activity, projects or economic and commercial partnerships with a bilateral character.",
      suport_pentru_afaceri: "Business Support",
      facilitati_p22:
        "Identifies and verifies potential business partners of companies from Romania or the People's Republic of China",
      facilitati_p23: "legal, financial and credit analysis",
      facilitati_p24:
        "presents companies with projects or business opportunities communicated to the bilateral chamber by Romanian or Chinese companies or its partners in the People's Republic of China, which may be considered of interest to them.",
      facilitati_p25:
        "organizes B2B meetings for company representatives with companies or economic and commercial entities from Romania or the People's Republic of China.",
      facilitati_p26:
        "provides specialized assistance to company representatives in negotiations with economic and commercial entities from Romania or the People's Republic of China.",
      facilitati_p27:
        "facilitates companies' contacts with partners from Romania or the People's Republic of China for the safe and quality conduct of import-export commercial activities.",
      facilitati_p28:
        "lists on the business platform of the bilateral chamber.",
      facilitati_p29:
        "ensures companies' access to the database, documentary studies, analysis documents, evaluations and statistics elaborated by the bilateral chamber, regarding the economic and social evolution in Romania or the People's Republic of China.",
      facilitati_p30:
        "informs companies about the business environment, competition policies and intellectual property practices, the legislative framework with an impact on the business and investment environment in Romania or the People's Republic of China.",
      consultanta_si_servicii: "Consulting and Services",
      facilitati_p31:
        "provides companies, under contractual conditions, business, legal and financial consulting, as well as other services specific to the development of bilateral projects.",
      facilitati_p32:
        "provides companies with information on identifying and attracting sources of funding for business projects, the formalities, norms and procedures for establishing, organizing and operating their commercial companies in Romania or the People's Republic of China.",
      facilitati_p33:
        "provides companies with assistance in preparing the specific documents for obtaining transit visas, short or long stays in Romania or the People's Republic of China, for their staff or representatives.",
      facilitati_p34:
        "ensures companies' access to the logistics services contracted by the bilateral chamber with its partners, at preferential rates: internal and international freight transport",
      facilitati_p35: "land, naval and air",
      facilitati_p36:
        ", inspection and control of imported or exported products, customs brokers etc.",
      facilitati_p37:
        "ensures companies' access to the tourist assistance and business travel services contracted by the bilateral chamber with its partners, at preferential rates: internal and international passenger transport, hotel service reservations, rent-a-car, accompanying guides etc.",
      facilitati_p38:
        "ensures companies' access to the spaces and meeting and protocol rooms, as well as to the technical equipment in the central headquarters of the bilateral chamber for organizing their own meetings and events.",
      facilitati_p39:
        "provides companies with expertise for analyzing, evaluating and resolving commercial disputes and conflicts, before they reach the court and offers alternative methods of resolving them through mediation, conciliation and arbitration.",
      facilitati_p40:
        "assists companies in front of the International Commercial Arbitration Court attached to the Chamber of Commerce and Industry of Romania for the settlement of commercial disputes.",
      facilitati_p41:
        "Most services are free or have a reduced fee for CCIRC members.",
      consultanta_import: "Import/Export Consulting",
      reprezentare_si_promovare: "Representation and Promotion",
      actiuni_si_evenimente: "Actions and Events",
      lista_beneficii_1:
        "List of benefits and facilities for companies with Romanian shareholding",
      lista_beneficii_2:
        "List of benefits and facilities for companies with Chinese shareholding",
      lista_beneficii_3:
        "List of benefits and facilities for Chinese companies or their subsidiaries in Romania",
      parteneri_strategici: "Strategic Partners",
      parteneri_servicii: "Services Partners",
      contacteaza_ne: "Contact Us",
      ai_intrebari:
        "Do you have questions? You can find us using the information below.",
      hotarare_inregistrare: "Registration decision",
      certificat_de_inregistrare: "Tax registration certificate",
      registrul_national: "National Register",
      nr_regist: "National Register number of non-profit legal entities",
      banca: "Bank",
      adresa: "Address",
      sc_p1:
        "Ways to financially support the activity of the Romania - China Chamber of Commerce and Industry",
      sc_p2:
        "is a bilateral chamber of commerce and operates in accordance with the provisions of the Romanian Chambers of Commerce Law no. 335/2007 and O.G. no. 26/2000 on associations and foundations, with subsequent amendments and completions.",
      sc_p3:
        "is an autonomous, non-governmental, non-political, non-profit organization, with legal personality and public utility recognized in accordance with the provisions of the law.",
      sc_p4:
        "The activities of the bilateral chamber are financed from income sources in accordance with the legal provisions specific to non-governmental organizations and with the provisions of its own statute.",
      sc_p5:
        "Supporting the organization and functioning of the bilateral chamber is a priority for the proper realization and fulfillment of the purpose and objectives assumed by the RCCI and the observance of the duties provided by law.",
      sc_p6: "Option I - Deductible dues and registration fees",
      sc_p7:
        "LEGAL FRAMEWORK: Law no. 227/2015 regarding the Fiscal Code, art. 25. para. (1)",
      sc_p8:
        "For the determination of the fiscal result, the expenses incurred for the purpose of carrying out the economic activity are considered deductible expenses, including those regulated by the legislation in force, as well as the registration fees, dues and contributions due to the chambers of commerce and industry, employers' organizations and trade unions.",
      sc_p9: "BILATERAL DOCUMENT: Dues contract",
      sc_p10:
        "The dues contract is a bilateral document signed between a company and RCCI, by which the two parties assume, the first, responsibilities regarding the financing of the activities with a certain amount and the second, grant benefits and facilities in accordance with its own statute and the specific legislation of the chambers of commerce.",
      sc_p11: "Download model - Dues contract",
      sc_p12: "Option II - Deductible contributions",
      sc_p13:
        "LEGAL FRAMEWORK: Law no. 227/2015 regarding the Fiscal Code, art. 25. para. (1)",
      sc_p14:
        "For the determination of the fiscal result, the expenses incurred for the purpose of carrying out the economic activity are considered deductible expenses, including those regulated by the legislation in force, as well as the registration fees, dues and contributions due to the chambers of commerce and industry, employers' organizations and trade unions.",
      sc_p15: "BILATERAL DOCUMENT: Contribution contract",
      sc_p16:
        "The contribution contract is a bilateral document signed between a company and RCCI, by which the two parties assume, the first, responsibilities regarding the financing of the activities with a certain amount and the second, grant benefits and facilities in accordance with its own statute and the specific legislation of the chambers of commerce.",
      sc_p17: "Download model - Contribution contract",
      sc_p18:
        "Option III - Sponsorships, low amounts deducted from the profit tax due",
      sc_p19: "LEGAL FRAMEWORK: Law no. 32/1994 regarding Sponsorship",
      sc_p20:
        "Sponsorship is the activity carried out on the basis of a contract concluded between the sponsor and the beneficiary, who agree on the financial means and material goods that are granted as support, as well as on the duration of the sponsorship. In the case of sponsorship consisting of material goods, these will be evaluated by the sponsorship contract at their real value from the moment of their delivery to the beneficiary.",
      sc_p21:
        "Law no. 227/2015 regarding the Fiscal Code and the implementing rules, art. 25 para. (4)",
      sc_p22:
        'The amount paid as sponsorship is deducted from the profit tax due "[...] at the minimum value between the limit of 0.75% of the turnover and 20% of the profit tax due".',
      sc_p23: "BILATERAL DOCUMENT: Sponsorship contract",
      sc_p24:
        "The sponsorship contract is a bilateral document signed between a company and RCCI, by which the two parties assume, the first, responsibilities regarding the financing of the activities or projects with a certain amount and the second, will make known to the public, free of charge, the sponsorship by promoting the name of the company's brand or image.",
      sc_p25: "Download model - Sponsorship contract",
      sc_p26: "Option IV - Redirecting 3.5% of the taxpayer's income tax value",
      sc_p27:
        "LEGAL FRAMEWORK: Law no. 227/2015 regarding the Fiscal Code, art. 1231, para. (1)",
      sc_p28:
        "Taxpayers who earn income from salaries, pensions, independent activities, intellectual property rights or from the use of goods can decide on the destination of an amount representing up to 3.5% of the tax to support non-profit entities that are established and operate in accordance with the law [...]",
      sc_p29: "UNILATERAL DOCUMENT: Fiscal form 230 or 212",
      sc_p30:
        "Fiscal form 230 is completed, annually, a document by which 3.5% of the income tax is redirected to RCCI, for persons who earn income from: salaries, pensions and independent activities taxed on the basis of income norm. The document is submitted to the Tax Administration (ANAF) to which the person belongs, by direct submission at the counter or by mail with a registered letter. There is no need to complete the amount!",
      sc_p31: "Download model - Form 230",
      sc_p32:
        "Fiscal form 212 is completed, annually, a document by which 3.5% of the income tax is redirected to RCCI, for persons who earn income from: income from independent activities for which the income is determined in real system, income from intellectual property rights, income from investments and income from abroad. The document is submitted to the Tax Administration (ANAF), by direct submission at the counter or by mail with a registered letter.",
      sc_p33: "Download model - Form 212",
      sc_p34: "Main data of RCCI",
      sc_p35: "All the information needed to complete the contracts and forms.",
      sediu: "Headquarters",
      punct_de_lucru: "Branch",
      reprezentant_legal: "Legal representative",
      informatii_legale: "Legal information",
      nr_inreg: "Reg. no.",
      registrul_asociatilor:
        "Associations and Foundations Register no. 11966/A/2010",
      informatii_bancare: "Bank information",
    },
  },
  ch: {
    translation: {
      acasa: "首页",
      prezentare: "介绍",
      echipa: "团队",
      agenda: "日程",
      parteneriate: "合作伙伴",
      facilitati: "设施",
      servicii: "服务",
      filiale: "分支机构",
      filiale_ro: "罗马尼亚分支",
      reprezentante_ch: "中国代表处",
      membri: "会员",
      membri_ccirc: "中罗工商会会员",
      membri_onoare: "荣誉会员",
      aderare: "入会",
      statut_ccirc: "中罗工商会章程",
      procedura: "程序",
      adeziune: "加入",
      fisa_companiei: "公司简介",
      contact: "联系方式",
      sustine_ccirc: "支持中罗工商会",
      relatii_bilaterale: "双边关系",
      romania_china: "罗马尼亚 - 中国",
      studii_documentare: "研究文件",
      ministerul_afacerilor_externe: "外交部",
      sfaturi_calatorie_china: "中国旅行建议",
      ambasada_romaniei: "罗马尼亚驻中华人民共和国大使馆",
      ambasada_chinei: "中华人民共和国驻罗马尼亚大使馆",
      institutul_cultural_roman: "罗马尼亚文化学院",
      icr_beijing: "罗马尼亚文化学院北京",
      centrul_cultural_chinez: "中国文化中心",
      ccc_bucuresti: "中国文化中心布加勒斯特",
      institutul_confucius: "孔子学院",
      universitati_romania: "罗马尼亚大学",
      radio_china_international: "中国国际广播电台",
      sectia_romana: "罗马尼亚语部",
      centrul_de_vize: "中国签证中心",
      invest_romania: "投资罗马尼亚",
      investeste_in_romania: "投资罗马尼亚",
      parteneri: "合作伙伴",
      meniu: "菜单",
      cauta_postari: "搜索帖子...",
      anterior: "上一页",
      urmator: "下一页",
      pagina: "页",
      din: "中",
      ing_jur_nicolae_vasilescu: "工程师 法律顾问 尼古拉·瓦西列斯库",
      presedinte_ccirc: "CCIRC 主席",
      descriere_nicolae_vasilescu:
        "尼古拉·瓦西列斯库是一名罗马尼亚政治家、罗马尼亚议会议员。自2012年12月19日起,他担任众议院规章委员会主席。",
      citeste_mai_mult: "阅读更多",
      amb_viorel_isticioaia_budura: "大使 维奥雷尔·伊斯提乔亚亚-布杜拉",
      presedinte_de_onoare_ccirc: "CCIRC 名誉主席",
      descriere_viorel_isticioaia:
        "维奥雷尔·伊斯提乔亚亚·布杜拉是欧盟外交事务机构(EEAS)亚太部门的总干事, 为欧盟提供外交服务。",
      sediul_central: "总部",
      telefon: "电话",
      email: "电子邮件",
      unde_ne_gasesti: "我们的位置",
      trimite_ne_un_mesaj: "发送消息",
      conectare_membri: "会员登录",
      conectare: "登录",
      numele_utilizator: "用户名",
      parola: "密码",
      introduceti_numele_utilizator: "输入用户名",
      introduceti_parola: "输入密码",
      loading: "载入中...",
      bine_ai_venit: "欢迎",
      deconectare: "登出",
      catalog_servicii_de_afaceri: "公司业务服务目录",
      consultanta_in_afaceri: "双边业务领域咨询",
      consultanta_comerciala: "商业、法律、金融和风险咨询",
      targuri_expozitii: "展会",
      conferinte: "会议",
      data_locatie: "日期 / 地点",
      eveniment: "活动",
      organizator: "主办方",
      agenda_paragraph:
        "探索2025年中国的经济和商业活动。了解在最重要的行业中举办的展览和会议提供的机会。",
      despre_noi: "关于我们",
      politica_de_confidentialitate: "隐私政策",
      politica_de_cookies: "Cookies 政策",
      cerere_de_aderare: "会员申请",
      contract_de_adeziune: "会员加入合同",
      fisa_companiei_domenii: "公司简介 - 兴趣领域",
      fisa_companii: "公司简介 - 联系信息",
      mandat_de_reprezentare: "代表授权",
      catalog_servicii_afaceri: "公司业务服务目录",
      catalog_servicii_logistica: "进出口物流服务目录",
      camera_de_comert_si_industrie_romania_china: "罗中工商会",
      prezentare_p1:
        "罗中工商会在推动和发展罗马尼亚和中华人民共和国之间的经济关系方面是一个成功的模式，拥有超过20年的经验和600多家会员公司积极参与其项目。",
      prezentare_p2:
        "我们的组织与罗马尼亚政府机构密切合作，支持罗中公司之间的B2B业务对话，促进罗马尼亚出口，平衡贸易差额。",
      identitate: "身份",
      prezentare_p3:
        "罗中工商会是一个自治、非政府、非政治和非盈利组织，以其在罗马尼亚和中华人民共和国之间的双边关系中的重要贡献而闻名。",
      prezentare_p4:
        "罗中工商会致力于成为双边经济和商业关系领域的主要对话伙伴，为其会员的项目和商业社区提供解决方案和专业知识。",
      obiective_institutionale: "机构目标",
      obiectiv_1: "扩大地域网络，开设分支机构和代表处。",
      obiectiv_2: "吸引新会员，包括对在罗马尼亚投资项目感兴趣的中国公司。",
      obiectiv_3: "优化内部管理结构和数据库以提高效率。",
      obiectiv_4: "提高罗中工商会的公众可见度，推广其会员的形象。",
      prezentare_p5:
        "罗中工商会在CCIR BUSINESS CENTER 6楼开展其特定活动，设有多个办公室或协调办公室，用于内部部门和管理，以及配备最现代化多媒体设备的会议室。罗中工商会的中央管理由一个总秘书处负责，由执行秘书和工作人员或常驻协作者组成。",
      suprafata: "面积",
      mp_250: "250 平方米",
      suprafata_p1: "办公室和会议室",
      prezentare_p6: "员工和常驻合作者",
      servicii_si_activitati: "服务和活动",
      colaborare_instiutionala: "机构合作",
      prezentare_p7: "罗中工商会与机构和协会合作，推动双边项目并支持经济活动。",
      dialog_diplomatic: "外交对话",
      prezentare_p8: "与外交使团和领事馆保持持续对话，发展经济合作。",
      organizare_evenimente: "活动组织",
      prezentare_p9:
        "在罗马尼亚和中华人民共和国的主要城市组织商业论坛、展览和经济代表团。",
      consultanta: "咨询",
      prezentare_p10:
        "我们为会员公司提供国际经济交易、法律、金融和风险方面的咨询。",
      obiective_de_dezvoltare_interna: "内部发展目标",
      prezentare_p11: "扩大地域网络",
      prezentare_p12:
        "在罗马尼亚各县和布加勒斯特设立分支机构，并在中华人民共和国的主要城市开设代表处，以扩展工商会的活动和可见度。",
      prezentare_p13: "增加会员数量",
      prezentare_p14:
        "吸引开展双边经济活动的顶级公司和对在罗马尼亚投资感兴趣的中国公司，以巩固CCIRC商业社区。",
      prezentare_p15: "发展内部管理结构",
      prezentare_p16:
        "改进CCIRC部门和方向的活动，有效管理数据库和档案文件，以优化内部管理。",
      prezentare_p17: "公共推广",
      prezentare_p18:
        "发展公共关系和积极的礼仪系统，以推广CCIRC及其会员的形象，确保积极有效的公共存在。",
      prezentare_p19: "加入国际组织",
      prezentare_p20:
        "参与具有共同目标的组织和协会，以促进与中华人民共和国的双边关系和支持国际经济关系。",
      prezentare_p21: "签订协议和协议",
      prezentare_p22:
        "通过协议、协议和备忘录与罗马尼亚和中国公共机构和机构合作，支持双边商会的目标和任务。",
      promovare_si_reprezentare: "推广和代表",
      prezentare_p23:
        "通过支持出口、促进外国投资和与中华人民共和国合作伙伴的双边合作，推广会员的经济利益。",

      prezentare_p24: "在中华人民共和国推广罗马尼亚品牌和产品",
      prezentare_p25: "鼓励出口以平衡贸易差额",
      prezentare_p26: "促进教育和科学研究的合作",
      prezentare_p27: "发展合作以吸引外国投资",
      organizare_de_evenimente: "活动组织",
      participare_la_forumuri: "参加论坛",
      prezentare_p28: "我们组织CCIRC会员参加商业论坛、展会和会议。",
      misiuni_economice: "经济代表团",
      prezentare_p29:
        "我们发起和参与中华人民共和国的经济代表团，并接待罗马尼亚的中国代表团。",
      eveniment_de_suport: "支持活动",
      prezentare_p30: "我们组织咨询和服务活动，以发展双边项目。",
      acordare_de_burse: "奖学金",
      prezentare_p31: "我们为罗马尼亚和中华人民共和国学生提供学习奖学金。",
      colaborare_cu_mediul_diplomatic: "与外交环境合作",
      prezentare_p32:
        "CCIRC与罗马尼亚和中华人民共和国的外交机构保持常态联系，确保稳固的合作关系，支持双边经济和商业关系。",
      informarea_reciproca: "相互信息",
      prezentare_p33:
        "确保与访问罗马尼亚和中华人民共和国的机构和公司的代表进行信息交流。",
      schimb_de_informatii: "信息交流",
      prezentare_p34: "保持有关经济和商业合作领域的有用信息流。",
      promovarea_initiativelor: "推广倡议",
      prezentare_p35:
        "通过相互信息交流，促进罗马尼亚和中国公司项目的商业合作。",
      sprijin_in_relatii: "B2B关系支持",
      prezentare_p36: "CCIRC被推荐为罗中公司之间B2B合作的主要合作伙伴。",
      evenimente_dedicate: "专门活动",
      prezentare_p37: "与外交机构合作组织活动，促进双边合作。",
      informarea_comunitatii: "中国社区信息",
      prezentare_p38:
        "与中华人民共和国驻布加勒斯特大使馆合作，提供有关罗马尼亚法规的信息。",
      cercetare_informare_expertiza: "研究、信息和专业知识",
      prezentare_p39:
        "通过研究和信息活动，为会员公司和机构提供支持，提供双边经济和商业合作的重要领域的专业知识。",
      elaborarea_de_evaluari: "评估和报告",
      prezentare_p40:
        "详细分析罗马尼亚和中华人民共和国之间双边经济和商业合作的现状。",
      informarea_entitatilor: "中国经济实体信息",
      prezentare_p41: "为中国公司提供欧盟的规范、标准和商业实践信息。",
      sustinerea_proiectelor: "支持研究和发展项目",
      prezentare_p42: "发起自己的项目或与专业机构合作进行双边合作领域的研究。",
      publicatii_informative: "信息出版物",
      prezentare_p43: "编辑通讯、目录和其他信息材料，印刷或在线，定期或偶发。",
      crearea_de_programe: "创建软件和在线平台",
      prezentare_p44:
        "开发网站和社交平台页面，以推广CCIRC活动和会员公司的活动。",
      constituirea_de_baze: "建立数据库和文件",
      prezentare_p45: "收集和便于会员公司和机构访问的有关双边活动的具体信息。",
      structurile_nationale: "国家领导结构",
      prezentare_p46:
        "罗中工商会由组织的国家结构领导，以确保双边倡议和项目的实施和战略协调。这些包括当前活动的集体领导和运作协调。",
      conferinta_nationala: "全国会议",
      prezentare_p47:
        "这是CCIRC的全国领导论坛，负责通过经济和商业双边关系的战略文件和决议。",
      colegiul_national: "全国领导学院",
      prezentare_p48: "定期讨论CCIRC议程并作出决策，以实现双边商会的目标。",
      biroul_permanent: "全国常设办公室",
      prezentare_p49:
        "协调当前活动的执行结构，并确保CCIRC在罗马尼亚和国际上的公共代表。",
      certificari: "认证",
      prezentare_p50: "CCIRC已获得其管理系统的认证，无论是在质量方面",
      prezentare_p51: "还是信息安全方面",
      prezentare_p52:
        "是个人数据运营商，符合2001年第677号法律关于个人数据处理的规定",
      prezentare_p53: "已在透明度和利益单一登记册中注册",
      afilieri: "附属",
      afilieri_nationale: "国家附属",
      prezentare_p54: "自2003年以来，是罗马尼亚商业和工业商会的成员。",
      prezentare_p55: "是双边商会全国联合会的创始成员。",
      prezentare_p56: "国际附属",
      parteneriate_in_rp: "中华人民共和国合作",
      parteneriate_regionale_locale: "区域和当地合作",
      descarca_fisierul: "下载文件",
      descarca_fisa: "下载文件",
      descarca_contract: "下载文件",
      descarca_cererea: "下载文件",
      facilitati_1: "CCIRC会员设施介绍",
      facilitati_2: "罗马尼亚公司股份公司的公司福利和设施清单",
      facilitati_3: "中国公司股份公司的公司福利和设施清单",
      facilitati_4: "中国公司或其在罗马尼亚的子公司的公司福利和设施清单",
      descarca_brosura: "下载手册",
      catalog_servicii_de_logistica: "进出口业务活动的物流服务目录",
      studii_d1: "A. 罗马尼亚 - 中国机构双边关系",
      studii_d2: "B. 罗马尼亚 - 中国经济双边关系",
      studii_d3: "C. 中国 - 欧洲双边关系",
      studii_d4: "D. 中国国际关系",
      studii_d5: "E. 罗马尼亚 - 一般信息",
      titlul_documentului: "文件标题",
      tip_document: "文件类型",
      nivel_acces: "访问级别",
      valabilitate: "有效期",
      nr_document: "文件编号",
      capitolul_1: "第一章",
      capitolul_2: "第二章",
      logistica_1: "进口/出口产品的检验和质量控制",
      logistica_2: "进口和出口产品的运输和物流",
      nu_exista_postari: "没有帖子",
      pagina_in_lucru: "页面正在建设中！",
      pagina_in_lucru_p1: "我们正在努力尽快完成。感谢您的理解！",
      inapoi_la_pagina: "返回首页",
      membri_ccirc_p1:
        "超过500家罗马尼亚和中国经济和商业实体是CCIRC的会员。其中，22家公司获得了该商会的战略合作伙伴地位。",
      membri_ccirc_p2:
        "CCIRC会员是来自罗马尼亚和中华人民共和国的经济和商业实体：",
      membri_ccirc_l1: "商业公司",
      membri_ccirc_l2: "跨国公司",
      membri_ccirc_l3: "国家公司和自治区",
      membri_ccirc_l4: "经济利益集团",
      membri_ccirc_l5: "合作组织",
      membri_ccirc_l6: "授权自然人",
      membri_ccirc_l7: "其他从事经济活动的专业人士类别",
      membri_ccirc_l8: "经济、商业和专业协会",
      membri_ccirc_l9: "雇主组织",
      membri_ccirc_l10:
        "在中华人民共和国注册的公司、其在罗马尼亚的分支机构或代表处",
      facilitati_membri: "CCIRC会员设施",
      facilitati_p1: "CCIRC为其会员提供广泛的设施：",
      facilitati_p2:
        "促进会员公司之间以及与罗马尼亚和中华人民共和国的其他经济实体之间的合作和合作关系的建立。",
      facilitati_p3:
        "促进会员公司之间以及与罗马尼亚和中华人民共和国的其他经济实体之间的合作和合作关系的建立。",
      facilitati_p4:
        "促进会员公司之间以及与罗马尼亚和中华人民共和国的其他经济实体之间的合作和合作关系的建立。",
      facilitati_p5:
        "促进会员公司之间以及与罗马尼亚和中华人民共和国的其他经济实体之间的合作和合作关系的建立。",
      facilitati_p6:
        "促进会员公司之间以及与罗马尼亚和中华人民共和国的其他经济实体之间的合作和合作关系的建立。",
      facilitati_p7:
        "促进会员公司之间以及与罗马尼亚和中华人民共和国的其他经济实体之间的合作和合作关系的建立。",
      facilitati_p8:
        "在罗马尼亚的行政-地方单位面前推广公司的投资项目和公私合作倡议。",
      facilitati_p9:
        "促进公司代表参加CCIRC自己的活动或与之合作组织的活动，以及邀请参加的活动或活动，旨在发展公司的公共和商业关系。",
      facilitati_p10:
        "促进公司获得邀请并参加对公司的双边经济关系感兴趣的罗马尼亚和中华人民共和国的公共或私人活动。",
      facilitati_p11: "论坛、会议、大会、研讨会、研讨会等。",
      facilitati_p12:
        "为公司在罗马尼亚或中华人民共和国组织自己的活动提供支持，确保中央和地方政府、机构或经济、商会、雇主和工会结构的代表，以及中华人民共和国的中国商业社区的参与。",
      facilitati_p13:
        "组织或促进公司代表或员工参加介绍罗马尼亚或中华人民共和国的商业环境和经济和商业立法以及双边关系的谈判和沟通培训课程，以及学习罗马尼亚语和中文的课程。",
      facilitati_p14:
        "促进公司参加在罗马尼亚或中华人民共和国举行的展会、展览会和其他类似的经济和商业活动。",
      facilitati_p15:
        "通知公司代表参加各种培训形式或获得罗马尼亚或中华人民共和国的学习奖学金的机会。",
      imagine_si_publicitate: "形象和广告",
      facilitati_p16:
        "确保公司参加双边商会组织的活动，进行广告宣传以推广其项目、形象和品牌。",
      facilitati_p17:
        "在罗马尼亚或中华人民共和国参加的展会和展览会上推广公司的产品和服务。",
      facilitati_p18:
        "在双边商会活动的广告文件和材料上推广公司的标志，根据约定的条件。",
      facilitati_p19:
        "推广公司形象并在双边商会的印刷出版物和目录中分发与其活动相关的新闻，以及在双边商会的网站或社交媒体页面上。",
      facilitati_p20:
        "授予公司使用双边商会标志的权利，用于公司的形象和推广文件和材料。",
      facilitati_p21:
        "授予公司或其代表荣誉称号，以表彰其在双边活动、项目或经济和商业合作中的活动。",
      suport_pentru_afaceri: "商业支持",
      facilitati_p22: "确定和验证罗马尼亚或中华人民共和国公司的潜在商业伙伴",
      facilitati_p23: "法律、财务和信用分析",
      facilitati_p24:
        "向罗中公司或其在中华人民共和国的合作伙伴通报罗中商会认为对他们可能感兴趣的项目或商业机会。",
      facilitati_p25:
        "为公司代表与罗马尼亚或中华人民共和国的公司或经济和商业实体举行B2B会议。",
      facilitati_p26:
        "为公司代表在与罗马尼亚或中华人民共和国的经济和商业实体的谈判中提供专业帮助。",
      facilitati_p27:
        "为公司与罗马尼亚或中华人民共和国的合作伙伴联系，以安全和质量地进行进出口商业活动。",
      facilitati_p28: "列在双边商会的商业平台上。",
      facilitati_p29:
        "确保公司访问由双边商会编制的关于罗马尼亚或中华人民共和国的经济和社会发展的数据库、文件研究、分析文件、评估和统计数据。",
      facilitati_p30:
        "向公司提供有关罗马尼亚或中华人民共和国的商业环境、竞争政策和知识产权实践、对商业和投资环境产生影响的立法框架的信息。",
      consultanta_si_servicii: "咨询和服务",
      facilitati_p31:
        "根据合同条件，为公司代表提供业务、法律和财务咨询，以及双边项目发展的其他专业服务。",
      facilitati_p32:
        "为公司提供有关寻找和吸引商业项目资金来源、在罗马尼亚或中华人民共和国建立、组织和运营其商业公司的手续、规范和程序的信息。",
      facilitati_p33:
        "为公司员工或代表准备获得罗马尼亚或中华人民共和国的过境签证、短期或长期停留的特定文件的协助。",
      facilitati_p34:
        "确保公司通过与双边商会与其合作伙伴签订的物流服务合同以优惠价格获得物流服务：国内和国际货运",
      facilitati_p35: "陆、海和空",
      facilitati_p36: "进出口产品的检验和质量控制、报关代理等。",
      facilitati_p37:
        "确保公司通过与双边商会与其合作伙伴签订的旅游援助和商务旅行服务合同以优惠价格获得旅游援助服务：国内和国际客运、酒店服务预订、租车、陪同导游等。",
      facilitati_p38:
        "确保公司通过与双边商会与其合作伙伴签订的协议以优惠价格获得双边商会总部的会议和礼仪室的空间和技术设备。",
      facilitati_p39:
        "为公司提供分析、评估和解决商业纠纷和冲突的专业知识，使其在达到法院之前解决它们，并通过调解、调解和仲裁提供替代解决方法。",
      facilitati_p40:
        "协助公司在罗马尼亚商会和工业商会附属的国际商事仲裁法院前解决商业纠纷。",
      facilitati_p41: "大多数服务对CCIRC会员免费或收取降低费用。",
      consultanta_import: "进出口咨询",
      reprezentare_si_promovare: "代表和推广",
      actiuni_si_evenimente: "活动和活动",
      lista_beneficii_1: "罗马尼亚股东公司的福利和设施清单",
      lista_beneficii_2: "中国股东公司的福利和设施清单",
      lista_beneficii_3: "中国公司或其在罗马尼亚的子公司的福利和设施清单",
      parteneri_strategici: "战略合作伙伴",
      parteneri_servicii: "服务合作伙伴",
      contacteaza_ne: "联系我们",
      ai_intrebari: "有问题吗？您可以使用以下信息找到我们。",
      hotarare_inregistrare: "注册决定",
      certificat_de_inregistrare: "税务登记证书",
      registrul_national: "国家登记",
      nr_regist: "非营利法人的国家登记号",
      banca: "银行",
      adresa: "地址",
      sc_p1: "支持罗马尼亚 - 中国商会的活动的财务方式",
      sc_p2:
        "是一家双边商会，根据罗马尼亚商会法第335/2007号和O.G.第26/2000号关于协会和基金会的规定运作，随后进行了修改和补充。",
      sc_p3:
        "是一家根据法律规定获得法律地位和公共效益的自治、非政府、非政治、非营利组织。",
      sc_p4:
        "双边商会的活动资金来源符合非政府组织的法律规定和其自身章程的规定。",
      sc_p5:
        "支持双边商会的组织和运作是实现和履行罗中商会承担的目的和目标的优先事项，并遵守法律规定的职责。",
      sc_p6: "选项I - 可扣除的会费和注册费",
      sc_p7: "法律框架：法律第227/2015号关于税收法，第25条。第(1)段",
      sc_p8:
        "为了确定税收结果，将用于开展经济活动的支出视为可扣除支出，包括法律规定的支出，以及应向商会、雇主组织和工会缴纳的注册费、会费和捐款。",
      sc_p9: "双边文件：会费合同",
      sc_p10:
        "会费合同是一份由公司和罗中商会签署的双边文件，公司和罗中商会分别承担资助活动的责任，并根据自身章程和商会的特定立法授予利益和便利。",
      sc_p11: "下载模板 - 会费合同",
      sc_p12: "选项II - 可扣除的捐款",
      sc_p13: "法律框架：法律第227/2015号关于税收法，第25条。第(1)段",
      sc_p14:
        "为了确定税收结果，将用于开展经济活动的支出视为可扣除支出，包括法律规定的支出，以及应向商会、雇主组织和工会缴纳的注册费、会费和捐款。",
      sc_p15: "双边文件：捐款合同",
      sc_p16:
        "捐款合同是一份由公司和罗中商会签署的双边文件，公司和罗中商会分别承担资助活动的责任，并根据自身章程和商会的特定立法授予利益和便利。",
      sc_p17: "下载模板 - 捐款合同",
      sc_p18: "选项III - 赞助，从应缴利润税中扣除的低额",
      sc_p19: "法律框架：法律第32/1994号关于赞助",
      sc_p20:
        "赞助是在赞助人和受益人之间达成的合同的基础上进行的活动，双方同意提供作为支持的财务手段和物质物品，以及赞助的持续时间。在由物质物品组成的赞助的情况下，这些物品将根据赞助合同在交付给受益人的时刻的实际价值进行评估。",
      sc_p21: "法律第227/2015号关于税收法和实施规则，第25条第(4)段",
      sc_p22:
        "作为赞助支付的金额从应缴利润税中扣除“[...]在营业额的0.75%和应缴利润税的20%之间的最低值”。",
      sc_p23: "双边文件：赞助合同",
      sc_p24:
        "赞助合同是一份由公司和罗中商会签署的双边文件，公司和罗中商会分别承担资助活动或项目的责任，并将通过免费方式向公众宣传赞助，以推广公司品牌或形象名称。",
      sc_p25: "下载模板 - 赞助合同",
      sc_p26: "选项IV - 重定向纳税人所得税价值的3.5%",
      sc_p27: "法律框架：法律第227/2015号关于税收法，第1231条，第(1)段",
      sc_p28:
        "从工资、养老金、独立活动、知识产权或物品使用中获得收入的纳税人可以决定将收入税的3.5%用于支持根据法律成立和运作的非营利实体[...]。",
      sc_p29: "单边文件：税务表格230或212",
      sc_p30:
        "税务表格230是一份每年填写的文件，用于将3.5%的所得税重定向到RCCI，适用于从工资、养老金和独立活动中获得收入的人。该文件提交给所属的税务管理局（ANAF），通过直接在柜台提交或通过挂号信邮寄。不需要填写金额！",
      sc_p31: "下载模板 - 表格230",
      sc_p32:
        "税务表格212是一份每年填写的文件，用于将3.5%的所得税重定向到RCCI，适用于从实际系统中确定收入的独立活动收入、知识产权收入、投资收入和海外收入的人。该文件提交给税务管理局（ANAF），通过直接在柜台提交或通过挂号信邮寄。",
      sc_p33: "下载模板 - 表格212",
      sc_p34: "RCCI的主要数据",
      sc_p35: "完成合同和表格所需的所有信息。",
      sediu: "总部",
      punct_de_lucru: "分支机构",
      reprezentant_legal: "法定代表人",
      informatii_legale: "法律信息",
      nr_inreg: "注册号",
      registrul_asociatilor: "协会和基金会注册号11966/A/2010",
      informatii_bancare: "银行信息",
    },
  },
};

i18n
  .use(LanguageDetector) // detectează limba setată în browser
  .use(initReactI18next)
  .init({
    resources,
    lng: "ro",
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
