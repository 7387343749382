import React from "react";
import { useTranslation } from "react-i18next";

const facilitati = [
  {
    categorie: "Implicare strategică",
    beneficii: [
      {
        descriere:
          "Atribuie reprezentantului companiei funcția de vicepreședinte al CCIRC și membru în Consiliului Național de Conducere al camerei bilaterale.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă reprezentantului companiei dreptul de a participa la activitățile camerei bilaterale în domeniul de activitate al companiei, dezvoltate în cadrul departamentului intern specializat al CCIRC.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează serviciile sau produsele companiei, inclusiv ofertele speciale ale acesteia către membrii camerei bilaterale, direct sau prin intermediului sistemului M2M.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează companiei contactele și dialogurile cu reprezentanții misiunilor diplomatice ale României din R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Reprezentare și relații publice",
    beneficii: [
      {
        descriere:
          "Facilitează contactele companiei cu instituțiile administrației publice centrale și locale, precum și cu organismele și organizațiile din România sau UE.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează întâlnirile, dialogurile și vizitele de prezentare ale delegațiilor companiei cu reprezentanții autorităților, instituțiilor sau structurile asociative economice, camerale, patronale și sindicale, la nivel central și local din România.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează proiectele de investiții și inițiativele de tip parteneriat public - privat ale companiei în fața unităților administrativ teritoriale din România.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură participarea reprezentanților companiei la evenimentele camerei bilaterale, proprii sau organizate în parteneriat, precum și la evenimentele sau activitățile la care aceasta este invitată să participe, în România, cu scopul dezvoltării relațiilor publice și comerciale ale companiei.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează obținerea de invitații și accesul la evenimente publice sau private din România, care prezintă interes pentru dezvoltarea relațiilor economice bilaterale ale companiei (forumuri, conferințe, congrese, seminarii, work-shopuri s.a.).",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează accesul și participarea companiei la târguri, expoziții și alte asemenea evenimente economice și comerciale care au loc în România sau în UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei suport pentru organizarea evenimentelor proprii, în România, prin asigurarea participării în cadrul acestora a reprezentanților autorităților, instituțiilor sau structurile asociative economice, camerale, patronale și sindicale, la nivel central și local din România.",
        destinatari: ["Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Suport de afaceri",
    beneficii: [
      {
        descriere:
          "Identifică și verifică potențialii parteneri de afaceri ai companiei din România (analize juridice,  financiare și de bonitate).",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Prezintă companiei proiecte sau oportunități de afaceri comunicate camerei bilaterale de către companiile românești sau de alte entități publice sau private din România, care pot fi considerate ca fiind de interes pentru aceasta.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Prezintă proiectele sau oportunitățile de afaceri ale companiei, potențialilor parteneri de afaceri români sau colaboratorilor camerei bilaterale din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Realizează pentru companie, în condiții contractuale, studii de piață cu privire la diferite produse și servicii pe domenii de activitate, analiza concurenței și a furnizorilor.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură consultanță și asistență pentru înființarea de societăți, filiale, sucursale sau reprezentanțe ale companiei în România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Organizează întâlniri de tip B2B pentru reprezentanții companiei cu companii sau entități economice și comerciale din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei, în condiții contractuale, consultanță de afaceri, juridică și financiară, precum și alte servicii specifice dezvoltării proiectelor bilaterale.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă asistență de specialitate la negocierile reprezentanților companiei cu entități economice și comerciale din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează contactele companiei cu parteneri din România pentru derularea în condiții de siguranță și calitate a operațiunilor specifice activităților comerciale de import - export pe piața europeană.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Organizează sau facilitează înscrierea și participarea reprezentanților sau personalului companiei la seminarii de prezentare a mediului de afaceri și legislatiei economice și comerciale din România și UE și la cursuri de perfecționare a negocierii și comunicării specifice relațiilor bilaterale cu parteneri români, precum și la cursuri de învățare a limbii române.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Listează pe platforma de business a camerei bilaterale, în cadrul sistemelor de cooperare inter-membri M2M și networking cu partenerii din România oportunitățiile de afaceri sau propunerile de proiecte ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei asistență pentru întocmirea documentelor specifice obținerii vizelor de tranzit, de scurtă sau lungă ședere, precum și a permiselor de muncă pentru personalul acesteia care urmează să se deplaseze sau să se stabilească în România.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei consultanță și asistență pentru achiziția sau închirierea de active imobiliare și bunuri în România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei la serviciile de logistică contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de marfă (terestru, naval și aerian), inspecție și control al produselor importate sau exportate, comisionari vamali s.a.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei la serviciile de asistență turistică și călătorii de afaceri contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de persoane, rezervări servicii hoteliere, rent-a-car, ghizi însoțitori s.a.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei în spațiile și sălile de sedință și protocol, precum și la dotările tehnice din sediul central al camerei bilaterale pentru organizarea întâlnirilor și evenimentelor proprii.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Informare și documentare",
    beneficii: [
      {
        descriere:
          "Asigură accesul companiei la baza de date, studiile documentare, documentele de analiză, evaluări și statistici elaborate de camera bilaterală, referitoare la evoluția economică și socială din România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Furnizează informații cu privire la mediul de afaceri, politicile de concurență și practicile de proprietate intelectuală din România și UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Transmite companiei informații actualizate privind cadrul legislativ cu incidentă asupra mediului de afaceri și investițional din România și UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania cu privire la facilitățile și avantajele oferite de România pentru investițiile străine realizate pe teritoriul său.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei informații privind identificarea și atragerea surselor de finanțare pentru proiectele de afaceri ale acestora din România și UE.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania cu privire la formalitățile, normele și procedurile privind înființarea, organizarea și funcționarea societăților comerciale în România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania despre oportunitățile de participare a reprezentanților companiei la diverse forme de training sau la accesarea de burse de studii în România.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Imagine și promovare",
    beneficii: [
      {
        descriere:
          "Asigură accesul companiei la evenimentele organizate de camera bilaterală pentru realizarea unor acțiuni sau campanii publicitare de promovare a proiectelor, imaginii și a brand-urilor acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează produsele și serviciile companiei în cadrul standurilor organizate de camera bilaterală la târgurile și expozițiile la care este prezentă, în România sau în R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează logo-urile companiei pe documentele și materialele de publicitate ale evenimentelor camerei bilaterale, în condițiile convenite de comun acord.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează imaginea companiei și distribuie știrile care privesc activitatea acesteia în publicațiile și cataloagele tipărite, precum și pe web-siteul sau pe paginile de socializare ale camerei bilaterale.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă dreptul de a utiliza logo-ul camerei bilaterale pe documentele și materialele de imagine și promovare ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă distincții și titluri onorifice companiei sau reprezentanților acesteia, pentru activitatea, proiectele sau parteneriatele economice și comerciale cu caracter bilateral.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
];

const facilitatiInEnglish = [
  {
    categorie: "Strategic involvement",
    beneficii: [
      {
        descriere:
          "Assigns the company's representative the function of vice-president of CCIRC and member in the National Management Council of the bilateral chamber.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Grants the company's representative the right to participate in the activities of the bilateral chamber in the company's field of activity, developed within the specialized internal department of CCIRC.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's services or products, including its special offers to the members of the bilateral chamber, directly or through the M2M system.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's contacts and dialogues with the representatives of the Romanian diplomatic missions in the People's Republic of China.",
        destinatari: ["Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Representation and public relations",
    beneficii: [
      {
        descriere:
          "Facilitates the company's contacts with the central and local public administration institutions, as well as with the bodies and organizations in Romania or the EU.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the meetings, dialogues, and presentation visits of the company's delegations with the representatives of the authorities, institutions, or economic, chamber, employer, and trade union structures, at the central and local level in Romania.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's investment projects and public-private partnership initiatives in front of the territorial administrative units in Romania.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Ensures the participation of the company's representatives in the events of the bilateral chamber, its own or organized in partnership, as well as in the events or activities to which it is invited to participate, in Romania, in order to develop the company's public and commercial relations.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's access to and participation in public or private events in Romania that are of interest to the development of the company's bilateral economic relations (forums, conferences, congresses, seminars, workshops, etc.).",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's access to fairs, exhibitions, and other similar economic and commercial events taking place in Romania or the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with support for organizing its own events in Romania by ensuring the participation of the representatives of the authorities, institutions, or economic, chamber, employer, and trade union structures, at the central and local level in Romania.",
        destinatari: ["Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Business support",
    beneficii: [
      {
        descriere:
          "Identifies and verifies the company's potential business partners in Romania (legal, financial, and creditworthiness analyses).",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Presents the company with business projects or opportunities communicated to the bilateral chamber by Romanian companies or other public or private entities in Romania that may be of interest to it.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Presents the company's business projects or opportunities to potential Romanian business partners or the bilateral chamber's collaborators in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Carries out market studies for the company, under contractual conditions, regarding various products and services in the fields of activity, competition analysis, and suppliers.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company, under contractual conditions, with business, legal, and financial consultancy, as well as other services specific to the development of bilateral projects.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Provides specialized assistance in the negotiations of the company's representatives with economic and commercial entities in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's contacts with Romanian partners for the safe and quality conduct of import-export operations on the European market.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Organizes B2B meetings for the company's representatives with companies or economic and commercial entities in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with assistance in setting up companies, branches, subsidiaries, or representations in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's access to the logistic services contracted by the bilateral chamber with its partners, at preferential rates: internal and international freight transport (land, sea, and air), inspection and control of imported or exported products, customs brokers, etc.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's access to the tourist assistance and business travel services contracted by the bilateral chamber with its partners, at preferential rates: internal and international passenger transport, hotel service reservations, rent-a-car, accompanying guides, etc.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's access to the meeting and protocol rooms, as well as the technical facilities in the central headquarters of the bilateral chamber for organizing its own meetings and events.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with assistance in preparing the specific documents for obtaining transit visas, short or long-stay visas, as well as work permits for its personnel who are to travel or settle in Romania.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with consultancy and assistance in acquiring or renting real estate and goods in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Organizes or facilitates the registration and participation of the company's representatives or staff in seminars on presenting the business environment and economic and commercial legislation in Romania and the EU, as well as in courses on improving negotiation and communication skills specific to bilateral relations with Romanian partners, as well as in Romanian language learning courses.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Lists on the bilateral chamber's business platform, within the M2M inter-member cooperation and networking systems with partners in Romania, the company's business opportunities or project proposals.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Information and documentation",
    beneficii: [
      {
        descriere:
          "Ensures the company's access to the database, documentary studies, analysis documents, evaluations, and statistics elaborated by the bilateral chamber, regarding the economic and social evolution in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides information on the business environment, competition policies, and intellectual property practices in Romania and the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Transmits information to the company regarding the legislative framework affecting the business and investment environment in Romania and the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the facilities and advantages offered by Romania for foreign investments made on its territory.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with information on identifying and attracting sources of funding for its business projects in Romania and the EU.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the formalities, rules, and procedures for setting up, organizing, and operating commercial companies in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the opportunities for its representatives to participate in various training forms or access scholarships in Romania.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Image and promotion",
    beneficii: [
      {
        descriere:
          "Ensures the company's access to the events organized by the bilateral chamber to carry out advertising campaigns to promote its projects, image, and brands.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's products and services within the stands organized by the bilateral chamber at the fairs and exhibitions where it is present, in Romania or the People's Republic of China.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's logos on the advertising documents and materials of the bilateral chamber's events, under the agreed conditions.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's image and distributes the news related to its activity in the printed publications and catalogs, as well as on the website or social media pages of the bilateral chamber.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Grants the right to use the bilateral chamber's logo on the company's image and promotional documents.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Grants distinctions and honorary titles to the company or its representatives for their activity, projects, or economic and commercial partnerships with a bilateral character.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
];

const facilitatiInChinese = [
  {
    categorie: "战略参与",
    beneficii: [
      {
        descriere:
          "将公司代表任命为CCIRC的副主席，并成为双边商会的全国管理委员会成员。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "赋予公司代表参与双边商会在公司活动领域内开展的活动的权利，这些活动在CCIRC的专业内部部门中开展。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "直接或通过M2M系统向双边商会的成员推广公司的服务或产品，包括其特别优惠。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司与罗马尼亚驻中华人民共和国外交使团的代表之间的联系和对话。",
        destinatari: ["战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "代表和公共关系",
    beneficii: [
      {
        descriere:
          "促进公司与罗马尼亚中央和地方公共行政机构以及罗马尼亚或欧盟的机构和组织的联系。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司代表与罗马尼亚中央和地方层面的当局、机构或经济、商会、雇主和工会结构的代表之间的会议、对话和访问。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在罗马尼亚地方行政单位面前推广公司的投资项目和公私合作伙伴关系倡议。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "确保公司代表参加双边商会的活动，无论是自己组织的还是与合作伙伴组织的，以及邀请公司参加的活动或活动，目的是发展公司的公共和商业关系。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司参与并参与在罗马尼亚举行的对公司双边经济关系发展有利的各种形式的培训或访问奖学金。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "促进公司参与在罗马尼亚或欧盟举行的对公司双边经济关系发展有利的各种形式的培训或访问奖学金。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "通过协商确定的条件，在双边商会的活动文件和材料上推广公司的标志。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司的形象和在双边商会印刷出版物和目录以及网站或社交媒体页面上的活动与公司活动相关的新闻。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere: "授予公司使用双边商会标志的权利，用于公司的形象和推广文件。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "授予公司或其代表的荣誉称号，以表彰其在双边经济和商业合作中的活动、项目或合作伙伴关系。",
        destinatari: ["成员", "战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "商业支持",
    beneficii: [
      {
        descriere:
          "确定并验证公司在罗马尼亚的潜在商业伙伴（法律、财务和信用分析）。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "向公司提供由罗马尼亚公司或其他罗马尼亚公共或私人实体向双边商会通报的可能对其有利的商业项目或机会。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向潜在的罗马尼亚商业伙伴或双边商会在罗马尼亚的合作伙伴推广公司的商业项目或机会。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "根据合同条件，为公司进行市场研究，涉及各种产品和服务领域的产品和服务、竞争分析和供应商。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "根据合同条件，为公司提供商业、法律和财务咨询，以及其他特定于双边项目发展的服务。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere: "为公司代表与罗马尼亚经济和商业实体的谈判提供专业协助。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "促进公司与罗马尼亚合作伙伴的联系，以确保公司在欧洲市场上的进出口业务的安全和质量。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供参加由双边商会与其合作伙伴签订的物流服务的机会，以优惠价格：国内和国际货物运输（陆地、海洋和空运）、进口或出口产品的检验和控制、海关代理等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供参加由双边商会与其合作伙伴签订的旅游援助和商务旅行服务的机会，以优惠价格：国内和国际客运、酒店服务预订、汽车租赁、陪同导游等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供参加由双边商会与其合作伙伴签订的旅游援助和商务旅行服务的机会，以优惠价格：国内和国际客运、酒店服务预订、汽车租赁、陪同导游等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供参加由双边商会与其合作伙伴签订的旅游援助和商务旅行服务的机会，以优惠价格：国内和国际客运、酒店服务预订、汽车租赁、陪同导游等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供参加由双边商会与其合作伙伴签订的旅游援助和商务旅行服务的机会，以优惠价格：国内和国际客运、酒店服务预订、汽车租赁、陪同导游等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供参加由双边商会与其合作伙伴签订的旅游援助和商务旅行服务的机会，以优惠价格：国内和国际客运、酒店服务预订、汽车租赁、陪同导游等。",
        destinatari: ["成员", "战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "信息和文档",
    beneficii: [
      {
        descriere:
          "确保公司访问由双边商会编制的关于罗马尼亚经济和社会发展的数据库、文献研究、分析文件、评估和统计数据。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "提供有关罗马尼亚和欧盟的商业环境、竞争政策和知识产权实践的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司提供有关影响罗马尼亚和欧盟商业和投资环境的立法框架的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "通知公司有关罗马尼亚为在其领土上进行外国投资所提供的便利和优势。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司的业务项目在罗马尼亚和欧盟的资金来源的识别和吸引提供信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司提供有关在罗马尼亚设立、组织和运营商业公司的形式、规则和程序的信息。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "通知公司其代表有机会参加罗马尼亚各种培训形式或获得奖学金的机会。",
        destinatari: ["成员", "战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "形象和推广",
    beneficii: [
      {
        descriere:
          "确保公司参加双边商会组织的活动，以开展广告活动，以促进其项目、形象和品牌。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere: "在双边商会在公司参加的展会和展览上推广公司的产品和服务。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会的活动文件和材料上推广公司的标志，条件是双方达成一致。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会的印刷出版物和目录以及网站或社交媒体页面上推广公司的形象和与公司活动相关的新闻。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere: "授予公司使用双边商会标志的权利，用于公司的形象和推广文件。",
        destinatari: ["成员", "战略合作伙伴"],
      },
      {
        descriere:
          "授予公司或其代表的荣誉称号，以表彰其在双边经济和商业合作中的活动、项目或合作伙伴关系。",
        destinatari: ["成员", "战略合作伙伴"],
      },
    ],
  },
];

export default function FacilitatiC() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container mx-auto bg-white rounded-xl p-4">
      <h1 className="text-4xl font-bold text-center mb-8 mt-4">
        {t("lista_beneficii_3")}
      </h1>
      {i18n.language === "ro" && (
        <>
          {facilitati.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {i18n.language === "en" && (
        <>
          {facilitatiInEnglish.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {i18n.language === "ch" && (
        <>
          {facilitatiInChinese.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
