import React from "react";
import SchimbValutar from "./SchimbValutar";
import WeatherTimeApp from "./WeatherTimeApp";
import LinkInstitutii from "./LinkInstitutii";
import ParteneriServicii from "./ParteneriServicii";

export default function ColoanaStanga() {
  return (
    <div>
      <WeatherTimeApp />
      <SchimbValutar />
      <LinkInstitutii />
      <ParteneriServicii />
    </div>
  );
}
