import React from "react";
import { Link } from "react-router-dom";

export default function AccessDenied() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Icon de lacăt */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-24 text-red-500"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>

      {/* Mesaj 403 */}
      <h1 className="text-6xl font-bold text-gray-800 mt-4">403</h1>
      <p className="text-xl text-center text-gray-600 mt-2">
        Acces interzis! Nu ai permisiunea de a vizita această pagină.
      </p>

      {/* Link către pagina principală */}
      <Link
        to="/"
        className="mt-6 bg-red-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-red-500"
      >
        Înapoi la pagina principală
      </Link>
    </div>
  );
}
