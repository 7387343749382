import React from "react";
import { useTranslation } from "react-i18next";

export default function NewProfileCard() {
  const { t, i18n } = useTranslation();

  return (
    <div className="sm:grid sm:grid-cols-2 gap-1">
      <div className="flex bg-white rounded-xl mb-2 sm:mb-0 shadow-lg p-4 gap-4">
        <img
          src="nicolae_vasilescu.jpg"
          alt="Avatar"
          width={100}
          className="object-cover rounded-md"
        />
        <div>
          <h1
            className={`text-md font-bold text-gray-800 ${
              i18n.language === "ch" ? "text-[13px]" : "text-md"
            }`}
          >
            {t("ing_jur_nicolae_vasilescu")}
          </h1>
          <h2 className="text-md text-gray-500">{t("presedinte_ccirc")}</h2>
          <p className="text-gray-700 text-sm line-clamp-3 overflow-hidden mt-2">
            {t("descriere_nicolae_vasilescu")}
          </p>
          <a
            href="https://ro.wikipedia.org/wiki/Nicolae_Vasilescu"
            className="text-red-500 font-semibold hover:text-red-600 w-fit flex items-center mt-1 gap-1 translate-on-hover"
          >
            {t("citeste_mai_mult")}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="size-5 transform duration-300"
            >
              <path
                fillRule="evenodd"
                d="M2 10a.75.75 0 0 1 .75-.75h12.59l-2.1-1.95a.75.75 0 1 1 1.02-1.1l3.5 3.25a.75.75 0 0 1 0 1.1l-3.5 3.25a.75.75 0 1 1-1.02-1.1l2.1-1.95H2.75A.75.75 0 0 1 2 10Z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="flex bg-white rounded-xl shadow-lg p-4 gap-4">
        <div>
          <h1
            className={`text-md font-bold text-gray-800 ${
              i18n.language === "ch" ? "text-[13px]" : "text-md"
            }`}
          >
            {t("amb_viorel_isticioaia_budura")}
          </h1>
          <h2 className="text-md text-gray-500 text-end">
            {t("presedinte_de_onoare_ccirc")}
          </h2>
          <p className="text-gray-700 text-sm line-clamp-3 overflow-hidden mt-2 text-end text-ellipsis">
            {t("descriere_viorel_isticioaia")}
          </p>
          <div className="flex justify-end">
            <a
              href="https://ro.wikipedia.org/wiki/Viorel_Isticioaia-Budura"
              className="text-red-500 font-semibold hover:text-red-600 flex items-center mt-1 gap-1 translate-on-hover"
            >
              {t("citeste_mai_mult")}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="size-5 transform duration-300"
              >
                <path
                  fillRule="evenodd"
                  d="M2 10a.75.75 0 0 1 .75-.75h12.59l-2.1-1.95a.75.75 0 1 1 1.02-1.1l3.5 3.25a.75.75 0 0 1 0 1.1l-3.5 3.25a.75.75 0 1 1-1.02-1.1l2.1-1.95H2.75A.75.75 0 0 1 2 10Z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
        <img
          src="isticioaia.png"
          alt="Avatar"
          width={100}
          className="object-cover rounded-md"
        />
      </div>
    </div>
  );
}
