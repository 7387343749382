import React from "react";
import { useTranslation } from "react-i18next";

export default function FilialeRO() {
  const { i18n } = useTranslation();
  return (
    <div className="bg-white rounded-lg pt-4 pb-12">
      {i18n.language === "ro" && (
        <>
          <img src="/filialeRO.png" alt="Filiale RO" />
          <img src="/filialeRO2.png" alt="Filiale RO" />
        </>
      )}
      {i18n.language === "en" && (
        <>
          <img src="/filialeROEN.png" alt="Filiale RO" />
          <img src="/filialeROEN2.png" alt="Filiale RO" />
        </>
      )}
      {i18n.language === "ch" && (
        <>
          <img src="/filialeROCH.png" alt="Filiale RO" />
          <img src="/filialeROCH2.png" alt="Filiale RO" />
        </>
      )}
    </div>
  );
}
