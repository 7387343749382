import React from "react";
import { useTranslation } from "react-i18next";

const facilitati = [
  {
    categorie: "Implicare strategică",
    beneficii: [
      {
        descriere:
          "Atribuie reprezentantului companiei funcția de vicepreședinte al CCIRC și membru în Consiliul Național de Conducere al camerei bilaterale.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă reprezentantului companiei coordonarea activităților camerei bilaterale în domeniul de activitate al companiei, dezvoltate în cadrul departamentului intern specializat al CCIRC.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură participarea companiei la elaborarea procedurilor CCIRC de selectare și propunere a oportunităților și proiectelor de afaceri  bilaterale în domenii de interes pentru activitatea acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Oferă companiei posibilitatea de a participă la elaborarea propunerilor cameriei bilaterale de modificare și completare a legislației naționale în domenii de interes pentru activitatea companiei.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Cooptează reprezentanții companiei în delegațiile camerei bilaterale care participă la întâlniri oficiale în România sau în cadrul misiunilor economice ale autorităților române în R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează serviciile sau produsele companiei la nivelul comunității de afaceri chineze din România și în cadrul întâlnirilor cu delegațiile companiilor chineze care prospectează prezența lor în tara noastră cu scopul derulării unor proiecte de investiții pe termen lung.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează companiei contactele și dialogurile cu reprezentanții Ambasadei R.P. Chineze la București și legăturile cu misiunile diplomatice ale României din R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează serviciile sau produsele companiei, inclusiv ofertele speciale ale acesteia către membrii camerei bilaterale, direct sau prin intermediului sistemului M2M.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Reprezentare și relații publice",
    beneficii: [
      {
        descriere:
          "Susține și reprezintă interesele generale ale companiei în relația cu instituțiile administrației publice centrale și locale, precum și în fața organismelor și organizațiilor naționale din care face parte camera bilaterală.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Apără interesele strategice ale companiei în cadrul mediului de afaceri și în fața autorităților, inclusiv prin susținerea campaniilor de advocacy ale acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează întâlnirile, dialogurile și vizitele de prezentare ale companiei cu reprezentanții autorităților, instituțiilor sau cu structurile asociative economice, camerale, patronale și sindicale, la nivel central și local în România și R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează proiectele de investiții și inițiativele de tip parteneriat public - privat ale companiei în fața unităților administrativ teritoriale din România.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează participarea reprezentanților companiei la evenimentele camerei bilaterale, proprii sau organizate în parteneriat, precum și la evenimentele sau activitățile la care aceasta este invitată să participe, în România sau în R.P. Chineză, cu scopul dezvoltării relațiilor publice și comerciale ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează obținerea de invitații și accesul la evenimente publice sau private din România și R.P. Chineză, care prezintă interes pentru dezvoltarea relațiilor economice bilaterale ale companiei (forumuri, conferințe, congrese, seminarii, work-shopuri s.a.).",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează accesul și participarea companiei la târguri, expoziții și alte asemenea evenimente economice și comerciale care au loc în România sau R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei suport pentru organizarea evenimentelor proprii, în România sau în R.P. Chineză, prin asigurarea participării în cadrul acestora a reprezentanților autorităților, instituțiilor sau structurile asociative economice, camerale, patronale și sindicale, la nivel central și local din țară și din R.P. Chineză, precum și ai comunității de afaceri chineze  din România.",
        destinatari: ["Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Suport de afaceri",
    beneficii: [
      {
        descriere:
          "Identifică și verifică potențialii parteneri de afaceri ai companiei din R.P. Chineză (analize juridice,  financiare și de bonitate).",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Prezintă companiei proiecte sau oportunități de afaceri  comunicate camerei bilaterale de către companiile chineze sau de partenerii săi din R.P. Chineză, care pot fi considerate ca fiind de interes pentru  aceasta.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Prezintă proiectele sau oportunitățile de afaceri  ale companiei, potențialilor parteneri de afaceri chinezi sau colaboratorilor camerei bilaterale din R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Organizează întâlniri de tip B2B pentru reprezentanții companiei cu companii sau entități economice și comerciale din R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei, în condiții contractuale, consultanță de afaceri, juridică și financiară, precum și alte servicii specifice dezvoltării proiectelor  bilaterale.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă asistență de specialitate la negocierile reprezentanților companiei cu entități economice și comerciale din R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Facilitează contactele companiei cu parteneri din România sau R.P. Chineză pentru derularea în condiții de siguranță și calitate a operațiunilor specifice activităților comerciale de import - export.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Organizează sau facilitează înscrierea și participarea reprezentanților sau personalului companiei la seminarii de prezentare  a mediului de afaceri și legislatiei economice și comerciale din R.P. Chineză și la cursuri de perfecționare a negocierii și comunicării specifice relațiilor bilaterale cu parteneri chinezi, precum și la cursuri de învățare a limbii chineze.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Listează pe platforma de business a camerei bilaterale, în cadrul sistemelor de cooperare inter-membri M2M și networking cu partenerii din România și R.P. Chineză oportunitățiile de afaceri sau propunerile de proiecte ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă companiei asistență pentru întocmirea documentelor specifice obținerii vizelor de tranzit, de scurtă sau lungă ședere în R.P. Chineză, pentru personalul sau reprezentanții acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei la serviciile de logistică contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de marfă (terestru, naval și aerian), inspecție și control al produselor importate sau exportate, comisionari vamali s.a.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei la serviciile de asistență turistică și călătorii de afaceri contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de persoane, rezervări servicii hoteliere, rent-a-car, ghizi însoțitori s.a.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură accesul companiei în spațiile și sălile de ședință și protocol, precum și la dotările tehnice din sediul central al camerei bilaterale pentru organizarea întâlnirilor și evenimentelor proprii",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asigură companiei expertiză pentru analizarea, evaluarea și soluționarea disputelor și conflictelor comerciale cu parteneri din R.P. Chineză, înainte ca acestea să ajungă în instanță și oferă metode alternative de soluționare a acestora prin mediere, conciliere și arbitraj.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Asistă compania în fața Curții de Arbitraj Comercial Internațional de pe lângă Camera de Comerț și Industrie a României pentru soluționarea  litigiilor comerciale.",
        destinatari: ["Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Informare și documentare",
    beneficii: [
      {
        descriere:
          "Asigură accesul companiei la baza de date, studiile documentare, documentele de analiză, evaluari și statistici elaborate de camera bilaterală, referitoare la evoluția economică și socială din R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Furnizează informații cu privire la mediul de afaceri, politicile de concurență și practicile de  proprietate intelectuală din R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Transmite companiei informații actualizate privind cadrul legislativ cu incidență asupra mediului de afaceri și investițional din R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă  companiei informații privind identificarea și atragerea surselor de finanțare pentru proiectele de afaceri ale acestora din R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania cu privire la formalitățile, normele și procedurile privind înființarea, organizarea și funcționarea societăților comerciale în R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Informează compania despre oportunitățile de participare a reprezentanților companiei la diverse forme de training sau la accesarea de burse de studii în R.P. Chineză.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
  {
    categorie: "Imagine și promovare",
    beneficii: [
      {
        descriere:
          "Asigură accesul companiei la evenimentele organizate de camera bilaterală pentru realizarea unor acțiuni sau campanii publicitare de promovare a proiectelor, imaginii și a brand-urilor acesteia.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează produsele și serviciile companiei în cadrul standurilor organizate de camera bilaterală la târgurile și expozițiile la care este prezentă, în România sau în R.P. Chineză.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează logo-urile companiei pe documentele și materialele de publicitate ale evenimentelor camerei bilaterale, în condițiile convenite de comun acord.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Promovează imaginea companiei și distribuie știrile care privesc activitatea acesteia în publicațiile și cataloagele tipărite, precum și pe web-siteul sau pe paginile de socializare ale camerei bilaterale.",
        destinatari: ["Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă dreptul de a utiliza logo-ul camerei bilaterale pe documentele și materialele de imagine și promovare ale companiei.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
      {
        descriere:
          "Acordă distincții și titluri onorifice companiei sau reprezentanților acesteia, pentru activitatea, proiectele sau parteneriatele economice și comerciale cu caracter bilateral.",
        destinatari: ["Membri", "Parteneri Strategici"],
      },
    ],
  },
];

const facilitatiInEnglish = [
  {
    categorie: "Strategic involvement",
    beneficii: [
      {
        descriere:
          "Assign the company's representative the position of vice-president of CCIRC and member of the National Steering Council of the bilateral chamber.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Assign the company's representative the coordination of the bilateral chamber's activities in the company's field of activity, developed within the CCIRC's specialized internal department.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Ensure the company's participation in the elaboration of CCIRC procedures for selecting and proposing business opportunities and bilateral projects in areas of interest to its activity.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Offer the company the opportunity to participate in the elaboration of the bilateral chamber's proposals for amending and supplementing national legislation in areas of interest to the company's activity.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Co-opt the company's representatives in the bilateral chamber's delegations that participate in official meetings in Romania or in the economic missions of the Romanian authorities in the P.R. China.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promote the company's services or products at the level of the Chinese business community in Romania and in meetings with delegations of Chinese companies that are prospecting their presence in our country with the aim of carrying out long-term investment projects.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitate the company's contacts and dialogues with the representatives of the P.R. Chinese Embassy in Bucharest and the links with the Romanian diplomatic missions in the P.R. China.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promote the company's services or products, including its special offers, to the members of the bilateral chamber, directly or through the M2M system.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Representation and public relations",
    beneficii: [
      {
        descriere:
          "Supports and represents the company's general interests in relation to the central and local public administration institutions, as well as in front of the national bodies and organizations to which the bilateral chamber belongs.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Defends the company's strategic interests within the business environment and in front of the authorities, including by supporting its advocacy campaigns.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's meetings, dialogues and presentation visits with the representatives of the authorities, institutions or with the economic, commercial, chamber, employer and trade union associative structures, at central and local level in Romania and the P.R. China.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's investment projects and public-private partnership initiatives in front of the administrative-territorial units in Romania.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the participation of the company's representatives in the bilateral chamber's events, whether its own or organized in partnership, as well as in the events or activities to which it is invited to participate, in Romania or in the P.R. China, with the aim of developing the company's public and commercial relations.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates obtaining invitations and access to public or private events in Romania and the P.R. China that are of interest to the development of the company's bilateral economic relations (forums, conferences, congresses, seminars, workshops, etc.).",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's access and participation in fairs, exhibitions and other such economic and commercial events that take place in Romania or the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with support for organizing its own events, in Romania or in the P.R. China, by ensuring the participation of the representatives of the authorities, institutions or the economic, commercial, chamber, employer and trade union associative structures, at central and local level from the country and the P.R. China, as well as the Chinese business community in Romania.",
        destinatari: ["Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Business support",
    beneficii: [
      {
        descriere:
          "Identifies and verifies the company's potential business partners from the P.R. China (legal, financial and creditworthiness analyzes).",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Presents the company with projects or business opportunities communicated to the bilateral chamber by Chinese companies or its partners from the P.R. China, which may be considered of interest to it.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Presents the company's projects or business opportunities to potential Chinese business partners or the bilateral chamber's collaborators from the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Organizes B2B meetings for the company's representatives with companies or economic and commercial entities from the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company, under contractual conditions, with business, legal and financial consultancy, as well as other specific services for the development of bilateral projects.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides specialized assistance to the company's representatives in their negotiations with economic and commercial entities from the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Facilitates the company's contacts with partners from Romania or the P.R. China for the safe and quality conduct of import-export commercial activities.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Organizes or facilitates the registration and participation of the company's representatives or personnel in seminars presenting the business environment and economic and commercial legislation in the P.R. China and in courses for improving negotiation and communication skills specific to bilateral relations with Chinese partners, as well as in courses for learning the Chinese language.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Lists on the bilateral chamber's business platform, within the M2M inter-member cooperation systems and networking with partners from Romania and the P.R. China, the company's business opportunities or project proposals.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with assistance in preparing the specific documents for obtaining transit visas, short or long stay visas in the P.R. China, for its personnel or representatives.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Ensures the company's access to the logistics services contracted by the bilateral chamber with its partners, at preferential rates: internal and international freight transport (land, sea and air), inspection and control of imported or exported products, customs brokers, etc.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Ensures the company's access to the tourist assistance and business travel services contracted by the bilateral chamber with its partners, at preferential rates: internal and international passenger transport, hotel service reservations, rent-a-car, accompanying guides, etc.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Ensures the company's access to the meeting and protocol rooms and technical facilities in the central headquarters of the bilateral chamber for organizing its own meetings and events.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with expertise for analyzing, evaluating and resolving commercial disputes with partners from the P.R. China, before they reach the court, and offers alternative methods of resolving them through mediation, conciliation and arbitration.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Assists the company in front of the International Commercial Arbitration Court attached to the Chamber of Commerce and Industry of Romania to resolve commercial disputes.",
        destinatari: ["Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Information and documentation",
    beneficii: [
      {
        descriere:
          "Ensures the company's access to the database, documentary studies, analysis documents, evaluations and statistics elaborated by the bilateral chamber, regarding the economic and social evolution in the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides information on the business environment, competition policies and intellectual property practices in the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Transmits to the company information on the legislative framework affecting the business and investment environment in the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Provides the company with information on identifying and attracting sources of financing for its business projects in the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the formalities, rules and procedures for establishing, organizing and operating commercial companies in the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Informs the company about the opportunities for its representatives to participate in various forms of training or access scholarships in the P.R. China.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
  {
    categorie: "Image and promotion",
    beneficii: [
      {
        descriere:
          "Ensures the company's access to the events organized by the bilateral chamber to carry out advertising campaigns to promote its projects, image and brands.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's products and services within the stands organized by the bilateral chamber at the fairs and exhibitions where it is present, in Romania or in the P.R. China.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's logos on the advertising documents and materials of the bilateral chamber's events, under the conditions agreed upon.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Promotes the company's image and distributes the news related to its activity in the printed publications and catalogs, as well as on the website or social media pages of the bilateral chamber.",
        destinatari: ["Strategic Partners"],
      },
      {
        descriere:
          "Grants the right to use the bilateral chamber's logo on the company's image and promotion documents and materials.",
        destinatari: ["Members", "Strategic Partners"],
      },
      {
        descriere:
          "Grants distinctions and honorary titles to the company or its representatives, for their activity, projects or economic and commercial partnerships with a bilateral character.",
        destinatari: ["Members", "Strategic Partners"],
      },
    ],
  },
];

const facilitatiInChinese = [
  {
    categorie: "战略参与",
    beneficii: [
      {
        descriere:
          "将公司代表任命为CCIRC的副主席和双边商会的国家领导委员会成员。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "将公司代表任命为双边商会在公司活动领域内的协调员，该领域在CCIRC的专业内部部门中开展。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "确保公司参与CCIRC制定的选择和提议业务机会和双边项目的程序，这些程序对其活动领域感兴趣。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供参与制定双边商会的提议，以修改和补充对公司活动领域感兴趣的国家立法。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "将公司代表并入双边商会的代表团，该代表团参加在罗马尼亚或中华人民共和国的罗马尼亚当局的经济任务中举行的正式会议。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在罗马尼亚的中国商业社区和与罗马尼亚的中国公司代表进行的会议中推广公司的服务或产品，以期进行长期投资项目。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司与驻布加勒斯特的中华人民共和国大使馆的代表的联系和对中华人民共和国的罗马尼亚外交使团的联系。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会的会员直接或通过M2M系统向公司推广其服务或产品，包括其特别优惠。",
        destinatari: ["会员", "战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "代表和公共关系",
    beneficii: [
      {
        descriere:
          "支持和代表公司在与中央和地方公共行政机构的关系中的一般利益，以及在双边商会所属的国家机构和组织面前。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "在商业环境中和当局面前捍卫公司的战略利益，包括支持其倡导活动。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司与罗马尼亚的中央和地方层面的当局、机构或与经济、商业、商会、雇主和工会联合结构的代表之间的会议、对话和演示访问。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在罗马尼亚的行政领土单位面前促进公司的投资项目和公私伙伴关系倡议。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司代表参加双边商会的活动，无论是自己的活动还是与合作伙伴组织的活动，以及邀请其参加的活动或活动，无论是在罗马尼亚还是在中华人民共和国，目的是发展公司的公共和商业关系。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "促进公司参加对罗马尼亚和中华人民共和国的双边经济关系发展有利的公共或私人活动的邀请和访问（论坛、会议、大会、研讨会、工作坊等）。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "促进公司参与在罗马尼亚或中华人民共和国举行的展览会、展览会和其他经济和商业活动。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "通过确保中央和地方的当局、机构或经济、商业、商会、雇主和工会联合结构的代表参加公司自己的活动，为公司提供支持。",
        destinatari: ["战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "商业支持",
    beneficii: [
      {
        descriere:
          "确定和验证公司在中华人民共和国的潜在商业合作伙伴（法律、财务和信用分析）。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司提供由中国公司或其在中华人民共和国的合作伙伴通过双边商会通知的项目或商业机会，这些项目或商业机会可能对其感兴趣。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "向潜在的中国商业合作伙伴或双边商会的合作伙伴介绍公司的项目或商业机会。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司的代表与中华人民共和国的公司或经济和商业实体举办B2B会议。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "根据合同条件，为公司提供商业、法律和财务咨询，以及其他用于开发双边项目的特定服务。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司的代表在与中华人民共和国的经济和商业实体的谈判中提供专业协助。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "促进公司与罗马尼亚或中华人民共和国的合作伙伴的联系，以安全和质量地进行进出口商业活动。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "通过确保公司代表或人员参加介绍中华人民共和国的商业环境和经济和商业立法的研讨会以及与中国合作伙伴的双边关系的谈判的完善课程，以及学习中文的课程。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会的业务平台上列出公司的业务机会或项目提议，包括与罗马尼亚和中华人民共和国的合作伙伴的M2M系统和网络合作。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供协助，以准备在中华人民共和国获得过境签证、短期或长期停留签证的具体文件，供其人员或代表使用。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "确保公司以优惠价格获得双边商会与合作伙伴签订的物流服务：国内和国际货物运输（陆地、海洋和空运）、进口或出口产品的检验和控制、海关经纪人等。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "确保公司以优惠价格获得双边商会与合作伙伴签订的旅游援助和商务旅行服务：国内和国际客运、酒店服务预订、租车、陪同导游等。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "确保公司在双边商会的中央总部的会议和礼仪室以及技术设施中举办自己的会议和活动。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供专业知识，以分析、评估和解决与中华人民共和国的合作伙伴的商业纠纷，使其在诉讼之前解决，并提供通过调解、调解和仲裁解决的替代方法。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "协助公司在罗马尼亚商会和工业的国际商事仲裁法庭面前解决商业纠纷。",
        destinatari: ["战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "信息和文件",
    beneficii: [
      {
        descriere:
          "确保公司访问由双边商会编制的关于中华人民共和国经济和社会发展的数据库、文献研究、分析文件、评估和统计数据。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "提供有关中华人民共和国商业环境、竞争政策和知识产权实践的信息。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "向公司传达影响中华人民共和国商业和投资环境的立法框架的信息。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "为公司提供有关在中华人民共和国为其业务项目确定和吸引资金来源的信息。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "通知公司有关在中华人民共和国成立、组织和运营商业公司的形式、规则和程序。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "通知公司有关其代表参加各种培训形式或在中华人民共和国获得奖学金的机会。",
        destinatari: ["会员", "战略合作伙伴"],
      },
    ],
  },
  {
    categorie: "形象和推广",
    beneficii: [
      {
        descriere:
          "确保公司参加双边商会组织的活动，以进行广告宣传活动，以推广其项目、形象和品牌。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在公司参加的展览会和展览会上推广公司的产品和服务，无论是在罗马尼亚还是在中华人民共和国。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会活动的广告文件和材料上推广公司的标志，以约定的条件。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "在双边商会的印刷出版物和目录以及网站或社交媒体页面上推广公司的形象和分发与其活动相关的新闻。",
        destinatari: ["战略合作伙伴"],
      },
      {
        descriere:
          "授予公司使用双边商会的标志在公司的形象和推广文件和材料上的权利。",
        destinatari: ["会员", "战略合作伙伴"],
      },
      {
        descriere:
          "授予公司或其代表荣誉称号，以表彰其在具有双边性质的活动、项目或经济和商业合作伙伴关系中的活动。",
        destinatari: ["会员", "战略合作伙伴"],
      },
    ],
  },
];

export default function FacilitatiA() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container mx-auto bg-white rounded-xl p-4">
      <h1 className="text-4xl font-bold text-center mb-8 mt-4">
        {t("lista_beneficii_1")}
      </h1>
      {i18n.language === "ro" && (
        <>
          {facilitati.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {i18n.language === "en" && (
        <>
          {facilitatiInEnglish.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {i18n.language === "ch" && (
        <>
          {facilitatiInChinese.map((sectiune, index) => (
            <div key={index} className="mb-4">
              <div className="collapse collapse-plus bg-base-200">
                <input
                  type="checkbox"
                  id={`sectiune-${index}`}
                  className="collapse-toggle"
                />
                <div className="collapse-title text-xl font-medium">
                  {sectiune.categorie}
                </div>
                <div className="collapse-content">
                  <div className="grid grid-cols-1 gap-4">
                    {sectiune.beneficii.map((beneficiu, idx) => (
                      <div
                        key={idx}
                        className="p-4 bg-gray-100 rounded-md shadow-md"
                      >
                        <p className="text-gray-700 text-lg">
                          {beneficiu.descriere}
                        </p>
                        <div className="mt-2">
                          {beneficiu.destinatari.map((dest, i) => (
                            <span
                              key={i}
                              className="inline-block px-2 py-1 mr-2 bg-blue-200 text-blue-800 text-sm rounded"
                            >
                              {dest}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
