import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaHandshake,
  FaBullhorn,
  FaBusinessTime,
  FaGlobe,
  FaChartLine,
} from "react-icons/fa";

const FacilitatiPrezentare = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white text-gray-900 min-h-screen p-10">
      <h1 className="text-4xl font-bold text-center mb-12 text-gray-800">
        {t("facilitati_membri")}
      </h1>

      {/* Reprezentare și Promovare */}
      <section className="mb-16">
        <div className="flex items-center mb-4">
          <FaHandshake className="text-red-500 text-3xl mr-4" />
          <h2 className="text-2xl font-semibold text-gray-800">
            {t("reprezentare_si_promovare")}
          </h2>
        </div>
        <p className="text-lg text-gray-800 mb-4">CCIRC:</p>
        <ul className="list-disc list-inside space-y-2 text-lg ml-8 text-gray-700">
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p1")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p2")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p3")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p4")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p5")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p6")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p7")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p8")}
          </li>
        </ul>
      </section>

      {/* Acțiuni și Evenimente */}
      <section className="mb-16">
        <div className="flex items-center mb-4">
          <FaBullhorn className="text-red-500 text-3xl mr-4" />
          <h2 className="text-2xl font-semibold text-gray-800">
            {t("actiuni_si_evenimente")}
          </h2>
        </div>
        <p className="text-lg text-gray-800 mb-4">CCIRC:</p>
        <ul className="list-disc list-inside space-y-2 text-lg ml-8 text-gray-700">
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p9")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p10")}({t("facilitati_p11")}).
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p12")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p13")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p14")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p15")}
          </li>
        </ul>
      </section>

      {/* Imagine și Publicitate */}
      <section className="mb-16">
        <div className="flex items-center mb-6">
          <FaGlobe className="text-red-500 text-3xl mr-4" />
          <h2 className="text-2xl font-semibold text-gray-800">
            {t("imagine_si_publicitate")}
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <p className="text-gray-700">{t("facilitati_p16")}</p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <p className="text-gray-700">{t("facilitati_p17")}</p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <p className="text-gray-700">{t("facilitati_p18")}</p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <p className="text-gray-700">{t("facilitati_p19")}</p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <p className="text-gray-700">{t("facilitati_p20")}</p>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <p className="text-gray-700">{t("facilitati_p21")}</p>
          </div>
        </div>
      </section>

      {/* Suport pentru Afaceri */}
      <section className="mb-16">
        <div className="flex items-center mb-4">
          <FaBusinessTime className="text-red-500 text-3xl mr-4" />
          <h2 className="text-2xl font-semibold text-gray-800">
            {t("suport_pentru_afaceri")}
          </h2>
        </div>
        <p className="text-lg text-gray-800 mb-4">CCIRC:</p>
        <ul className="list-disc list-inside space-y-2 text-lg ml-8 text-gray-700">
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p22")} ({t("facilitati_p23")}).
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p24")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p25")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p26")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p27")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p28")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p29")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p30")}
          </li>
        </ul>
      </section>

      {/* Consultanță și Servicii */}
      <section className="mb-16">
        <div className="flex items-center mb-4">
          <FaChartLine className="text-red-500 text-3xl mr-4" />
          <h2 className="text-2xl font-semibold text-gray-800">
            {t("consultanta_si_servicii")}
          </h2>
        </div>
        <p className="text-lg text-gray-800 mb-4">CCIRC:</p>
        <ul className="list-disc list-inside space-y-2 text-lg ml-8 text-gray-700">
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p31")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p32")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p33")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p34")} ({t("facilitati_p35")}){t("facilitati_p36")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p37")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p38")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p39")}
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-red-500">•</span>
            {t("facilitati_p40")}
          </li>
        </ul>
        <h4 className="text-lg text-center font-semibold text-red-500 mt-8">
          {t("facilitati_p41")}
        </h4>
      </section>
      <section>
        <div className="flex items-center mb-4">
          <FaGlobe className="text-red-500 text-3xl mr-4" />
          <h2 className="text-2xl font-semibold text-gray-800">
            {t("consultanta_import")}
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
          <div className="bg-gray-100 rounded-lg shadow-lg p-4">
            <img
              src="consultanta1.jpg"
              alt="Consultanță Import"
              className="rounded-md w-full h-auto"
            />
          </div>
          <div className="bg-gray-100 rounded-lg shadow-lg p-4">
            <img
              src="consultanta2.jpg"
              alt="Consultanță Export"
              className="rounded-md w-full h-auto"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default FacilitatiPrezentare;
