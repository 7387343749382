import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Icon de avertizare */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-24 text-red-500"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
        />
      </svg>

      {/* Mesaj 404 */}
      <h1 className="text-6xl font-bold text-gray-800 mt-4">404</h1>
      <p className="text-xl text-gray-600 mt-2">
        Oops! Pagina pe care o cauți nu a fost găsită.
      </p>

      {/* Link către pagina principală */}
      <Link
        to="/"
        className="mt-6 bg-red-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-red-500"
      >
        Înapoi la pagina principală
      </Link>
    </div>
  );
}
