import React, { useContext, useEffect, useState } from "react";
import BlogPost from "./BlogPost";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";

export default function AllBlogPosts() {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // Pentru debounce
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { userInfo } = useContext(UserContext);
  const { t, i18n } = useTranslation(); // Obținem și i18n pentru limbă

  // Debounce pentru searchTerm
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Delay de 500ms

    // Curățăm timeout-ul la fiecare schimbare de searchTerm
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/posts?page=${currentPage}&limit=6&searchTerm=${debouncedSearchTerm}`,
          {
            credentials: "include",
          }
        );
        const data = await response.json();
        setPosts(data.posts);
        setTotalPages(data.totalPages);
      } catch (err) {
        console.error("Eroare la preluarea postărilor:", err);
      }
    }
    fetchPosts();
  }, [currentPage, userInfo, debouncedSearchTerm]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Actualizează searchTerm-ul curent
  };

  // Filtrăm postările în funcție de limba selectată

  return (
    <div className="block mx-auto" id="postari">
      <div className="flex justify-end my-4">
        <div className="relative w-1/3">
          <input
            type="text"
            placeholder={t("cauta_postari")}
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 pl-10 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-300 w-full"
          />
          <span className="absolute left-3 top-2.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </span>
        </div>
      </div>
      {posts.length > 0 ? (
        <>
          {posts.map((post) => (
            <BlogPost
              key={post._id}
              title={post.title[i18n.language]}
              date={post.date[i18n.language]}
              content={post.summary[i18n.language]}
              image={post.image}
              id={post._id}
              visibility={post.visibility}
            />
          ))}

          {/* Paginația apare doar dacă există postări */}
          <div className="flex justify-center mt-4">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-red-500 rounded-md mr-2 font-semibold disabled:bg-red-300"
            >
              {t("anterior")}
            </button>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-red-500 rounded-md font-semibold disabled:bg-red-300"
            >
              {t("urmator")}
            </button>
          </div>

          <p className="text-center mt-2">
            {t("pagina")} {currentPage} {t("din")} {totalPages}
          </p>
        </>
      ) : (
        <p className="text-center font-semibold text-xl">
          {t("nu_exista_postari")}!
        </p>
      )}
    </div>
  );
}
