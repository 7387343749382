import React from "react";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-lg py-12">
      <div className="max-w-5xl mx-auto px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-6">
          <h1 className="text-4xl font-extrabold text-red-600">
            {t("contacteaza_ne")}
          </h1>
          <p className="text-gray-700 mt-4">{t("ai_intrebari")}</p>
        </div>

        {/* Content */}
        <div className="bg-white shadow-lg rounded-lg p-8 space-y-6">
          {/* Identitate */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                />
              </svg>
              <h2 className="text-2xl font-bold text-red-600">
                {t("identitate")}
              </h2>
            </div>

            <p className="text-gray-700">
              <strong>{t("hotarare_inregistrare")}:</strong> Decizia CSJ Nr.
              4436/1998
            </p>
            <p className="text-gray-700 mt-1">
              <strong>{t("certificat_de_inregistrare")}:</strong> CIF 122 12 775
            </p>
            <p className="text-gray-700 mt-1">
              <strong>{t("registrul_national")}:</strong> {t("nr_regist")}
            </p>
          </div>

          {/* Contact */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                />
              </svg>

              <h2 className="text-2xl font-bold text-red-600">
                {t("contact")}
              </h2>
            </div>
            <p className="text-gray-700">
              <strong>{t("adresa")}:</strong>
              <br />
              Bd. Unirii nr. 10, bl. 7B, sc. 2, et. 6, ap. 45
              <br />
              Sector 4, București, CP 040105
            </p>
            <p className="text-gray-700 mt-4">
              <strong>E-mail:</strong>
              <br />
              <a
                href="mailto:ccircoffice@gmail.com"
                className="text-red-600 hover:underline"
              >
                ccircoffice@gmail.com
              </a>
            </p>
            <p className="text-gray-700 mt-4">
              <strong>Web:</strong>
              <br />
              <a
                href="https://www.ccroch.ro"
                target="_blank"
                rel="noopener noreferrer"
                className="text-red-600 hover:underline"
              >
                www.ccroch.ro
              </a>
            </p>
          </div>

          {/* Bancă */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                />
              </svg>

              <h2 className="text-2xl font-bold text-red-600">{t("banca")}</h2>
            </div>
            <p className="text-gray-700">
              <strong>Libra Internet Bank:</strong>
            </p>
            <ul className="text-gray-700 mt-2 space-y-1">
              <li>
                <strong>RON:</strong> RO78 BREL 0002 0019 3491 0100
              </li>
              <li>
                <strong>EUR:</strong> RO94 BREL 0002 0019 3491 0200
              </li>
              <li>
                <strong>USD:</strong> RO13 BREL 0002 0019 3491 0300
              </li>
            </ul>
          </div>
        </div>

        {/* Google Maps */}
        <div className="my-12">
          <h2 className="text-3xl font-bold text-red-600 text-center mb-6">
            {t("unde_ne_gasesti")}?
          </h2>
          <div className="w-full h-72 rounded-lg overflow-hidden shadow-lg">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.3040536850212!2d26.09154828634653!3d44.42692489649743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ffe959a953a1%3A0x8fdb3804b47886d3!2sBulevardul%20Unirii%2010%2C%20Bucure%C8%99ti%20040105!5e0!3m2!1sro!2sro!4v1730932670568!5m2!1sro!2sro"
              className="w-full h-full"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
        </div>

        <div className="flex justify-center">
          <a
            href="mailto:ccircoffice@gmail.com"
            className="bg-red-600 text-white px-8 py-3 rounded-lg shadow-md font-semibold hover:bg-red-500 transition duration-300"
          >
            {t("trimite_ne_un_mesaj")}
          </a>
        </div>
      </div>
    </div>
  );
}
