import { useTranslation } from "react-i18next";

export default function Sponsori() {
  const { t } = useTranslation();

  const sponsori = [
    { name: "Radox", src: "/images/logos/radox.png" },
    { name: "Farmec", src: "/images/logos/farmec.png" },
    { name: "Grampet", src: "/images/logos/grampet.png" },
    { name: "Niro", src: "/images/logos/niro.png" },
    { name: "Valvis", src: "/images/logos/valvis.png" },
    { name: "Prutul", src: "/images/logos/prutul.png" },
    { name: "Huawei", src: "/images/logos/huawei.png" },
    { name: "Comes", src: "/images/logos/comes.png" },
    { name: "Elvada", src: "/images/logos/elvada.png" },
    { name: "Cosco", src: "/images/logos/cosco.png" },
    { name: "Cotnari", src: "/images/logos/cotnari.png" },
    { name: "Astra Arad", src: "/images/logos/astra.png" },
    { name: "Macromex", src: "/images/logos/macromex.png" },
    { name: "ZTE", src: "/images/logos/zte.png" },
    { name: "Energomontaj", src: "/images/logos/energomontaj.png" },
    { name: "China Unicom", src: "/images/logos/chinaunicom.png" },
    { name: "Impact", src: "/images/logos/impact.png" },
    { name: "Civil Construct", src: "/images/logos/civilconstruct.png" },
    { name: "General Meel", src: "/images/logos/generalmeel.png" },
    { name: "Green Road", src: "/images/logos/greenroad.png" },
    { name: "Haier", src: "/images/logos/haier.png" },
    { name: "Savini Due", src: "/images/logos/savinidue.png" },
    { name: "Aromint", src: "/images/logos/aromint.png" },
    { name: "Geminox", src: "/images/logos/geminox.png" },
    { name: "Aqua Caras", src: "/images/logos/aquacaras.png" },
    {
      name: "Universitatea Transilvania",
      src: "/images/logos/universitateatransilvania.png",
    },
    {
      name: "Urbio",
      src: "/images/logos/urbio.png",
    },
    {
      name: "Universitatea Bioterra",
      src: "/images/logos/universitateabioterra.png",
    },
    { name: "F&J", src: "/images/logos/fj.png" },
    { name: "China Travel", src: "/images/logos/chinatravel.png" },
    { name: "Edward Bisse", src: "/images/logos/edwardbisse.png" },
    { name: "Anton Jahn", src: "/images/logos/antonjahn.png" },
  ];

  {
    /*     { name: "Borsec", src: "/images/logos/borsec.png" },
    { name: "Gerovital", src: "/images/logos/gerovital.png" },
    { name: "Prutul", src: "/images/logos/prutul.png" },
    { name: "Boromir", src: "/images/logos/boromir.png" }, */
  }

  return (
    <div className="bg-white p-4 rounded-md shadow-md">
      <h2 className="text-2xl font-bold text-center mb-6">
        {t("parteneri_strategici")}
      </h2>
      <div className="grid grid-cols-2 gap-2">
        {sponsori.map((sponsor) => (
          <div
            key={sponsor.name}
            className="flex justify-center items-center hover:scale-105 transition-transform duration-200"
          >
            <img
              src={sponsor.src}
              alt={sponsor.name}
              className="max-h-20 object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
