import React, { useContext, useEffect } from "react";
import { UserContext } from "../UserContext";
import { Outlet } from "react-router-dom";
import AccessDenied from "./AccessDenied";

export default function Protected() {
  const { userInfo, setUserInfo } = useContext(UserContext);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/profile`, {
      credentials: "include",
    }).then((response) => {
      response.json().then((userInfo) => {
        setUserInfo(userInfo);
      });
    });
  }, []);

  const role = userInfo?.role;

  return <>{role === "admin" ? <Outlet /> : <AccessDenied />}</>;
}
