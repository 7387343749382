import React, { useEffect, useState } from "react";

export default function CurrencyExchangeCard() {
  const [rates, setRates] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/exchange-rates`
        ); // Apelăm backend-ul
        const data = await response.json();
        if (data.error) {
          setError(data.error);
        } else {
          setRates(data);
        }
      } catch (err) {
        setError("Error fetching exchange rates");
      }
    };
    fetchExchangeRates();
  }, []);

  // Funcție pentru a formata numărul cu 3 zecimale
  const formatNumber = (number) => {
    return number.toFixed(3);
  };

  if (!rates) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="max-w-md mx-auto shadow-lg rounded-lg my-3 overflow-hidden">
      <div className="bg-[#d9edf7] py-1">
        <h2 className="text-2xl font-bold text-[#428bca] text-center">1 RON</h2>
      </div>
      <div className="pt-2 pb-3 bg-white">
        <div>
          <div className="flex justify-between bg-white px-2">
            <div className="flex items-center gap-1">
              <img src="/china_flag.png" alt="China Flag" width={25} />
              <span className="font-bold text-[#ee1c25]">CNY</span>
            </div>
            <span className="font-semibold text-[#428bca]">
              {formatNumber(rates.CNY)}
            </span>
          </div>
        </div>
        <div className="flex justify-between bg-gray-100 px-2">
          <div className="flex items-center gap-1">
            <img src="/europe_flag.png" alt="Europe Flag" width={25} />
            <span className="font-bold text-[#00309a]">EUR</span>
          </div>
          <span className="font-semibold text-[#428bca]">
            {formatNumber(rates.EUR)}
          </span>
        </div>

        <div>
          <div className="flex justify-between bg-white px-2">
            <div className="flex items-center gap-1">
              <img src="/hk_flag.png" alt="Hong Kong Flag" width={25} />
              <span className="font-bold text-[#ef1620]">HKD</span>
            </div>
            <span className="font-semibold text-[#428bca]">
              {formatNumber(rates.HKD)}
            </span>
          </div>
        </div>

        <div className="flex justify-between bg-gray-100 px-2">
          <div className="flex items-center gap-1">
            <img src="/macau_flag.png" alt="Macao Flag" width={25} />
            <span className="font-bold text-[#00785a]">MOP</span>
          </div>
          <span className="font-semibold text-[#428bca]">
            {formatNumber(rates.MOP)}
          </span>
        </div>
      </div>
    </div>
  );
}
