import React from "react";
import ButonPrincipalProba from "./ButonPrincipalProba";
import Sponsori from "./Sponsori";

export default function ColoanaDreapta() {
  return (
    <div className="flex flex-col gap-4">
      <ButonPrincipalProba />
      <Sponsori />
    </div>
  );
}
