import React, { useContext } from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();

  const { setUserInfo } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.ok) {
      response.json().then((userData) => {
        setUserInfo(userData);
        setRedirect(true);
      });
    } else {
      setLoading(false);
      setError("Invalid username or password");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      {showAlert && (
        <div
          className="bg-red-100 border flex justify-center items-center fixed top-0 right-0 left-0 mx-auto w-fit border-red-400 text-red-700 px-4 py-3 rounded z-50 mt-10"
          role="alert"
        >
          <strong className="block sm:inline"> {error}</strong>
        </div>
      )}

      <div className="min-h-svh flex items-center justify-center bg-herringbone relative">
        <div className="relative z-10 w-full max-w-[340px] md:max-w-5xl flex shadow-lg flex-col md:flex-row">
          <div className="md:w-5/12 px-4 flex items-center justify-center rounded-l-lg bg-white">
            <img src="test.png" alt="Logo CCROCH" />
          </div>

          <div className="md:w-7/12 bg-[#F1190E] p-12 flex items-center rounded-r-lg justify-center">
            <div className="w-full max-w-sm">
              <h2 className="text-white text-2xl mb-6 font-semibold">
                {t("conectare")}
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    className="block text-gray-100 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    {t("numele_utilizator")}
                  </label>
                  <div className="relative flex items-center text-gray-600 focus-within:text-gray-900">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 absolute left-3 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>

                    <input
                      type="text"
                      id="username"
                      placeholder={t("introduceti_numele_utilizator")}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-red-400"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-100 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    {t("parola")}
                  </label>
                  <div className="relative flex items-center text-gray-600 focus-within:text-gray-900">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 absolute left-3 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                      />
                    </svg>
                    <input
                      type="password"
                      id="password"
                      placeholder={t("introduceti_parola")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-red-400"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full bg-white hover:bg-gray-100 text-red-500 py-2 px-4 rounded-lg font-medium flex items-center justify-center"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V2.5"
                        ></path>
                      </svg>
                      {t("loading")}
                    </>
                  ) : (
                    t("conectare")
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
